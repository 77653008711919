<template>
  <div class="entry-content">
    <div v-if="!addModal" class="container-fluid">
      <div class="top-row d-flex justify-content-between">
        <h1 class="page-title m-0">
          Treatment Directory
        </h1>
        <button type="button" class="btn" @click="showAddModal">
          <img class="add-new-icon" src="/img/icons/icon-plus.svg" alt="">
          <span class="add-new pl-1" style="color: #1e1e1e !important;">Add New</span>
        </button>
      </div>
      <loading :active.sync="isLoading" :can-cancel="false" :is-full-page="fullPage"></loading>
      <vue-good-table :columns="columns" :rows="services"
        :search-options="{enabled: true, placeholder: 'Search by name'}" :pagination-options="pageOptions">
        <template slot="table-row" slot-scope="props">
          <span v-if="props.column.field == 'updated_at'">
            <button @click="deleteProductType(props.row.id)" class="btn enroute-btn">Delete</button>
          </span>
        </template>
        <div slot="emptystate" class="text-center">
          No Data...
        </div>
      </vue-good-table>
    </div>
    <!-- Add promotional code modal -->
    <slide-y-up-transition origin="center top" mode="out-in">
      <div v-if="addModal" class="container-fluid">
        <h1 class="back m-0 cursor-pointer" @click="addModal=false"><img class="align-baseline"
            src="/img/icons/arrow-left.svg" height="15" alt="left-arrow">
          Back
        </h1>
        <div class="card mt-4 shadow cusotom-card bg-transparent">
          <div class="card-body">
            <div class="form-group">
              <label>Therapist:</label>
              <select class="custom-select" v-validate="'required'" name="therapist" v-model="newService.therapist_id">
                <option v-for="therapist in therapists" :value="therapist.id" :key="therapist.id">{{therapist.name}}</option>
              </select>
              <span v-show="errors.has('therapist')" class="help is-danger">{{ errors.first('therapist') }}</span>
            </div>
            <div class="form-group">
              <label>Price:</label>
              <input class="form-control" placeholder="50" v-model="newService.price" type="text" name="name"
                v-validate="'required:min:1'" />
              <span v-show="errors.has('name')" class="help is-danger">{{ errors.first('name') }}</span>
            </div>
            <div class="form-group">
              <label>Treatment Name:</label>
              <input class="form-control" placeholder="Video Call" v-model="newService.treatment_name" type="text" name="treatment"
                v-validate="'required:min:3'" />
              <span v-show="errors.has('treatment')" class="help is-danger">{{ errors.first('treatment') }}</span>
            </div>
            <div class="form-group mt-3 text-right">
              <button class="btn brand-btn enroute-btn" @click="addProductType">Add</button>
            </div>
          </div>
        </div>
      </div>
    </slide-y-up-transition>
  </div>
</template>
<script>
  import axios from 'axios';
  import 'vue-good-table/dist/vue-good-table.css'
  import Loading from 'vue-loading-overlay';
  import 'vue-loading-overlay/dist/vue-loading.css';
  import {
    SlideYUpTransition
  } from 'vue2-transitions';
  import {
    VueGoodTable
  } from 'vue-good-table';
  export default {
    components: {
      VueGoodTable,
      Loading,
      SlideYUpTransition
    },
    data() {
      return {
        isLoading: false,
        addModal: false,
        fullPage: true,
        columns: [{
            label: 'Therapist',
            field: 'therapist.name',
          },
          {
            label: 'Price',
            field: 'price',
          },
          {
            label: 'Treatment Name',
            field: 'treatment_name',
          },
          {
            label: 'Action',
            field: 'updated_at',
            width: '150px',
            sortable: false,
          }
        ],
        pageOptions: {
          enabled: true,
          mode: 'records',
          perPage: 10,
          position: 'bottom',
          perPageDropdown: [10, 20, 50],
          dropdownAllowAll: true,
          setCurrentPage: 1,
          nextLabel: 'next',
          prevLabel: 'prev',
          rowsPerPageLabel: 'Per Page',
          ofLabel: 'of',
          pageLabel: 'page', // for 'pages' mode
          allLabel: 'All',
        },
        services: [],
        therapist: [],
        newService: {
          therapist_id: '',
          price: '',
          treatment_name: ''
        },
        config: {
          headers: {
            Accept: 'application/json'
          }
        },
      };
    },
    methods: {
      getAllServices: function () {
        this.isLoading = true;
        axios.get("/admin/services/all?access_token=" + localStorage.getItem("access_token"), this.config)
          .then(result => {
            this.isLoading = false;
            this.services = result.data.services;
          }, error => {
            this.isLoading = false;
            console.log(error.response);
          });
      },
      getTherapists: function (page = 1) {
        let url = `/admin/therapists/all?page=${page}&access_token=${localStorage.getItem('access_token')}`;
        axios({
          method: "GET",
          url: url,
          headers: {
            "content-type": "application/json"
          }
        }).then(
          result => {
            if (result) {
              this.therapists = result.data.therapists.data;
              console.log(result)
            }
          },
          error => {
            console.log(error);
          }
        );
      },
      deleteProductType: function (id) {
        this.$swal({
          title: 'Are you sure?',
          text: "This will delete the treatment service.",
          icon: '',
          buttons: {
            cancel: true,
            confirm: true,
          },
        }).then((result) => {
          if (result) {
            this.isLoading = true;
            axios.get("/admin/services/delete/" + id + "?access_token=" + localStorage.getItem(
                  "access_token"), this
                .config)
              .then(result => {
                this.isLoading = false;
                this.$noty.info("Brand deleted");
                this.getAllServices();
                return result;
              }, error => {
                this.isLoading = false;
                this.$noty.error("Something went wrong");
                return error;
              });
          }
        })
      },
      showAddModal: function () {
        this.addModal = true;
      },
      addProductType: function () {
        this.$validator.validate().then((result) => {
          if (result) {
            this.isLoading = true;
            axios.post('/admin/services/create' + "?access_token=" + localStorage.getItem("access_token"),
                this.newService, this.config)
              .then(result => {
                this.isLoading = false;
                this.addModal = false;
                this.newBrand = {};
                this.$noty.success("Treatment Service added");
                this.getAllServices();
                return result;
              }, error => {
                this.isLoading = false;
                this.$noty.error("Something went wrong");
                return error;
              });
          }
        }).catch(() => {});
      },
      convertDigitToLetter: function(rowObj){
        if(rowObj == 1){
          return 'One';
        }else if(rowObj == 2){
          return 'Two';
        }else{
          return 'Three';
        }
      }
    },
    mounted() {
      this.getAllServices();
      this.getTherapists();
    }
  };
</script>
<style scoped>
  .cusotom-card:hover {
    transform: none !important;
  }

  .noty_theme__sunset.noty_type__success{
    background-color: #f2d3bc !important;
    color: #1e1e1e !important;
  }

  label{
    color: #1e1e1e !important;
  }
</style>
