<template>
  <div class=" entry-content">
    <div class="container-fluid">
      <div class="container">
        <div class="top-row d-flex justify-content-between">
          <h1 class="page-title m-0">
            Commissions
          </h1>
          <a class="btn exportBtn mr-1 offset-lg-5" :href="url + 'export/consultationcommissions'">Export Consultation</a>
          <a class="btn exportBtn" :href="url + 'export/commissions'">Export All</a>
        </div>
        <div class="d-flex mt-3 ml-2">
          <div class="resource-large-text d-flex" style="width:250px">
            Search:
            <input class="form-control resource-field border-0  ml-4 pl-2" placeholder="Search brand ..." type="text"
              v-model="search" @keydown="searchKeydown" @input="searchInput">
          </div>
          <div class="resource-large-text ml-5">
            Total Revenue: {{revenue}}
          </div>
          <div class="resource-large-text ml-5">
            Total Debt: {{debt}}
          </div>
        </div>
        <div class="table-responsive table-hover">
          <table class="table mt-2">
            <thead>
              <tr>
                <th class="resource-small-text cursor-pointer" scope="col">Brand Name</th>
                <th class="resource-small-text cursor-pointer" scope="col">Product Name</th>
                <th class="resource-small-text cursor-pointer" scope="col">Sold On </th>
                <th class="resource-small-text cursor-pointer" scope="col">Revenue</th>
                <th class="resource-small-text" scope="col">VAT</th>
                <th class="resource-small-text" scope="col">Debt to Brand</th>
                <th class="resource-small-text" scope="col">Therapist Name</th>
                <th class="resource-small-text" scope="col">Debt to Therapist</th>
                <th class="resource-small-text" scope="col">Scenario</th>
                <th class="resource-small-text" scope="col">Order Id</th>
                <th class="resource-small-text" scope="col">Date</th>
              </tr>
            </thead>
            <tbody>
              <tr class="bg-white cursor-pointer" v-for="commission in commissions" :key="commission.id">
                <td>{{commission.product? commission.product.brand.name:'-'}}</td>
                <td>{{commission.product? commission.product.product_name:'-'}}</td>
                <td>{{commission.created_at | moment(commission.created_at)}}</td>
                <td>{{commission.revenue}}</td>
                <td>{{commission.user_order && commission.user_order.tax? commission.user_order.tax: '-'}}</td>
                <td>{{commission.debt_to_brand || '-'}}</td>
                <td>{{commission.therapist? commission.therapist.name: '-'}}</td>
                <td>{{commission.debt_to_therapist || '-'}}</td>
                <td>{{commission.scenario || '-'}}</td>
                <td>{{commission.user_order_id? commission.user_order_id: '-'}}</td>
                <td>{{commission.created_at | moment}}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <pagination :total="total" @fetchData="getCommissions" v-if="total > 1" />
      </div>
    </div>
  </div>
</template>


<script>
  import axios from 'axios';
  import moment from "moment";
  import pagination from '../components/Pagination.vue';

  export default {
    name: "Questionnaire",
    components: {
      pagination
    },
    filters: {
      moment: function (date, altDate) {
        let d = moment(date);
        let altD = moment(altDate);
        return d.isValid() ?
          moment(date).format("MMM D, YYYY") :
          moment(altD).format("MMM D, YYYY");
      }
    },
    data: () => {
      return {
        commissions: [],
        total: 1,
        revenue: 0,
        debt: 0,
        url: '',
        search: '',
        brands: [],
      }
    },
    methods: {
      searchKeydown: function (e) {
        if (e.keyCode === 13) {
          if (this.search.length >= 3 || this.search == '') {
            this.getCommissions();
          } else {
            this.$noty.error("Please enter 3 or more characters");
          }
        }
      },
      searchInput: function () {
        if (!this.search) {
          this.getCommissions();
        }
      },
      getCommissions: function (page = 1) {
        let url = `/admin/commission?page=${page}&access_token=${localStorage.getItem('access_token')} `;
        if (this.sort) {
          if (this.sort.includes('_desc')) {
            url += `&sortBy=${this.sort.split('_desc')[0]}&orderBy=DESC`;
          } else {
            url += `&sortBy=${this.sort}`;
          }
        }
        if (this.search) {
          url += `&searchBy=${this.search}`;
        }
        axios({
          method: "GET",
          url: url,
          headers: {
            "content-type": "application/json"
          }
        }).then(
          result => {
            if (result) {
              this.commissions = result.data.commission.data;
              this.total = Math.ceil(result.data.commission.total / result.data.commission.per_page);
              this.revenue = result.data.total_revenue.toFixed(2);
              this.debt = result.data.total_debt.toFixed(2);
              console.log(result);
            }
          },
          error => {
            console.log(error);
          }
        );
      },
      getBrands: function () {
        let url = `/admin/brands/all?access_token=${localStorage.getItem('access_token')}`;
        axios({
          method: "GET",
          url: url,
          headers: {
            "content-type": "application/json"
          }
        }).then(
          result => {
            if (result) {
              this.brands = result.data.brands;
            }
          },
          error => {
            console.log(error);
          }
        );
      },
    },
    mounted: function () {
      this.getCommissions();
      this.getBrands();
      this.url = axios.defaults.baseURL;
    },
    computed: {}
  }
</script>

<style lang="scss" scoped>
  .table {
    border-collapse: separate;
    border-spacing: 0 12px !important;

    tbody td {
      padding: 12px;
    }

    thead th {
      padding: 5px;
      text-transform: capitalize;
    }
  }
</style>