<template>
  <div class="entry-content">
    <div v-if="!addModal" class="container-fluid">
      <div class="top-row d-flex justify-content-between">
        <h1 class="page-title m-0">
          Product Category
        </h1>
        <button type="button" class="btn" @click="showAddModal">
          <img class="add-new-icon" src="/img/icons/icon-plus.svg" alt="">
          <span class="add-new pl-1" style="color: #1e1e1e !important;">Add New</span>
        </button>
      </div>
      <loading :active.sync="isLoading" :can-cancel="false" :is-full-page="fullPage"></loading>
      <vue-good-table :columns="columns" :rows="product_types"
        :search-options="{enabled: true, placeholder: 'Search by name'}" :pagination-options="pageOptions">
        <template slot="table-row" slot-scope="props">
          <span v-if="props.column.field == 'updated_at'">
            <button @click="deleteProductType(props.row.id)" class="btn enroute-btn">Delete</button>
          </span>
        </template>
        <div slot="emptystate" class="text-center">
          No Data...
        </div>
      </vue-good-table>
    </div>
    <!-- Add promotional code modal -->
    <slide-y-up-transition origin="center top" mode="out-in">
      <div v-if="addModal" class="container-fluid">
        <h1 class="back m-0 cursor-pointer" @click="addModal=false"><img class="align-baseline"
            src="/img/icons/arrow-left.svg" height="15" alt="left-arrow">
          Back
        </h1>
        <div class="card mt-4 shadow cusotom-card bg-transparent">
          <div class="card-body">
            <div class="form-group">
              <label>Name:</label>
              <input class="form-control" placeholder="Mask" v-model="newProductType.name" type="text" name="name"
                v-validate="'required:min:3'" />
              <span v-show="errors.has('name')" class="help is-danger">{{ errors.first('name') }}</span>
            </div>
            <div class="form-group">
              <label>Category:</label>
              <select class="form-control" v-model="newProductType.category">
                <option value="1">Product Category</option>
                <option value="3">Product Formula</option>
              </select>
              <span v-show="errors.has('name')" class="help is-danger">{{ errors.first('name') }}</span>
            </div>
            <div class="form-group mt-3 text-right">
              <button class="btn brand-btn enroute-btn" @click="addProductType">Add</button>
            </div>
          </div>
        </div>
      </div>
    </slide-y-up-transition>
  </div>
</template>
<script>
  import axios from 'axios';
  import 'vue-good-table/dist/vue-good-table.css'
  import Loading from 'vue-loading-overlay';
  import 'vue-loading-overlay/dist/vue-loading.css';
  import {
    SlideYUpTransition
  } from 'vue2-transitions';
  import {
    VueGoodTable
  } from 'vue-good-table';
  export default {
    components: {
      VueGoodTable,
      Loading,
      SlideYUpTransition
    },
    data() {
      return {
        isLoading: false,
        addModal: false,
        fullPage: true,
        columns: [{
            label: 'Name',
            field: 'name',
          },
          {
            label: 'Category',
            field: 'category',
            formatFn: this.convertDigitToLetter,
          },
          {
            label: 'Action',
            field: 'updated_at',
            width: '150px',
            sortable: false,
          }
        ],
        pageOptions: {
          enabled: true,
          mode: 'records',
          perPage: 10,
          position: 'bottom',
          perPageDropdown: [10, 20, 50],
          dropdownAllowAll: true,
          setCurrentPage: 1,
          nextLabel: 'next',
          prevLabel: 'prev',
          rowsPerPageLabel: 'Per Page',
          ofLabel: 'of',
          pageLabel: 'page', // for 'pages' mode
          allLabel: 'All',
        },
        product_types: [],
        newProductType: {
          category: 1
        },
        config: {
          headers: {
            Accept: 'application/json'
          }
        },
      };
    },
    methods: {
      getAllProductTypes: function () {
        this.isLoading = true;
        axios.get("/admin/product-types/all?access_token=" + localStorage.getItem("access_token"), this.config)
          .then(result => {
            this.isLoading = false;
            this.product_types = result.data.types;
          }, error => {
            this.isLoading = false;
            console.log(error.response);
          });
      },
      deleteProductType: function (id) {
        this.$swal({
          title: 'Are you sure?',
          text: "This will delete the product category.",
          icon: '',
          buttons: {
            cancel: true,
            confirm: true,
          },
        }).then((result) => {
          if (result) {
            this.isLoading = true;
            axios.delete("/admin/product-types/delete/" + id + "?access_token=" + localStorage.getItem(
                  "access_token"), this
                .config)
              .then(result => {
                this.isLoading = false;
                this.$noty.info("Category deleted");
                this.getAllProductTypes();
                return result;
              }, error => {
                this.isLoading = false;
                this.$noty.error("Something went wrong");
                return error;
              });
          }
        })
      },
      showAddModal: function () {
        this.addModal = true;
      },
      addProductType: function () {
        this.$validator.validate().then((result) => {
          if (result) {
            if(this.product_types.map(type => type.name.toLowerCase()).includes(this.newProductType.name.toLowerCase())){
              this.$noty.error("Product Category already exist.");
              return;
            }
            this.isLoading = true;
            axios.post('/admin/product-types/create' + "?access_token=" + localStorage.getItem("access_token"),
                this.newProductType, this.config)
              .then(result => {
                this.isLoading = false;
                this.addModal = false;
                this.newBrand = {};
                
                this.$noty.success((this.newProductType.category == 1) ? "Product Category added" : "Product Formula added");
                this.getAllProductTypes();
                return result;
              }, error => {
                this.isLoading = false;
                this.$noty.error("Something went wrong");
                return error;
              });
          }
        }).catch(() => {});
      },
      convertDigitToLetter: function(rowObj){
        if(rowObj == 1){
          return 'Product Category';
        }else if(rowObj == 2){
          return 'Product Type';
        }else{
          return 'Product Formula';
        }
      }
    },
    mounted() {
      this.getAllProductTypes();
    }
  };
</script>
<style scoped>
  .cusotom-card:hover {
    transform: none !important;
  }

  .noty_theme__sunset.noty_type__success{
    background-color: #f2d3bc !important;
    color: #1e1e1e !important;
  }

  .form-control:not(textarea), .custom-select {
    height: 42px !important;
  }
</style>
