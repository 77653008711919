<template>
  <div class="entry-content">
    <div class="container-fluid">
      <div class="container pb-3">
        <div class="top-row d-flex justify-content-between align-items-center">
          <h1 class="page-title m-0">
            <router-link to="/purchase-orders">
                <i class="fas fa-arrow-left cursor-pointer"></i>
            </router-link>
            Purchase Order
            </h1>
          <div>
            <a class="btn exportBtn" :href="exportUrl">Export PO</a>
            <a class="btn exportBtn" :href="exportReturnUrl">
              Export PO Returns
            </a>
          </div>
        </div>
        <div class="row align-items-end m-0 mt-2"></div>
        <!-- users -->
        <div class="row my-3">
          <div class="table-responsive table-hover">
            <table class="table mt-4">
              <colgroup>
                <col span="1" style="width: 15%" />
                <col span="1" style="width: 70%" />
              </colgroup>
              <thead>
                <tr>
                  <td class="resource-small-text" scope="col-2">
                    Sales Order#
                  </td>
                  <td class="resource-small-text" scope="col">
                    {{ order.id }}
                  </td>
                </tr>
                <tr>
                  <td class="resource-small-text" scope="col">Supplier</td>
                  <td class="resource-small-text" scope="col">
                    {{ order.orderItems[0].product.supplier.name }}
                  </td>
                </tr>
                <tr>
                  <td class="resource-small-text" scope="col">Purchase Date</td>
                  <td class="resource-small-text" scope="col">
                    {{ order.created_at | moment }}
                  </td>
                </tr>
                <tr class="resource-small-text" scope="col" colspan="2">
                  &nbsp;
                </tr>
                <tr class="resource-small-text" scope="col" colspan="2">
                  &nbsp;
                </tr>
              </thead>
            </table>

            <!-- <div
              class="btn btn-danger ml-3"
              @click="showRemoveMultipleModal = true"
              :disabled="itemsChecked.length > 0"
              :class="{ disabled: !itemsChecked.length }"
            >
              Remove Selected
            </div> -->
            <div
              class="btn btn-warning ml-2"
              @click="
                showReturnMultipleModal = true;
                modalSaved = false;
              "
              :disabled="!itemsChecked.length > 0"
              :class="{
                disabled: !itemsChecked.length,
              }"
            >
              Return Selected
            </div>
            <table class="table mt-4">
              <tbody class="pt-4">
                <tr>
                  <th class="resource-small-text" scope="col"></th>
                  <th class="resource-small-text" scope="col">Item#</th>
                  <th class="resource-small-text" scope="col">Product Name</th>
                  <th class="resource-small-text" scope="col">Quantity</th>
                  <th class="resource-small-text" scope="col">Price</th>
                  <th class="resource-small-text" scope="col">Actions</th>
                </tr>
                <tr
                  v-for="item in order.order_items"
                  :key="item.id"
                  :class="{ returnedItem: item.status == 'returned' }"
                >
                  <td>
                    <input
                      type="checkbox"
                      :id="item.id"
                      :value="item.id"
                      v-model="itemsChecked"
                      v-if="item.status != 'returned'"
                    />
                  </td>
                  <td>{{ item.product.id }}</td>
                  <td>{{ item.product.name }}</td>
                  <td>{{ item.quantity }}</td>
                  <td>{{ item.price }}</td>
                  <td>
                    <div v-if="item.status != 'ordered'">
                      {{item.status}}
                    </div>
                    <div v-else>
                      <!-- <button
                        class="btn btn-danger"
                        @click="removeItemConfirmation(item)"
                        v-if="item.status !== 'returned'"
                      >
                        Remove
                      </button> -->
                      <button
                        class="btn btn-warning"
                        @click="returnItemConfirmation(item)"
                        v-if="item.status !== 'returned'"
                      >
                        Return
                      </button>
                    </div>
                  </td>
                </tr>
              </tbody>
              <tfoot v-if="order.order">
                <tr>
                  <td colspan="4">&nbsp;</td>
                  <th class="resource-small-text" scope="col">Subtotal</th>
                  <td>{{ order.order.total_price }}</td>
                </tr>
                <tr>
                  <td colspan="4">&nbsp;</td>
                  <th class="resource-small-text" scope="col">Shipping</th>
                  <td>{{ order.order.shipping_charges }}</td>
                </tr>
                <tr>
                  <td colspan="4">&nbsp;</td>
                  <th class="resource-small-text" scope="col">VAT</th>
                  <td>{{ order.order.tax }}</td>
                </tr>
                <tr>
                  <td colspan="4">&nbsp;</td>
                  <th class="resource-small-text" scope="col">Total</th>
                  <td>
                    {{
                      parseFloat(
                        parseFloat(order.order.total_price) +
                          parseFloat(order.order.shipping_charges) +
                          parseFloat(order.order.tax)
                      ).toFixed(2)
                    }}
                  </td>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal to remove single item -->
    <modal :show="showRemoveModal" @close="showRemoveModal = false">
      <div class="d-flex flex-column align-items-center">
        <h2>Are you sure you want to remove item from the Purchase Order?</h2>
        <h3>Deletion cannot be reverted</h3>
        <div class="d-flex flex-column align-items-left" v-if="removeModalData">
          <div>Item ID: {{ removeModalData.product.id }}</div>
          <div>Item Name: {{ removeModalData.product.name }}</div>
          <div>Item Quantity: {{ removeModalData.quantity }}</div>
          <div>Item Price: {{ removeModalData.price }}</div>
        </div>
        <div class="buttons">
          <div class="buttons d-flex">
            <div
              class="btn btn-secondary mt-3 mr-3"
              @click="showRemoveModal = false"
            >
              Cancel
            </div>
            <div
              class="btn btn-danger mt-3 justify-content-center d-flex"
              @click="removeItemFromPO(removeModalData.id)"
              style="width: 200px"
            >
              <div v-if="modalLoading" class="loader"></div>
              <span class="font-weight-bold" v-else>Confirm Delete</span>
            </div>
          </div>
        </div>
      </div>
    </modal>

    <!-- Modal to return single item -->

    <modal :show="showReturnModal" @close="showReturnModal = false">
      <div v-if="!modalSaved">
        <div class="d-flex flex-column align-items-center">
          <h2>Are you sure you want to return item from the Purchase Order?</h2>
          <div
            class="d-flex flex-column align-items-left"
            v-if="returnModalData"
          >
            <div>Item ID: {{ returnModalData.product.id }}</div>
            <div>Item Name: {{ returnModalData.product.name }}</div>
            <div>Item Quantity: {{ returnModalData.quantity }}</div>
            <div>Item Price: {{ returnModalData.price }}</div>
          </div>
          <div class="buttons">
            <div class="buttons d-flex">
              <div
                class="btn btn-secondary mt-3 mr-3"
                @click="showReturnModal = false"
              >
                Cancel
              </div>
              <div
                class="btn btn-warning mt-3 justify-content-center d-flex"
                @click="returnItemFromPO(returnModalData.id)"
                style="width: 200px"
              >
                <div v-if="modalLoading" class="loader"></div>
                <span class="font-weight-bold" v-else>Confirm Return</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <div class="d-flex flex-column align-items-center">
          <h2>Items are returned.</h2>
          <a class="btn exportBtn" :href="exportReturnUrl">Export PO Return</a>
        </div>
      </div>
    </modal>

    <!-- Modal to bulk return -->
    <modal
      :show="showReturnMultipleModal"
      @close="showReturnMultipleModal = false"
    >
      <div class="d-flex flex-column align-items-center">
        <div v-if="!modalSaved">
          <h2>You are returning {{ itemsChecked.length }} items.</h2>
          <h2>
            Are you sure you want to return items from the Purchase Order?
          </h2>
          <div class="buttons">
            <div class="buttons d-flex">
              <div
                class="btn btn-secondary mt-3 mr-3"
                @click="showReturnMultipleModal = false"
              >
                Cancel
              </div>
              <div
                class="btn btn-warning mt-3 justify-content-center d-flex"
                @click="returnMultipleItems()"
                style="width: 200px"
              >
                <div v-if="modalLoading" class="loader"></div>
                <span class="font-weight-bold" v-else>Confirm Return</span>
              </div>
            </div>
          </div>
        </div>
        <div v-else>
          <h2>Items are returned.</h2>
          <a class="btn exportBtn" :href="exportReturnUrl">Export PO Return</a>
        </div>
      </div>
    </modal>
    <!-- Modal to bulk remove -->
    <modal
      :show="showRemoveMultipleModal"
      @close="showRemoveMultipleModal = false"
    >
      <div class="d-flex flex-column align-items-center">
        <h2>You are removing {{ itemsChecked.length }} items.</h2>
        <h2>Are you sure you want to remove items from the Purchase Order?</h2>
        <h3>Deletion cannot be reverted</h3>
        <div class="buttons">
          <div class="buttons d-flex">
            <div
              class="btn btn-secondary mt-3 mr-3"
              @click="showRemoveMultipleModal = false"
            >
              Cancel
            </div>
            <div
              class="btn btn-danger mt-3 justify-content-center d-flex"
              @click="removeMultipleItems()"
              style="width: 200px"
            >
              <div v-if="modalLoading" class="loader"></div>
              <span class="font-weight-bold" v-else>Confirm Delete</span>
            </div>
          </div>
        </div>
      </div>
    </modal>
  </div>
</template>
<script>
import axios from "axios";
import moment from "moment";

export default {
  name: "PurchaseOrder",
  filters: {
    moment: function (date) {
      let d = moment(date);
      return d.isValid() ? moment(date).format("YYYY-DD-MM") : "N/A";
    },
  },
  data: () => {
    return {
      appURL: process.env.VUE_APP_MAIN_URL,
      order: {},
      url: "",
      total: 1,
      search: "",
      sort: "id",
      from: "",
      to: "",
      orderId: "",
      exportUrl: "",
      exportReturnUrl: "",
      showRemoveModal: false,
      showRemoveMultipleModal: false,
      showReturnModal: false,
      showReturnMultipleModal: false,
      removeModalData: null,
      returnModalData: null,
      modalLoading: false,
      disableRemoveSelectedBtn: true,
      itemsChecked: [],
      modalSaved: false,
    };
  },
  methods: {
    searchKeydown: function (e) {
      if (e.keyCode === 13) {
        if (this.search.length >= 3 || this.search == "") {
          this.getPurchaseOrder();
        } else {
          this.$noty.error("Please enter 3 or more characters");
        }
      }
    },
    searchInput: function () {
      if (!this.search) {
        this.getPurchaseOrder();
      }
    },
    getPurchaseOrder: function () {
      let id = this.$route.params.id;
      let url = "admin/purchase/" + id;
      axios({
        method: "GET",
        url: url,
        headers: {
          "content-type": "application/json",
        },
      }).then(
        (result) => {
          if (result) {
            this.order = result.data.order;

            console.log({ order: this.order });
            const orderItems = [];
            const returnedItems = [];
            this.order.orderItems.forEach(function (item) {
              if (item.status !== "deleted") {
                if (item.status == "returned") {
                  item.product.product_name =
                    "(RETURNED) - " + item.product.product_name;
                  returnedItems.push(item.id);
                }

                orderItems.push(item);
              }
            });
            this.order.order_items = orderItems;

            this.showRemoveModal = false;
            this.showRemoveMultipleModal = false;
            this.modalLoading = false;
            this.modalSaved = true;
            this.itemsChecked = [];
            this.exportReturnUrl =
              this.url +
              "admin/purchase-orders/export-returned?item_ids=" +
              JSON.stringify(returnedItems) +
              `&access_token=${localStorage.getItem("access_token")}`;
          }
        },
        (error) => {
          console.log(error);
        }
      );
    },
    removeItemConfirmation: function (item) {
      this.removeModalData = item;
      this.showRemoveModal = true;
    },
    returnItemConfirmation: function (item) {
      this.modalSaved = false;
      this.returnModalData = item;
      this.showReturnModal = true;
    },
    removeItemFromPO: function (id) {
      this.modalLoading = true;
      let url = "admin/sales-orders/remove/";// + this.$route.params.id;
      axios({
        method: "POST",
        url: url,
        data: { item_ids: JSON.stringify([id]) },
        headers: {
          "content-type": "application/json",
        },
      }).then(
        (result) => {
          if (result) {
            this.getPurchaseOrder();
          }
        },
        (error) => {
          this.modalLoading = false;
          console.log(error);
        }
      );
    },
    returnItemFromPO: function (id) {
      this.modalSaved = false;
      this.modalLoading = true;
      let url = "admin/sales-orders/return/"; //+ this.$route.params.id;
      axios({
        method: "POST",
        url: url,
        data: { item_ids: JSON.stringify([id]) },
        headers: {
          "content-type": "application/json",
        },
      }).then(
        (result) => {
          if (result) {
            this.getPurchaseOrder();
          }
        },
        (error) => {
          this.modalLoading = false;
          console.log(error);
        }
      );
    },
    removeMultipleItems: function () {
      this.modalLoading = true;
      let url = "admin/sales-orders/remove/"; // + this.$route.params.id;

      axios({
        method: "POST",
        url: url,
        data: { item_ids: JSON.stringify(this.itemsChecked) },
        headers: {
          "content-type": "application/json",
        },
      }).then(
        (result) => {
          if (result) {
            this.getPurchaseOrder();
          }
        },
        (error) => {
          this.modalLoading = false;
          console.log(error);
        }
      );
    },
    returnMultipleItems: function () {
      this.modalSaved = false;
      this.modalLoading = true;
      let url = "admin/sales-orders/return/"; // + this.$route.params.id;

      axios({
        method: "POST",
        url: url,
        data: { item_ids: JSON.stringify(this.itemsChecked) },
        headers: {
          "content-type": "application/json",
        },
      }).then(
        (result) => {
          if (result) {
            this.getPurchaseOrder();
          }
        },
        (error) => {
          this.modalLoading = false;
          console.log(error);
        }
      );
    },
  },
  computed: {},
  mounted() {
    console.log("test1");
    this.getPurchaseOrder();
    console.log("test2");
    this.url = axios.defaults.baseURL;
    this.exportUrl =
      this.url +
      "admin/purchase-orders/export/" +
      this.$route.params.id +
      `?access_token=${localStorage.getItem("access_token")}`;
  },
};
</script>

<style lang="scss" scoped>
@media (max-width: 767px) {
  .table-responsive .dropdown-menu {
    position: static !important;
  }
}
@media (min-width: 768px) {
  .table-responsive {
    overflow: visible;
  }
}
li > li {
  padding-top: 0;
  padding-bottom: 0;
}

.disabled {
  pointer-events: none;
}

.returnedItem {
  background-color: rgba(0, 0, 0, 0.1);
}

.returnedItem:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.loader {
  border: 4px solid rgb(51, 73, 87);
  border-top: 4px solid #f3f3f3;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
