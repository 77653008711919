<template>
<div class="container-fluid pt-8">
  <div class="row">
  <div class="col-lg-12">
    <p class="main-message m-0 mx-auto reset-max-width">Reset Password</p>
    <div class="row">
      <div class="col-lg-12 mt-4 mb-2">
          <div class="form-group mx-auto reset-max-width">
            <p class="resource-text">We will send you an email to reset your password.</p>
            <label class="resource-text m-0" for="email">Email</label>
              <input type="text"
              class="form-control"
              id="email"
              placeholder="example@myemail.com"
              v-model="forgot.email">
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-12 text-center mt-4">
        <button type="button" class="btn btn-primary px-5 enroute-btn resource-large-text" @click="reset">Reset Password</button>
        <router-link class="d-block mt-4" to="/login">
          <small class="resource-text">Cancel</small>
        </router-link>
        <div>{{response}}</div>
      </div>
    </div>
  </div>
  </div>
</div>
</template>
<script>
  import axios from 'axios';

  export default {
    name: 'password-forget',
    data() {
      return {
        forgot: {},
        response: ''
      }
    },
    methods: {
        reset: function () {
        var data = new FormData();
        data.append('email', this.forgot.email);
        axios(
          {
            method: "POST",
            "url": "admin/forget-password",
            "data": data,
            "headers": {
              "content-type": "multipart/form-data",
            }
          }
        ).then(result => {
          if(result.data['message']=='Email not found')
            this.$noty.error(result.data['message'])
          else{
            this.$noty.success(result.data['message'])
          }
          this.forgot.email = "";
          }, error => {
          this.$noty.error("Please check your credentials")
          console.log(error.response);
          this.loader = false;

        });
      },
    },
  }
</script>
<style>
</style>
