<template>
  <div class="entry-content">
    <router-link to="/products">
      <h1 class="back m-0 ml-4"><img class="align-baseline" src="/img/icons/arrow-left.svg" height="15" alt="left-arrow"> Back</h1>
    </router-link>
    <div class="container-fluid pt-2">
      <div class="container">
        <div class="row mt-4">
          <div class="col-2 text-center">
            <picture-input class="mb-3" ref="pictureInput" @change="inputImage" width="150" height="150"
              margin="0" accept="image/jpeg,image/png" size="2" :hideChangeButton="true" :removable="false">
            </picture-input>
            <button class="btn btn-primary btn-sm px-2 mx-auto enroute-btn resource-text resource-upload-btn"
              @click="uploadImage">Upload Photo</button>
          </div>
          <div class="col-5">
            <div class="form-group">
              <label class="resource-text m-0" for="product">Product</label>
              <input type="text" class="form-control" v-validate="'required|min:3'" data-vv-delay="500" name="name"
                id="product" v-model="product.product_name">
              <span v-show="submittedHit && errors.has('name')" class="help is-danger">{{ errors.first('name') }}</span>
            </div>
            <div class="form-group">
              <label class="resource-text m-0" for="brand">Brand</label>
              <select class="custom-select" v-validate="'required'" name="brand" v-model="product.brand_id">
                <option v-for="brand in brands" :value="brand.id" :key="brand.id">{{brand.name}}</option>
              </select>
              <span v-show="submittedHit && errors.has('brand')"
                class="help is-danger">{{ errors.first('brand') }}</span>
            </div>
            <div class="form-group">
              <label class="resource-text m-0" for="type">Type</label>
              <select class="custom-select" v-validate="'required'" name="type" v-model="product.product_type_id">
                <option v-for="type in types" :value="type.id" :key="type.id">{{type.name}}</option>
              </select>
              <span v-show="submittedHit && errors.has('type')" class="help is-danger">{{ errors.first('type') }}</span>
            </div>
          </div>
          <div class="col-2">
            <div class="form-group">
              <label class="resource-text m-0" for="price">Price <span class="resource-small-text">(SEK)</span></label>
              <input type="text" class="form-control" v-validate="'required|min:1'" data-vv-delay="500" name="price"
                id="price" v-model="product.product_price">
              <span v-show="submittedHit && errors.has('price')"
                class="help is-danger">{{ errors.first('price') }}</span>
            </div>
            <div class="form-group">
              <label class="resource-text m-0" for="volume">Volume <span class="resource-small-text">(ml)</span></label>
              <input type="text" class="form-control" data-vv-delay="500" name="volume" v-model="product.volume"
                v-validate="'required'">
              <span v-show="submittedHit && errors.has('volume')"
                class="help is-danger">{{ errors.first('volume') }}</span>
            </div>
          </div>
          <div class="col-2">
            <div class="form-group">
              <label class="resource-text m-0" for="category1">Product Category</label>
              <select class="custom-select" v-validate="'required'" name="category1" v-model="product.product_category">
                <option v-for="cat in cat1types" :value="cat.category" :key="cat.id">{{cat.name}}</option>
              </select>
              <span v-show="submittedHit && errors.has('category1')"
                class="help is-danger">{{ errors.first('category1') }}</span>
            </div>
            <div class="form-group">
              <label class="resource-text m-0" for="category2">Product Type</label>
              <select class="custom-select" v-validate="'required'" name="category2" v-model="product.product_type">
                <option v-for="type in product_types" :value="type" :key="type">{{type}}</option>
              </select>
              <span v-show="submittedHit && errors.has('category2')"
                class="help is-danger">{{ errors.first('category2') }}</span>
            </div>
            <div class="form-group">
              <label class="resource-text m-0" for="category3">Product Formula</label>
              <select class="custom-select" v-validate="'required'" name="category3" v-model="product.product_formula">
                <option v-for="cat in cat3types" :value="cat.category" :key="cat.id">{{cat.name}}</option>
              </select>
              <span v-show="submittedHit && errors.has('category3')"
                class="help is-danger">{{ errors.first('category3') }}</span>
            </div>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-7">
            <div class="form-floating form-group">
              <label class="resource-text m-0" for="how_to_use">How To Use</label>
              <textarea class="form-control" v-validate="'required|min:5'" data-vv-delay="500" name="how_to_use"
                placeholder="Write down how to use the product" rows="2" id="how_to_use"
                v-model="product.how_to_use"></textarea>
              <span v-show="submittedHit && errors.has('how_to_use')"
                class="help is-danger">{{ errors.first('how_to_use') }}</span>
            </div>
          </div>
          <div class="col-5">
            <div class="form-floating form-group">
              <label class="resource-text m-0" for="active_ingredients">Active Ingredients</label>
              <textarea class="form-control" v-validate="'required|min:5'" data-vv-delay="500" name="active_ingredients"
                placeholder="Write down active ingredients here" rows="2" id="active_ingredients"
                v-model="product.active_ingredients"></textarea>
              <span v-show="submittedHit && errors.has('active_ingredients')"
                class="help is-danger">{{ errors.first('active_ingredients') }}</span>
            </div>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-6">
            <div class="form-floating form-group">
              <label class="resource-text m-0" for="recommended_for_skin_type">Recommended Skin Types</label>
              <multiselect v-model="product.recommended_for_skin_type" :options="skin_types" :multiple="true" :close-on-select="false" :clear-on-select="false"
                :preserve-search="true" placeholder="Choose recommended skin types" label="text" track-by="text">
                <template slot="selection" slot-scope="{ values, search, isOpen }">
                  <span class="multiselect__single" v-if="values.length &amp;&amp; !isOpen">{{ displayArrayValues(values) }}</span>
                </template>
              </multiselect>
              <span v-show="submittedHit && errors.has('recommended_for_skin_type')" class="help is-danger">{{ errors.first('recommended_for_skin_type') }}</span>
            </div>
          </div>
          <div class="col-3">
            <div class="form-group">
              <label class="resource-text m-0" for="article_number">Article Number</label>
              <input type="text" class="form-control" v-validate="'required'" data-vv-delay="500" name="article_number"
                id="article_number" v-model="product.article_number">
              <span v-show="submittedHit && errors.has('article_number')"
                class="help is-danger">{{ errors.first('article_number') }}</span>
            </div>
          </div>
          <div class="col-3">
            <div class="form-group">
              <label class="resource-text m-0" for="when_to_use">When To Use</label>
              <select class="custom-select" v-validate="'required'" data-vv-delay="500" name="when_to_use"
                v-model="product.when_to_use">
                <option value="morning_evening">Morning and Evening</option>
                <option value="morning">Morning</option>
                <option value="evening">Evening</option>
                <option value="weekly">Weekly</option>
              </select>
              <span v-show="submittedHit && errors.has('when_to_use')"
                class="help is-danger">{{ errors.first('when_to_use') }}</span>
            </div>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-6">
            <label class="resource-text m-0" for="recommended_for_skin_condition">Recommended Skin Conditions</label>
            <multiselect v-model="product.recommended_for_skin_condition" :options="skin_conditions" :multiple="true" :close-on-select="false" :clear-on-select="false"
                :preserve-search="true" placeholder="Choose recommended skin conditions" label="text" track-by="text">
              <template slot="selection" slot-scope="{ values, search, isOpen }">
                <span class="multiselect__single" v-if="values.length &amp;&amp; !isOpen">{{ displayArrayValues(values) }}</span>
              </template>
            </multiselect>
            <span v-show="submittedHit && errors.has('recommended_for_skin_condition')" class="help is-danger">{{ errors.first('recommended_for_skin_condition') }}</span>
          </div>
          <div class="col-3 align-self-center">
            <div class="form-group">
              <label class="resource-text m-0" for="certification">Certification</label>
              <input type="text" class="form-control" v-validate="'required'" data-vv-delay="500" name="certification"
                id="certification" v-model="product.certification">
              <span v-show="submittedHit && errors.has('certification')"
                class="help is-danger">{{ errors.first('certification') }}</span>
            </div>
          </div>
          <div class="col-3 align-self-center">
            <div class="form-group">
              <label class="resource-text m-0" for="country_of_origin">Country Of Origin</label>
              <select class="custom-select" v-validate="'required'" data-vv-delay="500" name="country_of_origin"
                v-model="product.country_of_origin">
                <option v-for="country in countries" :value="country.name" :key="country.code">{{country.name}}
                </option>
              </select>
              <span v-show="submittedHit && errors.has('country_of_origin')"
                class="help is-danger">{{ errors.first('country_of_origin') }}</span>
            </div>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-7">
            <div class="form-floating form-group">
              <label class="resource-text m-0" for="description">Description</label>
              <textarea class="form-control" v-validate="'required|min:5'" data-vv-delay="500" name="description"
                placeholder="Write down description here" rows="4" id="description"
                v-model="product.product_description"></textarea>
              <span v-show="submittedHit && errors.has('description')"
                class="help is-danger">{{ errors.first('description') }}</span>
            </div>
          </div>
          <div class="col-5">
            <div class="form-floating form-group">
              <label class="resource-text m-0" for="warnings">Warnings (Optional)</label>
              <textarea class="form-control" v-validate="'min:2'" data-vv-delay="500" name="warnings"
                placeholder="Write down warnings here" rows="4" id="warnings" v-model="product.warnings"></textarea>
              <span v-show="submittedHit && errors.has('warnings')"
                class="help is-danger">{{ errors.first('warnings') }}</span>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-7">
            <div class="form-floating form-group">
              <label class="resource-text m-0" for="ingredients">Ingredients</label>
              <textarea class="form-control" v-validate="'required|min:3'" data-vv-delay="500" name="ingredients"
                placeholder="write down ingredients here" rows="4" id="ingredients"
                v-model="product.product_ingredients"></textarea>
              <span v-show="submittedHit && errors.has('ingredients')"
                class="help is-danger">{{ errors.first('ingredients') }}</span>
            </div>
          </div>
        </div>
        <div class="row py-6">
          <div class="col-12 text-right">
            <button class="btn btn-primary enroute-btn resource-save-btn" @click="addProduct">Replace Product</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import axios from 'axios';
  import PictureInput from 'vue-picture-input';
  import {
    b64toBlob
  } from '../utils/helper';
  import countries from '../utils/countries';
  import Multiselect from 'vue-multiselect';
  import "vue-multiselect/dist/vue-multiselect.min.css";

  export default {
    name: 'Product',
    components: {
      Multiselect,
      PictureInput,
    },
    data: () => {
      return {
        product: {
          product_type: '',
          volume: '',
          recommended_for_skin_type: [],
          recommended_for_skin_condition: [],
        },
        skin_types: [
          { text: "Dry" },
          { text: "Oily" },
          { text: "Combination" },
          { text: "Normal" },
        ],
        skin_conditions: [
          { text: "Pigmentations" },
          { text: "Uneven skin tone" },
          { text: "Breakouts" },
          { text: "Blackheads" },
          { text: "Acne" },
          { text: "Fine lines" },
          { text: "Wrinkles" },
          { text: "Redness" },
          { text: "Irritation" },
          { text: "Oiliness" },
          { text: "Enlarged pores" },
          { text: "Uneven skin texture" },
          { text: "Dehydrated skin" },
          { text: "Dull skin" },
          { text: "Add glow" },
        ],
        product_types: [
            "Pre-Cleanser",
            "Cleanser",
            "Toner",
            "Essence water",
            "Exfoliation",
            "Treatment",
            "Face mask",
            "Serum",
            "Eye cream",
            "Moisturiser",
            "SPF",
            "Face oil",
            "Face mist",
            "Supplement",
        ],
        countries: countries,
        submittedHit: false,
        saved: false,
        brands: [],
        types: []
      }
    },
    methods: {
      getAllBrands: function () {
        axios.get("/admin/brands/all?access_token=" + localStorage.getItem("access_token"), this.config)
          .then(result => {
            this.isLoading = false;
            this.brands = result.data.brands;
          }, error => {
            this.isLoading = false;
            return error;
          });
      },
      getAllTypes: function () {
        axios.get("/admin/product-types/all?access_token=" + localStorage.getItem("access_token"), this.config)
          .then(result => {
            this.isLoading = false;
            this.types = result.data.types;
          }, error => {
            this.isLoading = false;
            return error;
          });
      },
      displayArrayValues: function(array) {
        return array.map(element => element.text).toString();
      },
      uploadImage: function () {
        this.$refs.pictureInput.$refs.fileInput.click();
      },
      inputImage: function () {
        if (this.$refs.pictureInput.image) {
          this.product.image = this.$refs.pictureInput.image;
        }
      },
      addProduct: function () {
        this.$validator.validateAll().then((result) => {
          result;
          this.submittedHit = true;
          if (result) {
            const formDataToUpload = new FormData();
            if (!this.imageFromDB) {
              const blob = b64toBlob(this.product.image);
              formDataToUpload.append("image", blob);
            }
            for (const key in this.product) {
              if (key !== 'image') {
                if(key == 'recommended_for_skin_type' || key == 'recommended_for_skin_condition'){
                  formDataToUpload.append(key, JSON.stringify(this.product[key].map(element => element.text)));
                }
                else{
                  formDataToUpload.append(key, this.product[key]);
                }
              }
            }
            axios({
              method: "POST",
              url: `admin/products/add-replacement/${this.$route.params.product_id}?access_token=${localStorage.getItem('access_token')}`,
              data: formDataToUpload,
              headers: {
                "content-type": "multipart/form-data"
              }
            }).then(
              result => {
                if (result) {
                  alert("Product replaced with new product successfully");
                  this.saved = true;
                  this.$router.push(`/products`);
                }
              },
              error => {
                console.log(error);
              }
            );
          }
          // }else{
          //   this.$noty.error("Please fill all fields.");
          // }
        }).catch(() => {});
      },
    },
    beforeRouteLeave(to, from, next) {
      if (this.saved) {
        next();
      } else {
        const answer = window.confirm(
          'Do you really want to leave? Any unsaved changes will not be saved automatically.')
        if (answer) {
          next();
        } else {
          next(false);
        }
      }
    },
    mounted() {
      this.getAllBrands();
      this.getAllTypes();
    }
  };
</script>

<style lang="scss" scoped>
</style>
