<template>
  <div class="entry-content">
    <div v-if="isLoading" class="loading-container w-100 d-flex justify-content-center align-items-center">
      <div class="loader">
      </div>
    </div>
    <router-link to="/products">
      <h1 class="back m-0 ml-4"><img class="align-baseline" src="/img/icons/arrow-left.svg" height="15" alt="left-arrow"> Back</h1>
    </router-link>
    <div class="container-fluid pt-2">
      <div class="container">
        <div class="row mt-4">
          <div class="col-2 text-center">
            <div class="image-container">
                <img v-if="product.image && imageFromDB" ref="dbImage" class="mb-4 img-fluid"
              :src="appURL + product.image" alt="Product image" :style="{height: imageHeight + 'px'}" @load="setHeight">
              <button v-if="product.image" class="btn btn-sm remove-button btn-danger" title="Remove image" @click="removeImage('image')">
                <i class="fa fa-times"></i>
              </button>
            </div>
            <picture-input class="mb-3" ref="pictureInput" @change="inputImage"
              width="150" height="150" margin="0" accept="image/jpeg,image/png" size="2"
              :hideChangeButton="true" :removable="false" :class="{'d-none':product.image && imageFromDB}">
            </picture-input>
            <button class="btn btn-primary btn-sm px-2 mx-auto enroute-btn resource-text resource-upload-btn"
              @click="uploadImage">Upload Photo</button>
          </div>
          <div class="col-5">
            <div class="form-group">
              <label class="resource-text m-0" for="product">Product</label>
              <input type="text" class="form-control" v-validate="'required|min:3'" data-vv-delay="500" name="name"
                id="product" v-model="product.name">
              <span v-show="submittedHit && errors.has('name')" class="help is-danger">{{ errors.first('name') }}</span>
            </div>
            <div class="form-group">
              <label class="resource-text m-0" for="brand">Brand</label>
              <input type="text" class="form-control" data-vv-delay="500" name="brand"
                     id="brand" v-model="product.brand">
              <span v-show="submittedHit && errors.has('brand')"
                class="help is-danger">{{ errors.first('brand') }}</span>
            </div>
          </div>
          <div class="col-2">
            <div class="form-group">
              <label class="resource-text m-0" for="price">Price </label>
              <input type="text" class="form-control" v-validate="'required|min:1'" data-vv-delay="500" name="price"
                id="price" v-model="product.price">
              <span v-show="submittedHit && errors.has('price')"
                class="help is-danger">{{ errors.first('price') }}</span>
            </div>
            <div class="form-group">
              <label class="resource-text m-0" for="volume">Color</label>
              <input type="text" class="form-control" data-vv-delay="500" name="color" v-model="product.color">
              <span v-show="submittedHit && errors.has('color')" class="help is-danger">{{ errors.first('color') }}</span>
            </div>
          </div>
          <div class="col-2">
            <div class="form-group">
              <label class="resource-text m-0" for="category">Category</label>
              <select class="form-control" v-validate="'required'" data-vv-delay="500" name="category"
                      id="category" v-model="product.category_id">
                <option v-for="category in categories" :key="category.id" :value="category.id" :selected="category.id == product.category_id">{{category.name}}</option>
              </select>
              <span v-show="submittedHit && errors.has('category')" class="help is-danger">{{ errors.first('category') }}</span>
            </div>
            <div class="form-group">
              <label class="resource-text m-0" for="material">Material</label>
              <select class="form-control" data-vv-delay="500" name="material"
                      id="material" v-model="product.material_id">
                <option v-for="material in materials" :key="material.id" :value="material.id" :selected="material.id == product.material_id">{{material.name}}</option>
              </select>
              <span v-show="submittedHit && errors.has('material')" class="help is-danger">{{ errors.first('material') }}</span>
            </div>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-5">
            <div class="form-floating form-group">
              <label class="resource-text m-0" for="description">Description</label>
              <textarea class="form-control" v-validate="'required|min:5'" data-vv-delay="500" name="description"
                        placeholder="Write down description here" rows="4" id="description"
                        v-model="product.description"></textarea>
              <span v-show="submittedHit && errors.has('description')"
                    class="help is-danger">{{ errors.first('description') }}</span>
            </div>
          </div>
          <div class="col-5">
            <div class="form-floating form-group">
              <label class="resource-text m-0" for="how_to_use">Shipping Time</label>
              <textarea class="form-control" v-validate="'required|min:5'" data-vv-delay="500" name="shipping_time"
                        placeholder="Write down shipping time" rows="4" id="how_to_use"
                        v-model="product.shipping_time"></textarea>
              <span v-show="submittedHit && errors.has('shipping_time')" class="help is-danger">{{ errors.first('shipping_time') }}</span>
            </div>
          </div>
          <div class="col-2">
              <div class="form-group">
                <label class="resource-text m-0" for="premium">Premium</label>
                <select class="form-control" v-model="product.premium" v-validate="'required'" id="premium">
                  <option value="1">Yes</option>
                  <option value="0">No</option>
                </select>
                <span v-show="submittedHit && errors.has('premium')" class="help is-danger">{{ errors.first('premium') }}</span>
              </div>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-4">
            <label class="resource-text m-0" for="category1">Size</label>
            <input type="text" class="form-control" data-vv-delay="500" name="weight" v-model="product.size">
            <span v-show="submittedHit && errors.has('size')" class="help is-danger">{{ errors.first('size') }}</span>
          </div>
          <div class="col-4">
            <div class="form-floating form-group">
              <label class="resource-text m-0" for="description">Available Quantity</label>
              <input type="number" class="form-control" v-validate="'required|integer'" data-vv-delay="500" name="available_quantity"
                        placeholder="" id="available_quantity"
                        v-model="product.available_quantity">
              <span v-show="submittedHit && errors.has('available_quantity')"
                    class="help is-danger">{{ errors.first('available_quantity') }}</span>
            </div>
          </div>
          <div class="col-4">
            <label class="resource-text m-0" for="minimum_order_quantity">Minimum Order Quantity</label>
            <input type="number" class="form-control" data-vv-delay="500" name="minimum_order_quantity" v-model="product.minimum_order_quantity" v-validate="'integer'">
            <span v-show="submittedHit && errors.has('minimum_order_quantity')" class="help is-danger">{{ errors.first('minimum_order_quantity') }}</span>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-4 align-self-center">
            <div class="form-group">
              <label class="resource-text m-0" for="supplier">Supplier</label>
              <select class="form-control" v-validate="'required'" data-vv-delay="500" name="supplier"
                      id="supplier" v-model="product.supplier_id">
                <option v-for="supplier in suppliers" :key="supplier.id" :value="supplier.id" :selected="supplier.id == product.supplier_id">{{supplier.name}}</option>
              </select>
              <span v-show="submittedHit && errors.has('supplier')"
                    class="help is-danger">{{ errors.first('supplier') }}</span>
            </div>
          </div>
          <div class="col-4 align-self-center">
            <div class="form-group">
              <label class="resource-text m-0" for="supplier_article_number">Supplier Article Number</label>
              <input type="text" class="form-control" data-vv-delay="500" name="supplier_article_number"
                     id="supplier_article_number" v-model="product.supplier_article_number">
              <span v-show="submittedHit && errors.has('supplier_article_number')"
                    class="help is-danger">{{ errors.first('supplier_article_number') }}</span>
            </div>
          </div>
          <div class="col-4 align-self-center">
            <div class="form-group">
              <label class="resource-text m-0" for="weight">Weight</label>
              <input type="text" class="form-control" data-vv-delay="500" name="weight" v-model="product.weight" v-validate="'required'">
              <span v-show="submittedHit && errors.has('weight')" class="help is-danger">{{ errors.first('weight') }}</span>
            </div>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-4 align-self-center">
            <div class="form-group">
              <label class="resource-text m-0" for="capacity">Capacity</label>
              <input type="text" class="form-control" data-vv-delay="500" name="capacity"
                     id="capacity" v-model="product.capacity">
              <span v-show="submittedHit && errors.has('capacity')"
                    class="help is-danger">{{ errors.first('capacity') }}</span>
            </div>
          </div>          
          <div class="col-4 align-self-center">
            <div class="form-group">
              <label class="resource-text m-0" for="shipping_price">Shipping price</label>
              <input type="text" class="form-control" data-vv-delay="500" name="shipping_price" v-model="product.shipping_price" v-validate="'required'">
              <span v-show="submittedHit && errors.has('shipping_price')" class="help is-danger">{{ errors.first('shipping_price') }}</span>
            </div>
          </div>
          <div class="col-2 align-self-center">
            <div class="form-group">
              <label class="resource-text m-0" for="microwavable">Microwavable</label>
              <select class="form-control" v-model="product.microwavable" id="microwavable">
                <option value="1">Yes</option>
                <option value="0">No</option>
              </select>
              <span v-show="submittedHit && errors.has('microwavable')" class="help is-danger">{{ errors.first('microwavable') }}</span>
            </div>
          </div>
          <div class="col-4 align-self-center">
            
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-4 text-center">
            <div class="image-container">
                 <img v-if="product.image_1  && imageFromDB1" ref="dbImage1" class="mb-4 img-fluid"
                 :src="appURL + product.image_1" alt="Product image 1" :style="{height: imageHeight + 'px'}" @load="setHeight">
                <button v-if="product.image_1" class="btn btn-sm remove-button-2 btn-danger" title="Remove image" @click="removeImage('image_1')">
                <i class="fa fa-times"></i>
              </button>
            </div>
           
            <picture-input class="mb-3" ref="pictureInput1" @change="inputImage"
                           width="150" height="150" margin="0" accept="image/jpeg,image/png" size="2"
                           :hideChangeButton="true" :removable="false" :class="{'d-none':product.image_1  && imageFromDB1}">
            </picture-input>
            <button class="btn btn-primary btn-sm px-2 mx-auto enroute-btn resource-text resource-upload-btn"
                    @click="uploadImage1">Upload Photo</button>
          </div>
          <div class="col-4 text-center">
            <div class="image-container">
  <img v-if="product.image_2  && imageFromDB2" ref="dbImage2" class="mb-4 img-fluid"
                 :src="appURL + product.image_2" alt="Product image 2" :style="{height: imageHeight + 'px'}" @load="setHeight">
                   <button v-if="product.image_2" class="btn btn-sm remove-button-2 btn-danger" title="Remove image" @click="removeImage('image_2')">
                <i class="fa fa-times"></i>
              </button>
            </div>
          
            <picture-input class="mb-3" ref="pictureInput2" @change="inputImage"
                           width="150" height="150" margin="0" accept="image/jpeg,image/png" size="2"
                           :hideChangeButton="true" :removable="false" :class="{'d-none':product.image_2 && imageFromDB2}">
            </picture-input>
            <button class="btn btn-primary btn-sm px-2 mx-auto enroute-btn resource-text resource-upload-btn"
                    @click="uploadImage2">Upload Photo</button>
          </div>
          <div class="col-4 text-center">
           <div class="image-container">
              <img v-if="product.image_3 && imageFromDB3" ref="dbImage3" class="mb-4 img-fluid"
                 :src="appURL + product.image_3" alt="Product image 3" :style="{height: imageHeight + 'px'}" @load="setHeight">
                   <button v-if="product.image_3" class="btn btn-sm remove-button-2 btn-danger" title="Remove image" @click="removeImage('image_3')">
                <i class="fa fa-times"></i>
              </button>
           </div>
            <picture-input class="mb-3" ref="pictureInput3" @change="inputImage"
                           width="150" height="150" margin="0" accept="image/jpeg,image/png" size="2"
                           :hideChangeButton="true" :removable="false" :class="{'d-none':product.image_3 && imageFromDB3}">
            </picture-input>
            <button class="btn btn-primary btn-sm px-2 mx-auto enroute-btn resource-text resource-upload-btn"
                    @click="uploadImage3">Upload Photo</button>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-12 mt-3">
            <label class="resource-text m-0" for="category1">Video</label>
            <input type="file" name="video" @change="handleMediaUpload($event.target.files)" class="form-control-file">
          </div>
        </div>
        <div class="row py-6">
          <div class="col-3 align-self-center">
              <span class="resource-text phase resource-remove" @click="deleteProduct" title="Remove this product">Remove
                Permanently</span>
          </div>
          <div class="col-9 text-right">
<!--            <span class="mx-3" v-if="product.out_of_stock">-->
<!--              <span class="resource-text out-of-stock" @click="toggleOutOfStock">Stock Available</span>-->
<!--            </span>-->
<!--            <span class="mx-3" v-else>-->
<!--              <span class="resource-text out-of-stock" @click="toggleOutOfStock">Out Of Stock</span>-->
<!--            </span>-->
<!--            <span v-if="product.phase_out">-->
<!--              <span class="resource-text phase" @click="togglePhase">Phase In</span>-->
<!--            </span>-->
<!--            <span v-else>-->
<!--              <span class="resource-text phase" @click="togglePhase">Phase Out</span>-->
<!--            </span>-->
            <button class="btn btn-primary enroute-btn resource-save-btn ml-6" @click="updateProduct">Save
              Changes</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import axios from 'axios';
  import PictureInput from 'vue-picture-input';
  import {
    b64toBlob
  } from '../utils/helper';
  import countries from '../utils/countries';

  // import Multiselect from 'vue-multiselect';
  import "vue-multiselect/dist/vue-multiselect.min.css";

  export default {
    name: 'Product',
    components: {
      // Multiselect,
      PictureInput,
    },
    data: () => {
      return {
        appURL: process.env.VUE_APP_MAIN_URL,
        countries: countries,
        isLoading: false,
        product: [],
        submittedHit: false,
        imageFromDB: true,
        imageFromDB1: true,
        imageFromDB2: true,
        imageFromDB3: true,
        videoFromDB: true,
        imageHeight: 150,
        remove: false,
        brands: [],
        suppliers: [],
        categories: [],
        materials: [],
        skin_types: [
          { text: "Dry" },
          { text: "Oily" },
          { text: "Combination" },
          { text: "Normal" },
        ],
        skin_conditions: [
          { text: "Pigmentations" },
          { text: "Uneven skin tone" },
          { text: "Breakouts" },
          { text: "Blackheads" },
          { text: "Acne" },
          { text: "Fine lines" },
          { text: "Wrinkles" },
          { text: "Redness" },
          { text: "Irritation" },
          { text: "Oiliness" },
          { text: "Enlarged pores" },
          { text: "Uneven skin texture" },
          { text: "Dehydrated skin" },
          { text: "Dull skin" },
          { text: "Add glow" },
        ],
        lifestyle_factors: [
          { text: "Hay fever" },
          { text:   "Perfume" },
          { text:   "Breastfeeding" },
          { text:   "Pregnancy" },
          { text:   "Roucutan (isotretinioun)" },
          { text:   "Cortisol" },
          { text:   "Antibiotics" },
          { text:   "Kemotherapy" },
          { text:   "Topical/inhalor steriods" },
          { text:   "Bentzalyon-peroxid" },
        ],
        product_types: [
            "Pre-Cleanser",
            "Cleanser",
            "Toner",
            "Essence water",
            "Exfoliation",
            "Treatment",
            "Face mask",
            "Serum",
            "Eye cream",
            "Moisturiser",
            "SPF",
            "Face oil",
            "Face mist",
            "Supplement",
        ],
      }
    },
    methods: {
      getAllSuppliers: function () {
        axios.get("admin/suppliers/all?access_token=" + localStorage.getItem("access_token"), this.config)
          .then(result => {
            this.isLoading = false;
            this.suppliers = result.data.suppliers;
          }, error => {
            this.isLoading = false;
            return error;
          });
      },
      getAllCategories: function () {
        axios.get("admin/categories/all?access_token=" + localStorage.getItem("access_token"), this.config)
            .then(result => {
              this.isLoading = false;
              this.categories = result.data.categories;
            }, error => {
              this.isLoading = false;
              return error;
            });
      },
      getAllMaterials: function () {
        axios.get("admin/materials/all?access_token=" + localStorage.getItem("access_token"), this.config)
            .then(result => {
              this.isLoading = false;
              this.materials = result.data.materials;
            }, error => {
              this.isLoading = false;
              return error;
            });
      },
      displayArrayValues: function(array) {
        return array.map(element => element.text).toString();
      },
      uploadImage: function () {
        this.$refs.pictureInput.$refs.fileInput.click();
      },
      uploadImage1: function () {
        this.$refs.pictureInput1.$refs.fileInput.click();
      },
      uploadImage2: function () {
        this.$refs.pictureInput2.$refs.fileInput.click();
      },
      uploadImage3: function () {
        this.$refs.pictureInput3.$refs.fileInput.click();
      },
      inputImage: function () {
        if (this.$refs.pictureInput.image) {
          this.product.image = this.$refs.pictureInput.image;
          this.imageFromDB = false;
        }
        if (this.$refs.pictureInput1.image) {
          this.product.image_1 = this.$refs.pictureInput1.image;
          this.imageFromDB1 = false;
        }
        if (this.$refs.pictureInput2.image) {
          this.product.image_2 = this.$refs.pictureInput2.image;
          this.imageFromDB2 = false;
        }
        if (this.$refs.pictureInput3.image) {
          this.product.image_3 = this.$refs.pictureInput3.image;
          this.imageFromDB3 = false;
        }
      },
      handleMediaUpload: function (fileList) {
        this.videoFromDB = false;
        this.product.video = fileList[0];
      },
      setHeight: function() {
        this.imageHeight = 150;
      },
      setProductDetails: function(product) {
        this.product = product;
      },
      getProduct: function () {
        this.isLoading = true;
        axios({
          method: "GET",
          url: `/admin/products/${this.$route.params.product_id}?access_token=${localStorage.getItem('access_token')}`,
          headers: {
            "content-type": "application/json"
          }
        }).then(
          result => {
            if (result) {
              this.setProductDetails(result.data.product);
              this.isLoading = false;
            }
          },
          error => {
            console.log(error);
            this.isLoading = false;
          }
        );
      },
      updateProduct: function () {
        this.$validator.validateAll().then((result) => {
          result;
          this.submittedHit = true;
          if (result) {
            const formDataToUpload = new FormData();
            if (!this.imageFromDB) {
              const blob = b64toBlob(this.product.image);
              formDataToUpload.append("image", blob);
            }
            if (!this.imageFromDB1) {
              const blob = b64toBlob(this.product.image_1);
              formDataToUpload.append("image_1", blob);
            }
            if (!this.imageFromDB2) {
              const blob = b64toBlob(this.product.image_2);
              formDataToUpload.append("image_2", blob);
            }
            if (!this.imageFromDB3) {
              const blob = b64toBlob(this.product.image_3);
              formDataToUpload.append("image_3", blob);
            }
            if (!this.videoFromDB && this.product.video) {
              const blob = b64toBlob(this.product.video);
              formDataToUpload.append("video", blob);
            }
            for (const key in this.product) {
              if (key !== 'image' && key !== 'image_1' && key !== 'image_2' && key !== 'image_3' && key !== 'video') {
                  formDataToUpload.append(key, this.product[key]);
              }
            }
            if(this.product.premium == "0"){
              this.product.premium = false;
            } else {
              this.product.premium = true;
            }
            if(this.product.microwavable == "0"){
              this.product.microwavable = false;
            } else {
              this.product.microwavable = true;
            }
            this.isLoading = true;
            axios({
              method: "POST",
              url: "admin/products/update/" + this.$route.params.product_id,
              data: formDataToUpload,
              headers: {
                "content-type": "multipart/form-data"
              }
            }).then(
              result => {
                this.submittedHit = false;
                if (result) {
                  this.setProductDetails(result.data.data);
                  this.$noty.success("Changes saved!");
                  this.isLoading = false;
                }
              },
              error => {
                this.submittedHit = false;
                this.isLoading = false;
                console.log(error.response);
              }
            );
          }
          this.isLoading = false;
          // } else {
          //   this.$noty.error("Please fill all fields.");
          // }
        }).catch(() => {
          this.isLoading = false;
        });
      },
      deleteProduct: function () {
        this.remove = true;
        if(!confirm("Are you sure to delete this product ?")){
          return;
        }
        axios({
          method: "DELETE",
          url: "admin/products/delete/" + this.$route.params.product_id,
          headers: {
            "content-type": "application/json"
          }
        }).then(
            result => {
              if (result) {
                this.remove = true;
                this.$noty.success("Product deleted.");
                this.$router.push(`/products`);
              }
            },
            error => {
              console.log(error);
            }
        );
      },
      togglePhase: function () {
        if(!this.product.phase_out){
          this.remove = true;
          this.$router.push('/replaceProduct/' + this.product.id);
          return;
        }
        axios({
          method: "POST",
          url: `admin/products/phase-in/${this.product.id}?access_token=${localStorage.getItem('access_token')}`,
          headers: {
            "content-type": "application/json"
          }
        }).then(
          result => {
            if (result) {
              this.remove = true;
              this.$router.push(`/products`);
            }
          },
          error => {
            console.log(error);
          }
        );
      },
      removeImage : function (imageType) {
        this.$swal({
          title: "Are you sure?",
          text: "Once deleted, you will not be able to recover this image!",
          icon: "warning",
          buttons: true,
            dangerMode: true,
            })
        .then((willDelete) => {
          if (willDelete) {
              axios({
          method: "DELETE",
          url: `admin/products/image?id=${this.product.id}&access_token=${localStorage.getItem('access_token')}&image=${imageType}`,
          headers: {
            "content-type": "application/json"
          }
        }).then(
          result => {
            if (result) {
             this.$swal("Image has been deleted successfully", {
              icon: "success",
            });
            this.getProduct();
            }
          },
          error => {
            console.log(error);
          }
        );
          
          } else {
            this.$swal("Your image is safe!");
          }
        });
       
      },
     
      toggleOutOfStock: function () {
        axios({
          method: "POST",
          url: `admin/products/toggle-out-of-stock/${this.product.id}?access_token=${localStorage.getItem('access_token')}`,
          headers: {
            "content-type": "application/json"
          }
        }).then(
          result => {
            if (result) {
              this.remove = true;
              this.$router.push(`/products`);
            }
          },
          error => {
            console.log(error);
          }
        );
      },
    },
    beforeRouteLeave(to, from, next) {
      if(this.remove){
        next();
      }else{
        const answer = window.confirm('Do you really want to leave? Any unsaved changes will not be saved automatically.')
        if (answer) {
          next()
        } else {
          next(false)
        }
      }
    },
    mounted() {
      this.getAllSuppliers();
      this.getAllCategories();
      this.getAllMaterials();
      this.getProduct();
    }
  };
</script>

<style lang="scss" scoped>
</style>
