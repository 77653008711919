<template>
  <div class="entry-content">
    <div class="container-fluid">
      <div class="container pb-3">
        <div class="top-row d-flex justify-content-between align-items-center">
          <h1 class="page-title m-0">
            Sales Orders
          </h1>
          <div class="d-flex flex-row align-items-center">
            <label class="resource-small-text m-0 mr-1" for="from">From: </label>
            <input type="date" v-model="from" class="form-control resource-field py-0 px-1 mr-1" id="from" @change="getSalesOrders">
            <label class="resource-small-text m-0 mr-1" for="to">To: </label>
            <input type="date" v-model="to" class="form-control resource-field py-0 px-1 mr-1" id="to" @change="getSalesOrders">
          </div>
<!--          <a class="btn exportBtn" :href="url + 'export/users'">Export All</a>-->
        </div>
        <div class="row align-items-end m-0 mt-2">
          <div class="offset-9 col-3 d-flex justify-content-end p-0">
            <div style="max-width: 150px;">
              <label class="resource-small-text m-0" for="sort">Sort by</label>
              <select v-model="sort" class="resource-select custom-select py-0 px-1" id="sort" @change="getSalesOrders">
                <option value="id" selected>Default</option>
                <option value="user_id">User Id</option>
              </select>
            </div>
          </div>
        </div>
        <!-- users -->
        <div v-if="loadingOrders" class="w-100 d-flex justify-content-center align-items-center" style="height: 60vh;">
          <div class="loader">
          </div>
        </div>
        <div v-else class="row my-3">
          <div class="table-responsive table-hover">
            <table class="table mt-4">
              <thead>
              <tr>
                <th class="resource-small-text" scope="col">
                  Sales Order ID
                </th>
                <th class="resource-small-text" scope="col">
                  Type
                </th>
                <th class="resource-small-text" scope="col">Date</th>
                <th class="resource-small-text" scope="col">Customer ID</th>
                <th class="resource-small-text" scope="col">Total</th>
                <th class="resource-small-text" scope="col">&nbsp;</th>
              </tr>
              </thead>
              <tbody>
              <tr class="bg-white" v-for="order in orders" :key="order.id">
                <td>{{ order.id }}</td>
                <td class="text-capitalize">{{ order.type ? order.type : "Product" }}</td>
                <td>{{ order.created_at | moment }}</td>
                <td>{{ order.user_id }}</td>
                <td>{{ order.total_price }}</td>
                <td>
                  <ul class="pl-0 mb-0">
                  <li class="nav-item dropdown left-width ml-2 py-0">
                    <base-dropdown data-boundary="viewport" class="nav-link px-0 float-right">
                      <div class="media align-items-center" slot="title">
                        <div class="media-body d-none d-sm-block">
                          <img
                                  src="img/icons/second-menu-normal-brown.svg"
                                  alt="second menu"
                          />
                        </div>
                      </div>
                      <template class="new">
                        <div class="menu-block">
                          <router-link
                                  :to="'/sales-order/' + order.id"
                                  class="py-3 pr-3 pl-1 d-flex justify-content-center">
                            <span class="medium-text" style="background-color: #ffffff !important;"> Open Sales Order </span>
                          </router-link>
                        </div>
                      </template>
                    </base-dropdown>
                  </li>
                  </ul>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div style="padding: 22px;">
          <pagination :total="total" @fetchData="getSalesOrders" v-if="total > 1" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import axios from 'axios';
  import pagination from '../components/Pagination.vue';
  import moment from "moment";

  export default {
    name: 'SalesOrders',
    components: {
      pagination
    },
    filters: {
      moment: function (date) {
        let d = moment(date);
        return d.isValid() ? moment(date).format("MMM D, YYYY") : "N/A";
      },
    },
    data: () => {
      return {
        appURL: process.env.VUE_APP_MAIN_URL,
        orders: [],
        url: '',
        total: 1,
        search: '',
        sort: 'id',
        from: '',
        to: '',
        loadingOrders: true,
      }
    },
    methods: {
      searchKeydown: function (e) {
        if (e.keyCode === 13) {
          if (this.search.length >= 3 || this.search == '') {
            this.getSalesOrders();
          } else {
            this.$noty.error("Please enter 3 or more characters");
          }
        }
      },
      searchInput: function () {
        if (!this.search) {
          this.getSalesOrders();
        }
      },
      getSalesOrders: function (page = 1) {
        this.loadingOrders = true;
        let url = `admin/sales-orders/all?page=${page}&access_token=${localStorage.getItem('access_token')}`;
        if (this.sort) {
          if (this.sort.includes('_desc')) {
            url += `&sortBy=${this.sort.split('_desc')[0]}&orderBy=DESC`;
          } else {
            url += `&sortBy=${this.sort}`;
          }
        }
        // if (this.search) {
        //   url += `&searchBy=${this.search}`;
        // }
        if (this.from) {
          url += `&from=${this.from}`;
        }
        if (this.to) {
          url += `&to=${this.to}`;
        }
        axios({
          method: "GET",
          url: url,
          headers: {
            "content-type": "application/json"
          }
        }).then(
          result => {
            if (result) {
              this.orders = result.data.orders.data;
              console.log(this.orders);
              this.total = Math.ceil(result.data.orders.total / result.data.orders.per_page);
              this.loadingOrders = false;
            }
          },
          error => {
            console.log(error);
            this.loadingOrders = false;
          }
        );
      },
      goToSalesOrder: function (id) {
        this.$router.push(`sales-order/${id}`);
      },
    },
    computed: {
    },
    mounted() {
      localStorage.setItem('page', '1');
      this.getSalesOrders();
      this.url = axios.defaults.baseURL;
    }
  };
</script>

<style lang="scss" scoped>
  @media (max-width: 767px) {
    .table-responsive .dropdown-menu {
      position: static !important;
    }
  }
  @media (min-width: 768px) {
    .table-responsive {
      overflow: visible;
    }
  }
  li > li{
    padding-top:0;
    padding-bottom: 0;
  }

  .loader {
    border: 4px solid rgb(51, 73, 87);
    border-top: 4px solid #f3f3f3;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 2s linear infinite;
  }
    
  @keyframes spin {
      0% { transform: rotate(0deg); }
      100% { transform: rotate(360deg); }
  }
</style>
