<template>
  <div class="entry-content">
    <router-link to="/users">
      <h1 class="back m-0 ml-4"><img class="align-baseline" src="/img/icons/arrow-left.svg" height="15"
          alt="left-arrow"> Back</h1>
    </router-link>
    <div class="container-fluid">
      <div class="container pb-3">
        <!-- user -->
        <div class="row my-4">
          <div class="col-2 bg-white p-3 header-box">
            <img v-if="(user.image)" :src="appURL + user.image" class="img-fluid rounded-circle mr-2" alt="user image"
              style="width: auto; max-height: 109px;" />
            <img v-else src="../assets/nav-user.png" class="rounded-circle bg-black px-3 py-2 mr-2" alt="default image"
              style="width: auto; max-height: 110px;" />
          </div>
          <div class="col-4 bg-white mr-3 p-0 pl-2">
            <h2 class="resource-text mt-2">{{ user.name }}</h2>
            <h4 class="resource-text">{{ user.email }}</h4>
            <h4 class="resource-text">
              Member Since
              {{ user.created_at | moment }}
            </h4>
          </div>
        </div>
        <div class="order-history col-lg-12">
          <div>
            <h1 class="top-title">Order History</h1>
          </div>
          <div v-if="orders.length" class="table-responsive table-hover">
            <table class="table mt-4">
              <thead style="border-radius: 4px; background-color: #deeddf;">
              <tr class="header">
                <th>Order Details</th>
                <th>Products</th>
                <th>Ships to</th>
                <th>Total</th>
              </tr>
              </thead>
              <tbody >
                <tr class="bg-white cursor-pointer" v-for="order in orders" :key="order.id">
                <td>Order #: {{order.id}}<br><b>{{order.created_at | moment }}</b></td>
                <td>
                  <div v-for="product in order.orderItems" :key="product.id">
                    {{product.quantity + ' x ' + product.product.name || '-'}}
                  </div>
                </td>
                <td>
                  {{(shippingDetails)?shippingDetails.address:''}}<br>
                  {{(shippingDetails)?shippingDetails.city:''}}, {{(shippingDetails)?shippingDetails.country:''}} , {{(shippingDetails)?shippingDetails.postcode:'-'}}</td>
                <td>{{getTotal(order) || '-'}}</td>
              </tr >
              </tbody>
            </table>
          </div>
          <div v-else class="py-5">
            <div class="w-100 d-flex align-items-center justify-content-center flex-column">
              <h3>
                Nothing to show here!
              </h3>
              <p>
                There are no orders made so far.
              </p>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>
<script>
  import axios from "axios";
  import moment from "moment";

  export default {
    name: "user",
    components: {
    },
    filters: {
      moment: function (date) {
        let d = moment(date);
        return d.isValid() ? moment(date).format("MMM D, YYYY") : "N/A";
      },
    },
    data: () => {
      return {
        appURL: process.env.VUE_APP_MAIN_URL,
        user: {},
        orders: {},
        shippingDetails: {},
      };
    },
    methods: {
      getUser: function () {
        let url = `admin/users/${this.$route.params.user_id}?access_token=${localStorage.getItem('access_token')}`;
        axios({
          method: "GET",
          url: url,
          headers: {
            "content-type": "application/json",
          },
        }).then(
          (result) => {
            if (result) {
              this.user = result.data.user;
              this.orders = result.data.orders;
              this.shippingDetails = result.data.shipping;
              console.log(result);
            }
          },
          (error) => {
            console.log(error);
          }
        );
      },
      getTotal: function(order){
        let total = 0;
        order.orderItems.forEach(item => {
          total = total + parseFloat(item.price);
        });
        return total;
      },
    },
    mounted() {
      this.getUser();
    },
  };
</script>

<style lang="scss" scoped>
  .header-box {
    border-radius: 2px;
    box-shadow: 0 2px 5px -2px rgba(30, 30, 30, 0.2);
  }

  h2.product-title{
    color: #1e1e1e;
  }

  .product-brand{
    color: #1e1e1e;
    font-weight: normal;
  }

  .product-type{
    color: #1e1e1e;
    font-weight: normal;
    opacity: 0.7;
  }
</style>