<template>
  <div class="entry-content">
    <div class="container-fluid">
      <div class="container pb-3">
        <div class="top-row d-flex justify-content-between">
          <h1 class="page-title m-0">
            Subscribers
          </h1>
        </div>
        <div class="row align-items-end m-0">
          <div class="col-9">
            <div class="row align-items-center">
              <div class="form-group m-0 float-left mr-5">
                <div class="input-group search">
                  <div class="input-group-prepend m-0">
                    <span class="input-group-text border-0"><img src="/img/icons/search.svg" alt="search"></span>
                  </div>
                  <input class="form-control resource-field border-0 pl-2" placeholder="Search email ..." type="text"
                    v-model="search" @keydown="searchKeydown" @input="searchInput">
                </div>
              </div>
            </div>
          </div>
          <div class="col-3 d-flex justify-content-end p-0">
            <div style="max-width: 150px;">
              <label class="resource-small-text m-0" for="sort">Sort by</label>
              <select v-model="sort" class="resource-select custom-select py-0 px-1" id="sort" @change="getUsers">
                <option value="email" selected>Email (A - Z)</option>
                <option value="email_desc" selected>Email (Z - A)</option>
                <option value="subscribedDate_desc">Date (Latest)</option>
                <option value="subscribedDate">Date (Earliest)</option>
              </select>
            </div>
          </div>
        </div>
        <!-- users -->
        <div>
            <div class="row" style="margin-top: 20px;">
                <div class="text col-3">Name</div>
                <div class="text col-5">Email</div>
                <div>Created Date</div>
            </div>
            <div style="padding-top: 10px; padding-bottom: 10px;">
                <div style="background-color:black; width: 100%; height: 1px;">

                </div>
            </div>
            <div class="row" v-for="user in filteredUsers" :key="user.id">
                <p class="col-3">{{user.name || "/"}}</p>
                <p class="col-5">{{user.email || "- No Email Provided -"}}</p>
                <p>{{user.subscribedDate}}</p>
            </div>
        </div>
        <div style="padding: 22px;">
          <pagination :total="total" @fetchData="getUsers" v-if="total > 1" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import axios from 'axios';
  import pagination from '../components/Pagination.vue';

  export default {
    name: 'Subscribers',
    components: {
      pagination
    },
    data: () => {
      return {
        appURL: process.env.VUE_APP_MAIN_URL,
        subscribers: [],
        url: '',
        total: 1,
        search: '',
        sort: 'email',
      }
    },
    methods: {
      searchKeydown: function (e) {
        if (e.keyCode === 13) {
          if (this.search.length >= 3 || this.search == '') {
            this.getUsers();
          } else {
            this.$noty.error("Please enter 3 or more characters");
          }
        }
      },
      searchInput: function () {
        if (!this.search) {
          this.getUsers();
        }
      },
      getUsers: function (page = 1) {
        let url = `admin/subscribers?page=${page}&access_token=${localStorage.getItem('access_token')}`;
        if (this.sort) {
          if (this.sort.includes('_desc')) {
            url += `&sortBy=${this.sort.split('_desc')[0]}&orderBy=DESC`;
          } else {
            url += `&sortBy=${this.sort}`;
          }
        }
        if (this.search) {
          url += `&searchBy=${this.search}`;
        }
        axios({
          method: "GET",
          url: url,
          headers: {
            "content-type": "application/json"
          }
        }).then(
          result => {
            if (result) {
              this.subscribers = result.data.subscribers.data;
              this.total = Math.ceil(result.data.subscribers.total / result.data.subscribers.per_page);
            }
          },
          error => {
            console.log(error);
          }
        );
      },
    },
    computed: {
      filteredUsers() {
        return this.subscribers.filter((item) => {
          return item;
        })
      },
    },
    mounted() {
      this.getUsers();
      this.url = axios.defaults.baseURL;
    }
  };
</script>

<style lang="scss" scoped>
  .color-box {
    width: 25px;
    height: 12px;
  }

  .request-box {
    background-color: #f2d3bc;
  }

  .suggestion-box {
    background-color: black;
  }

  .rejection-box {
    background-color: #a5a5a5;
  }

  .user-request-border {
    border: 8px solid #f2d3bc;
  }

  .client-suggestion-border {
    border: 8px solid black;
  }

  .client-rejection-border {
    border: 8px solid #a5a5a5;
  }
</style>
