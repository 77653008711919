<template>
  <div class="entry-content">
    <div class="container-fluid">
      <div class="container pb-3">
        <div class="top-row d-flex justify-content-between">
          <h1 class="page-title m-0">
            Products
          </h1>
<!--          <a class="btn exportBtn" :href="url + 'export/products'">Export All</a>-->
        </div>
        <div class="row align-items-end m-0">
          <div class="col-9">
            <div class="row align-items-center">
              <div class="form-group m-0 float-left mr-5">
                <div class="input-group search">
                  <div class="input-group-prepend m-0">
                    <span class="input-group-text border-0"><img src="/img/icons/search.svg" alt="search"></span>
                  </div>
                  <input class="form-control resource-field border-0 pl-2" placeholder="Search name ..." type="text" v-model="search"
                    @keydown="searchKeydown" @input="searchInput">
                </div>
              </div>
              <router-link to="/add-product">
                <img class="add-new-icon" src="/img/icons/icon-plus.svg" alt="">
                <span class="add-new pl-1 ">Add New</span>
              </router-link>
            </div>
          </div>
          <div class="col-3 d-flex justify-content-end p-0">
            <div style="max-width: 190px;">
              <label class="resource-small-text m-0" for="sort">Sort by</label>
              <select v-model="sort" class="resource-select custom-select py-0" id="sort" @change="getProducts">
                <option value="name" selected>Name</option>
                <option value="created_at">Date</option>
                <option value="price">Price (Asc)</option>
                <option value="price_desc">Price (Desc)</option>
                <option value="brand">Brand (Asc)</option>
                <option value="brand_desc">Brand (Desc)</option>
              </select>
            </div>
          </div>
          <div  style="margin-top: 15px; margin-left: auto; margin-right: 0;">
            <input style="margin-right: 13px;" type="file" ref="file" multiple="multiple" accept=".jpg">
            <button @click="importImages" class="btn exportBtn">Import images</button>
          </div>
          <div style="margin-top: 15px; margin-left: auto; margin-right: 0;">
            <input type="file" id="file" name="file" accept=".xlsx" @change="handleFileUpload($event)"/>
            <button @click="importProducts" class="btn exportBtn">Import products</button>
          </div>
        </div>
        <!-- products -->
        <div class="row my-4">
          <div class="col-3 card resource bg-white m-3 p-0" v-for="product in products" :key="product.id">
            <div class="bg-black image resource-image-block">
              <img v-if="(product.image)" :src="appURL + product.image"
                alt="product image" style="width: 100%; max-height: 109px; object-fit: contain;">
              <img v-else src="../assets/product-bottle.png"
                class="my-3" alt="default image" style="width: auto; max-height: 75px;">
            </div>
            <p class="text-center resource-large-text m-0 p-0 mt-1">{{product.name}}</p>
            <p class="text-center resource-text p-0 m-0">{{product.brand}}</p>
            <div class="row justify-content-center mb-3">
              <router-link :to="'/product/'+product.id">
                <button class="btn btn-primary btn-sm resource-small-text enroute-btn resource-edit-btn px-4">Edit</button>
              </router-link>
            </div>
          </div>
        </div>

        <pagination v-model="page" :page="page" :total="total" @fetchData="getProducts" v-if="total > 1"/>
      </div>
    </div>
  </div>
</template>
<script>
  import axios from 'axios';
  import pagination from '../components/Pagination.vue';

  export default {
    name: 'Product',
    components: {
      pagination
    },
    data: () => {
      return {
        appURL: process.env.VUE_APP_MAIN_URL,
        products: [],
        total: 1,
        search: '',
        sort: 'name',
        url: '',
        file: '',
        page: localStorage.getItem('page')
      }
    },
    methods: {
      searchKeydown: function(e){
        if (e.keyCode === 13) {
          if(this.search.length>=3 || this.search==''){
            this.getProducts();
          }
          else{
            this.$noty.error("Please enter 3 or more characters");
          }
        }
      },
      searchInput: function(){
        if(!this.search){
          this.getProducts();
        }
      },
      importImages: function(){
        let formData = new FormData();
        let size = this.$refs.file.files.length
        for( var i = 0; i < size; i++ ){
          let file = this.$refs.file.files[i];
          formData.append('image' + i, file);
        }
        formData.append('size', size);
        let url = "admin/products/import-images";
        axios({
          method: "POST",
          url: url,
          data: formData,
          headers: {
            "content-type": "multipart/form-data"
          }
        }).then(
          result => {
            if (result) {
              this.$noty.success("images imported!");
            }
          }
        );
      },
      importProducts : function(){
        if(this.file != ''){
          let url = "admin/products/import";
          var formData = new FormData();
          formData.append("file", this.file);
          axios({
            method: "POST",
            url: url,
            data: formData,
            headers: {
              "content-type": "application/json"
            }
          }).then(
            result => {
              if (result) {
                this.$noty.success("Products imported!");
              }
            },
            error => {
              this.$noty.error("Format is not correct!");
              console.log(error);
            })
          }else{
            this.$noty.error("No file selected!");
          }
      },
      handleFileUpload(event){
        this.file = event.target.files[0];
        console.log(this.file.name);
        if(!this.file.name.endsWith(".xlsx")){
          this.$noty.error("This file should be an excel file");
          this.file = "";
          document.getElementById("file").value = "";
        }
        console.log(this.file);
      },
      getProducts: function (page=localStorage.getItem("page")) {
        this.page = page;
        console.log("test: " + this.page);
        localStorage.setItem("page", this.page);
        console.log("set: " + localStorage.getItem("page"))
        let url = `admin/products/all?page=${this.page}&access_token=${localStorage.getItem('access_token')} `;
        if(this.sort){
          if(this.sort.includes('_desc')){
            url += `&sortBy=${this.sort.split('_desc')[0]}&orderBy=DESC`;
          }else{
            url += `&sortBy=${this.sort}`;
          }
        }
        if(this.search){
          url += `&searchBy=${this.search}`;
        }
        axios({
          method: "GET",
          url: url,
          headers: {
            "content-type": "application/json"
          }
        }).then(
          result => {
            if (result) {
              this.products = result.data.products.data;
              this.total = Math.ceil(result.data.products.total/result.data.products.per_page);
            }
          },
          error => {
            console.log(error);
          }
        );
      },
      phaseIn: function (id) {
        axios({
          method: "POST",
          url: `admin/products/phase-in/${id}?access_token=${localStorage.getItem('access_token')}`,
          headers: {
            "content-type": "application/json"
          }
        }).then(
          result => {
            if(result){
              this.getProducts();
            }
          },
          error => {
            console.log(error);
          }
        );
      },
    },
    mounted() {
      console.log("page: " + localStorage.getItem('page'));
      this.getProducts();
      this.url = axios.defaults.baseURL;
    }
  };
</script>

<style lang="scss" scoped>
</style>
