<template>
    <div class="background">
      <slide-y-up-transition mode="out-in" origin="center top">
        <router-view></router-view>
      </slide-y-up-transition>
    </div>
</template>
<script>
  import { SlideYUpTransition } from 'vue2-transitions'

  export default {
    name: 'auth-layout',
    components: {
      SlideYUpTransition
    },
    data() {
      return {
        year: new Date().getFullYear(),
        showMenu: false,
      }
    },
    methods: {
    },
    created() {
      window.addEventListener("resize", this.calcPadding);
    },
    mounted: function() {
      this.calcPadding();
    }
  }
</script>
<style>

.background {
  margin: 0px !important;
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  right: 0;
  background-color: #f2f0eb;
}
</style>
