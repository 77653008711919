<template>
    <base-nav class="navbar-top navbar-dark"
              id="navbar-main"
              :show-toggle-button="false"
              expand>
        <ul class="navbar-nav align-items-center d-none d-flex ml-auto">
            <li class="nav-item">
                <div class="media logout pb-2 text-right" slot="title">
                    <div class="media-body ml-2 d-none d-lg-block">
                        <span class="email">{{email}}</span>
                    </div>
                        <a @click.prevent="logout" class="cursor-pointer pl-4">
                            Log Out
                        </a>
                    </div>
            </li>
        </ul>
    </base-nav>
</template>
<script>
    import router from "@/router";
    import axios from "axios";
  export default {
    data() {
      return {
        activeNotifications: false,
        showMenu: false,
        searchQuery: '',
        email: '',
      };
    },
    methods: {
      toggleSidebar() {
        this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
      },
      hideSidebar() {
        this.$sidebar.displaySidebar(false);
      },
      toggleMenu() {
        this.showMenu = !this.showMenu;
      },
        checkToken(){
            if(localStorage.getItem("access_token") !== null) {
                axios(
                    {
                        method: "GET",
                        "url": "admin/auth?access_token=" + localStorage.getItem('access_token'),
                        "headers": {
                            "content-type": "multipart/form-data"
                        },
                    }
                ).then(result => {
                    result;
                }, error => {
                    // eslint-disable-next-line no-console
                    if (error.response.status == 401) {
                        this.$swal(
                            "Error",
                            'Your session is expired. Please login again.',
                            "error"
                        );
                        localStorage.removeItem('user');
                        localStorage.removeItem('auth');
                        localStorage.removeItem('access_token');
                        router.push({path: "/login"});
                    }
                    // console.log(error.response);
                });
            }
        },
        logout(){
            axios(
                {
                    method: "GET",
                    "url": "admin/logout?access_token=" + localStorage.getItem('access_token'),
                    "headers": {
                        "content-type": "multipart/form-data"
                    },
                }
            ).then(result => {
                result;
                localStorage.removeItem('user');
                localStorage.removeItem('auth');
                localStorage.removeItem('access_token');
                router.push({ path: "/login" });
            }, error => {
                // eslint-disable-next-line no-console
                console.log(error.response);
            });
        },
    },
      mounted(){
          this.checkToken();
          setInterval(() => {
              this.checkToken();
          }, 1000 * 60 * 5); // Each 5 Minutes Check Token
          let user = JSON.parse(localStorage.getItem('user'));
          this.email = user.email;
      }
  };
</script>
<style lang="scss" scoped>
    .profile-pic, .cursor-pointer{
        cursor: pointer;
    }
    .logout{
        color: #1e1e1e;
        width: 265px;
        border-bottom: 1px solid #1e1e1e;
        font-size: 14px;
        line-height: 1.14;

        .email{
            opacity: 0.7;
        }

        a{
            font-weight: 400 !important;
        }
    }
</style>
