<template>
    <div class="entry-content">
        <h1 class="title ml-5">Messages</h1>
        <div class="container-fluid pt-2">
            <div class="container">
                <div v-if="!noClients" class="row">
                    <div class="col-lg-4 contact-list column rc">
                        <input type="text" class="search form-control resource-field border-0 pl-2" placeholder="Search users" v-model="searchUserField" @input="searchUser()">
                        <div class="user" :class="{'active': client.id == uId}" v-for="client in filteredClients"
                            :key="client.id" @click="uId = client.id">
                            <img v-if="client.profile_image" :src="appURL + client.profile_image"
                                alt="user image" class="rounded-circle" style="width: 40px; height: 40px;">
                            <img v-else src="../assets/nav-user.png" class="img bg-black">
                            <div class="d-flex flex-column flex-fill">
                                <div class="content">
                                    <p class="name">{{client.name}}</p>
                                    <p class="time">{{ latestTime(client.id) }}</p>
                                </div>
                                <div class="content">
                                    <p class="message">{{ latestMessage(client.id) }}</p>
                                    <span class="msgs" v-if="totalMessages[client.id] - client.total">{{ totalMessages[client.id] - client.total }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <message-block v-show="client.id == uId" v-for="client in clients" :uId="client.id" 
                        :key="client.id" :userImage="client.profile_image" :active="client.id == uId"></message-block>
                </div>
                <div v-else class="row pt-8">
                    <div class="col-lg-5"></div>
                    <div class="col-lg-7">
                        You do not have any active clients!
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import axios from "axios";
import moment from "moment";
import { mapState, mapActions } from 'vuex';
import MessageBlock from "../components/MessageBlock.vue";
import { db } from "../db-init";

export default {
    name: 'Messages',
    components: {
        MessageBlock
    },
    data: () => {
        return {
            appURL: process.env.VUE_APP_MAIN_URL,
            clients: [],
            filteredClients:[],
            uId: -1,
            noClients: false,
            searchUserField: '',
        }
    },
    methods: {
        ...mapActions([
            'updateUnread',
            'updateUsers',
            'updateMessages',
        ]),
        getClients: function () {
            let url = `admin/messages/users?access_token=${localStorage.getItem('access_token')}`
            axios({
                method: "GET",
                url: url,
                headers: {
                    "content-type": "application/json"
                }
            }).then(
                result => {
                    if (result) {
                        const data = result.data.users;
                        if(data.length){
                            this.updateUsers(data);
                            data.forEach(d => {
                                d.total = this.totalMessages[d.id];
                            });
                            this.clients = data;
                            if(this.uId == -1){
                                this.uId = this.clients[0].id;
                            }
                            this.searchUser();
                        }else{
                            this.noClients = true;
                        }
                    }
                },
                error => {
                    console.log(error);
                }
            );
        },
        latestMessage(user_id){
            const latest = this.latestMessages[user_id];
            if(latest){
                if(latest.type == 'image'){
                    return 'Image';
                }else if(latest.type == 'booking'){
                    return 'Booking';
                }else if(latest.type == 'alert'){
                    return 'Alert';
                }
                return this.latestMessages[user_id].message;
            }
            return '';
        },
        latestTime(user_id){
            if(this.latestMessages[user_id]){
                const end = moment();
                const startTime = moment(this.latestMessages[user_id].created);

                var duration = moment.duration(end.diff(startTime));
                var hours = duration.asHours();

                if(hours>24){
                    return moment(this.latestMessages[user_id].created).format("MMM-DD");
                }else{
                    return moment(this.latestMessages[user_id].created).format("HH.mm");
                }
            }
            return '';
        },
        updateTotalMessages: function(){
            const check = this.clients.find(client => client.id == this.uId);
            if(check){
                this.clients.find(client => client.id == this.uId).total = this.totalMessages[this.uId];
            }
            let unread = 0;
            this.clients.forEach(client => {
                unread += this.totalMessages[client.id] - client.total;
            });
            this.updateUnread(unread);
        },
        getMessages: function() {
            db.ref(
                `messages/admins/${1}`
            ).on(
                "value",
                (snapshot) => {
                const snap = snapshot.val();
                const messages = {};
                if (snap && snap.users) {
                    const users = snap.users;
                    const userIds = Object.keys(users);
                    userIds.forEach(user_id => {
                    const keys = Object.keys(users[user_id]);
                    const user_messages = keys.map(key => ({
                        id: key,
                        content: users[user_id][key],
                    }));
                    messages[user_id] = user_messages;
                    });
                }
                this.updateMessages(messages);
                this.searchUser();
                console.log(messages);
                },
                (error) => {
                console.error(error);
                }
            );
        },
        searchUser: function(){
            if(this.searchUserField == ''){
                this.filteredClients = [...this.clients];
            }else{
                this.filteredClients = [];
                this.clients.forEach((client)=>{
                    if(client.email && client.email.toLowerCase().includes(this.searchUserField.toLowerCase())){
                        this.filteredClients.push(client);
                    }
                });
            }

            this.filteredClients.forEach((client)=>{
                if(this.latestMessages[client.id]){
                    client.latestTime = this.latestMessages[client.id].created;
                }
                else{
                    client.latestTime = null;
                }
            })
            
            const tempClients = [...this.filteredClients];

            tempClients.sort(function(a, b) {
                console.log("Comparaing:", a,b);
                var keyA = a.latestTime,
                    keyB = b.latestTime;
                // Compare the 2 dates
                if (keyA > keyB) return -1;
                if (keyA < keyB) return 1;
                return 0;
                });

            tempClients.forEach((client)=>{
                console.log({filterClients: client.latestTime});
            })

            this.filteredClients = tempClients;
            
        }
    },
    computed: {
        ...mapState([
          'latestMessages',
          'totalMessages'
        ])
    },
    mounted() {
        this.getClients();
        this.getMessages();
    },
    watch: {
        totalMessages: function(newValue, oldValue){
            newValue;
            oldValue;
            this.updateTotalMessages();
        },
        uId: function(newValue, oldValue){
            newValue;
            oldValue;
            this.updateTotalMessages();
        },
    },
};
</script>

<style lang="scss" scoped>
    ::-webkit-scrollbar {
        width: 0px;
    }

    .contact-list {
        opacity: 0.9 !important;
        border-radius: 4px !important;
        box-shadow: 0 2px 5px -2px rgba(30, 30, 30, 0.2) !important;
        background: white;
        max-height: 77vh;
        margin: 0.5rem 1rem 0.5rem 0rem !important;
        padding: 0;
        overflow: scroll;
    }

    .column.rc .user {
        display: flex;
        padding: 13px;
        cursor: pointer;
    }

    .column.rc .active{
        background-image: linear-gradient(to right, #faf0f0, #f2e0dc 96%);
    }

    .column.rc .user .img {
        width: 40px;
        height: 40px;
        padding: 5px;
        border-radius: 50%;
        object-fit: contain;
    }

    .column.rc .user .content {
        padding-left: 12px;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        max-width: 184px;
    }

    .column.rc .user .content .name {
        font-size: 20px !important;
        font-weight: 300;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #825028;
        margin-bottom: 0;
    }

    .column.rc .user .content .time {
        opacity: 0.7;
        font-family: Inter;
        font-size: 10px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.6;
        letter-spacing: normal;
        color: #1e1e1e;
        margin-bottom: 0;
    }

    .column.rc .user .message {
        opacity: 0.7;
        font-family: Inter;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.14;
        letter-spacing: normal;
        color: #1e1e1e;
        margin-bottom: 0;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }

    .column.rc .user .content .msgs {
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.14;
        letter-spacing: normal;
        color: #fff;
        background-color: #db9b59;
        border-radius: 50%;
        width: 18px;
        height: 18px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .column.rc{
        max-width: 250px !important;
    }

    .search{
        width: 100%;
    }

</style>