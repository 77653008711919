<template>
  <div class="entry-content">
    <div v-if="loadingOrder" class="w-100 d-flex justify-content-center align-items-center" style="height: 60vh;">
      <div class="loader">
      </div>
    </div>
    <div v-else class="container-fluid">
      <div class="container pb-3">
        <div class="top-row d-flex justify-content-between align-items-center">
          <h1 class="page-title m-0">
              <router-link to="/sales-orders">
                <i class="fas fa-arrow-left cursor-pointer"></i>
            </router-link>
            Sales Order
          </h1>
          <div class="top-row d-flex justify-content-end align-items-right">
            <a class="btn exportBtn" :href="exportUrl">Export Order</a>
            <a class="btn exportBtn" :href="exportReturnedUrl">Export Returned Order</a>
          </div>
        </div>
        <div class="row align-items-end m-0 mt-2">
        </div>
        <!-- users -->
        <div class="row my-3">
          <div class="table-responsive table-hover">
            <table class="table mt-4">
              <thead>
              <tr>
                <th class="resource-small-text" scope="col">Customer ID</th>
                <td class="resource-small-text" scope="col">{{order.user_id}}</td>
                <th class="resource-small-text" scope="col" colspan="2">&nbsp;</th>
                <th class="resource-small-text" scope="col">Sales Order#</th>
                <td class="resource-small-text" scope="col">{{order.id}}</td>
                <td class="resource-small-text" scope="col">&nbsp;</td>
              </tr>
              <tr>
                <th class="resource-small-text" scope="col">Customer Name</th>
                <td class="resource-small-text" scope="col">{{order.user && order.user.name ? order.user.name : '-'}}</td>
                <th class="resource-small-text" scope="col" colspan="2">&nbsp;</th>
                <th class="resource-small-text" scope="col">Purchase Date</th>
                <td class="resource-small-text" scope="col">{{order.created_at | moment}}</td>
                <td class="resource-small-text" scope="col">&nbsp;</td>
              </tr>
              </thead>
              <tbody>
              <tr>
                <th class="resource-small-text" scope="col">Contact Details</th>
                <td colspan="3">
                  {{order.shipping_full_name}}<br>
                  {{order.shipping_email}}<br>
                  {{order.shipping_phone}}<br>
                </td>
                <th class="resource-small-text" scope="col" colspan="3">&nbsp;</th>
              </tr>
              <tr>
                <th class="resource-small-text" scope="col">Billing Details</th>
                <td colspan="3">
                  {{order.billing_address}}<br>
                  {{order.billing_city}}, {{order.billing_postcode}}<br>
                  {{order.billing_country}}
                </td>
                <th class="resource-small-text" scope="col" colspan="3">&nbsp;</th>
              </tr>
              <tr>
                <th class="resource-small-text" scope="col">Shipping</th>
                <td colspan="3">
                  {{order.shipping_method}}
                </td>
                <th class="resource-small-text" scope="col" colspan="3">&nbsp;</th>
              </tr>
              <tr>
                <th class="resource-small-text" scope="col">Shipping Address</th>
                <td colspan="3">
                  {{order.shipping_address}}<br>
                  {{order.shipping_city}}, {{order.shipping_postcode}}<br>
                  {{order.shipping_country}}
                </td>
                <th class="resource-small-text" scope="col" colspan="3">&nbsp;</th>
              </tr>
              <tr>
                <th class="resource-small-text" scope="col">Item#</th>
                <th class="resource-small-text" scope="col">Product Name</th>
                <th class="resource-small-text" scope="col">Supplier</th>
                <th class="resource-small-text" scope="col">Quantity</th>
                <th class="resource-small-text" scope="col">Price</th>
                <th class="resource-small-text" scope="col">&nbsp;</th>
              </tr>
              <tr v-for="item in order.orderItems" :key="item.id">
                <td>{{item.product ? item.product.id : '-'}}</td>
                <td>{{item.product ? item.product.name : '-'}}</td>
                <td>{{item.product ? item.product.brand : '-'}}</td>
                <td>{{item.quantity}}</td>
                <td>{{item.price}}</td>
                <td v-if="item.status == 'deleted' || item.status == 'returned'" class="text-capitalize">{{item.status}}</td>
                <td v-else>
                  <!-- <button class="btn btn-danger btn-sm" @click="removeItemFromSO(item.id)">Delete</button> &nbsp; -->
                  <button class="btn btn-info btn-sm" @click="returnItemFromSO(item.id)">Return</button>
                </td>
              </tr>
              </tbody>
              <tfoot>
              <tr>
                <td colspan="4">&nbsp;</td>
                <th class="resource-small-text" scope="col">Subtotal</th>
                <td>{{order.total_price}}</td>
              </tr>
              <tr>
                <td colspan="4">&nbsp;</td>
                <th class="resource-small-text" scope="col">Shipping</th>
                <td>{{order.shipping_charges}}</td>
              </tr>
              <tr>
                <td colspan="4">&nbsp;</td>
                <th class="resource-small-text" scope="col">VAT</th>
                <td>{{order.tax}}</td>
              </tr>
              <tr>
                <td colspan="4">&nbsp;</td>
                <th class="resource-small-text" scope="col">Total</th>
                <td>{{(parseFloat(order.total_price) + parseFloat(order.shipping_charges) + parseFloat(order.tax)).toFixed(2)}}</td>
              </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import axios from 'axios';
  import moment from "moment";

  export default {
    name: 'SalesOrder',
    filters: {
      moment: function (date) {
        let d = moment(date);
        return d.isValid() ? moment(date).format("YYYY-DD-MM") : "N/A";
      },
    },
    data: () => {
      return {
        appURL: process.env.VUE_APP_MAIN_URL,
        order: {},
        url: '',
        total: 1,
        search: '',
        sort: 'id',
        from: '',
        to: '',
        orderId: '',
        exportUrl: '',
        exportReturnedUrl: '',
        loadingOrder: true,
      }
    },
    methods: {
      searchKeydown: function (e) {
        if (e.keyCode === 13) {
          if (this.search.length >= 3 || this.search == '') {
            this.getSalesOrder();
          } else {
            this.$noty.error("Please enter 3 or more characters");
          }
        }
      },
      searchInput: function () {
        if (!this.search) {
          this.getSalesOrder(this.$route.params.id);
        }
      },
      getSalesOrder: function (id) {
        this.loadingOrder = true;
        let url = "admin/sales-orders/" + id;
        // if (this.search) {
        //   url += `&searchBy=${this.search}`;
        // }
        axios({
          method: "GET",
          url: url,
          headers: {
            "content-type": "application/json"
          }
        }).then(
          result => {
            if (result) {
              this.order = result.data.order;
              this.loadingOrder = false;
            }
          },
          error => {
            console.log(error);
            this.loadingOrder = false;
          }
        );
      },
      removeItemFromSO: function (id) {
        this.modalLoading = true;
        let url = "admin/sales-orders/remove/";

        axios({
          method: "POST",
          url: url,
          data: { item_ids: JSON.stringify([id]) },
          headers: {
            "content-type": "application/json",
          },
        }).then(
            (result) => {
              if (result) {
                this.getSalesOrder(this.$route.params.id);
              }
            },
            (error) => {
              this.modalLoading = false;
              console.log(error);
            }
        );
      },
      returnItemFromSO: function (id) {
         this.$swal({
          title: 'Are you sure you want to return the item?',
          text: "This action can not be undone.",
          icon: '',
          buttons: {
            cancel: true,
            confirm: true,
          },
        }).then((result) => {
          if (result) {
              this.modalSaved = false;
          this.modalLoading = true;
          let url = "admin/sales-orders/return/";

          axios({
            method: "POST",
            url: url,
            data: { item_ids: JSON.stringify([id]) },
            headers: {
              "content-type": "application/json",
            },
          }).then(
              (result) => {
                if (result) {
                  this.getSalesOrder(this.$route.params.id);
                }
              },
              (error) => {
                this.modalLoading = false;
                console.log(error);
              }
          );
        }
          })
        }
      
    },
    computed: {
    },
    mounted() {
      let id = this.$route.params.id;
      this.getSalesOrder(id);
      this.url = axios.defaults.baseURL;
      this.exportUrl = this.url + 'admin/sales-orders/export/' + this.$route.params.id + `?access_token=${localStorage.getItem('access_token')}`;
      this.exportReturnedUrl = this.url + 'admin/sales-orders/export-returned/' + this.$route.params.id + `?access_token=${localStorage.getItem('access_token')}`;
    }
  };
</script>

<style lang="scss" scoped>
  @media (max-width: 767px) {
    .table-responsive .dropdown-menu {
      position: static !important;
    }
  }
  @media (min-width: 768px) {
    .table-responsive {
      overflow: visible;
    }
  }
  li > li{
    padding-top:0;
    padding-bottom: 0;
  }

  .loader {
    border: 4px solid rgb(51, 73, 87);
    border-top: 4px solid #f3f3f3;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 2s linear infinite;
  }

  @keyframes spin {
      0% { transform: rotate(0deg); }
      100% { transform: rotate(360deg); }
  }
</style>
