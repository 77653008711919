<template>
  <div class="entry-content">
    <div class="container-fluid">
      <div class="container pb-3">
        <div class="top-row d-flex justify-content-between">
          <h1 class="page-title m-0">Alerts</h1>
          <a class="btn exportBtn" :href="url + 'export/messages'"
            >Export All</a
          >
        </div>
        <div class="table-responsive table-hover">
          <table class="table mt-4">
            <thead>
              <tr>
                <th
                  class="resource-small-text cursor-pointer"
                  scope="col"
                  @click="sortAlerts('name')"
                  title="sort by sender name"
                >
                  Sender <i class="fas fa-caret-down"></i>
                </th>
                <th
                  class="resource-small-text cursor-pointer"
                  scope="col"
                  @click="sortAlerts('created_at')"
                  title="sort by date"
                >
                  Date <i class="fas fa-caret-down"></i>
                </th>
                <th class="resource-small-text" scope="col">Tags</th>
                <th class="resource-small-text" scope="col">Content</th>
              </tr>
            </thead>
            <tbody>
              <tr class="bg-white" v-for="alert in alerts" :key="alert.id">
                <td>
                  <img
                    v-if="alert.image1"
                    :src="appURL + alert.image1"
                    class="rounded-circle float-left mr-2"
                    alt="user image"
                    height="50px"
                    width="50px"
                  />
                  <div
                    v-else
                    class="
                      placeholder-profile-pic
                      rounded-circle
                      float-left
                      mr-2
                    "
                  >
                    <img
                      src="../assets/nav-user.png"
                      alt="user image"
                      height="30px"
                      width="30px"
                    />
                  </div>
                  <p class="resource-text m-0 mt-2">{{ alert.name }}</p>
                  <p class="m-0 resource-small-text opacity-7">
                    {{ alert.email }}
                  </p>
                </td>
                <td>{{ alert.created_at | moment }}</td>
                <td>{{ alert.tags }}</td>
                <td
                  class="d-flex justify-content-between"
                  style="white-space: unset"
                >
                  <div style="max-width: 400px">
                    <p class="m-0 mt-2 resource-small-text">
                      {{ alert.comment }}
                    </p>
                  </div>
                  <a :href="appURL + alert.image" target="_blank">
                    <img
                      v-if="alert.image"
                      :src="appURL + alert.image"
                      alt=""
                      style="width: 50px; height: 50px"
                    />
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <pagination :total="total" @fetchData="getAlerts" v-if="total > 1" />
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import moment from "moment";
import pagination from "../components/Pagination.vue";

export default {
  name: "alerts",
  components: {
    pagination,
  },
  filters: {
    moment: function (date) {
      let d = moment(date);
      return d.isValid() ? moment(date).format("MMM D, YYYY") : "N/A";
    },
  },
  data: () => {
    return {
      appURL: process.env.VUE_APP_MAIN_URL,
      alerts: [],
      url: "",
      total: 1,
      sortBy: "",
      changeOrder: true,
    };
  },
  methods: {
    sortAlerts: function (sort) {
      if (this.sortBy == sort) {
        this.changeOrder = !this.changeOrder;
      }
      this.sortBy = sort;
      this.getAlerts();
    },
    getAlerts: function (page = 1) {
      let url = `admin/users/alerts?page=${page}&access_token=${localStorage.getItem(
        "access_token"
      )}`;
      if (this.sortBy) {
        url += `&sortBy=${this.sortBy}`;
      }
      if (this.changeOrder) {
        url += `&orderBy=DESC`;
      }
      axios({
        method: "GET",
        url: url,
        headers: {
          "content-type": "application/json",
        },
      }).then(
        (result) => {
          if (result) {
            this.alerts = result.data.alerts.data;
            this.alerts.map((alert) => {
              alert.tags = alert.tags
                ? alert.tags.replace(/[^a-zA-Z, ]/g, "")
                : "-";
            });
            this.total = Math.ceil(
              result.data.alerts.total / result.data.alerts.per_page
            );
            console.log(this.alerts);
          }
        },
        (error) => {
          console.log(error);
        }
      );
    },
  },
  mounted: function () {
    this.getAlerts();
    this.url = axios.defaults.baseURL;
  },
  computed: {},
};
</script>

<style lang="scss" scoped>
.table {
  border-collapse: separate;
  border-spacing: 0 12px !important;
  tbody td {
    padding: 12px;
  }
  thead th {
    padding: 12px;
    text-transform: capitalize;
  }
}

.placeholder-profile-pic {
  background-color: black;
  background-size: cover;
  height: 50px;
  width: 50px;
  display:flex;
  justify-content: center;
  align-items: center;
}
</style>