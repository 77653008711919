<template>
  <div class="entry-content">
    <router-link to="/therapists">
      <h1 class="back m-0 ml-4"><img class="align-baseline" src="/img/icons/arrow-left.svg" height="15"
        alt="left-arrow"> Back</h1>
    </router-link>
    <div class="container-fluid pt-2">
        <div class="container row">
            <div class="col-3"></div>
            <div class="col-5">
            <div class="form-group">
                <label class="resource-text m-0" for="name">Display name</label>
                <input type="text" v-validate="'required|min:3'" data-vv-delay="500" name="name" class="form-control"
                id="name" v-model="therapist.name">
                <span v-show="submittedHit && errors.has('name')"
                class="help is-danger">{{ errors.first('name') }}</span>
            </div>
            <div class="form-group">
                <label class="resource-text m-0" for="email">Email</label>
                <input type="text" v-validate="'required|email'" data-vv-delay="500" name="email" class="form-control"
                id="email" v-model="therapist.email">
                <span v-show="submittedHit && errors.has('email')"
                class="help is-danger">{{ errors.first('email') }}</span>
            </div>
            <div class="py-4">
                <div class="col-12 text-center">
                    <button class="btn btn-primary enroute-btn resource-save-btn" @click="addTherapist">Invite Therapist</button>
                </div>
                <div class="col-12 text-center mt-2">
                    <router-link class="navbar-brand text-left" to="/add-therapist">
                        <small class="resource-text">I have more details about Therapist</small>
                    </router-link>
                </div>
            </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import axios from 'axios';

  export default {
    name: 'therapist',
    components: {
    },
    data: () => {
      return {
        therapist: {
            name: '',
            email: ''
        },
        submittedHit: false,
        successMessage: "Therapist added successfully",
        errorMessage: "This email is already in use.",
      }
    },
    methods: {
      addTherapist: function () {
          this.$validator.validateAll().then((result) => {
            result;
            this.submittedHit = true;
            axios({
                method: "POST",
                url: `admin/therapists/add?access_token=${localStorage.getItem('access_token')}`,
                data: this.therapist,
                headers: {
                  "content-type": "application/json"
                }
            }).then(
                result => {
                if (result) {
                  if(result.data.message == this.errorMessage){
                    this.$noty.error(result.data.message);
                  }
                  else if(result.data.message == this.successMessage){
                    this.$router.push(`/therapists`);
                    this.$noty.success("Invitation sent to therapist's email");  
                    }
                  }
                },
                error => {
                  this.$noty.error(error.response.data.message);  
                  console.log("Error: " + error.response.data.message);
                }
            );
        }).catch(() => {});
      },
    },
    mounted() {
    }
  };
</script>

<style lang="scss" scoped>
</style>
