<template>
  <div class="entry-content">
    <router-link to="/therapists">
      <h1 class="back m-0 ml-4"><img class="align-baseline" src="/img/icons/arrow-left.svg" height="15" alt="left-arrow"> Back</h1>
    </router-link>
    <div class="container-fluid pt-2">
      <div class="container">
        <div class="row mt-4">
          <!-- First Column -->
          <div class="col-6">
            <div class="row mb-4">
              <div class="col-4 text-center">
                <picture-input class="mb-3" ref="pictureInput" @change="inputImage" width="150" height="150"
                  margin="0" accept="image/jpeg,image/png" size="2" :hideChangeButton="true" :removable="false">
                </picture-input>
                <button class="btn btn-primary btn-sm px-2 mx-auto enroute-btn resource-text resource-upload-btn"
                  @click="uploadImage">Upload Photo</button>
              </div>
              <div class="col-8">
                <div class="form-group">
                  <label class="resource-text m-0" for="name">Display name</label>
                  <input type="text" v-validate="'required|min:3'" data-vv-delay="500" name="name" class="form-control"
                    id="name" v-model="therapist.name">
                  <span v-show="submittedHit && errors.has('name')"
                    class="help is-danger">{{ errors.first('name') }}</span>
                </div>
                <div class="form-group">
                  <label class="resource-text m-0" for="email">Email</label>
                  <input type="text" v-validate="'required|email'" data-vv-delay="500" name="email" class="form-control"
                    id="email" v-model="therapist.email">
                  <span v-show="submittedHit && errors.has('email')"
                    class="help is-danger">{{ errors.first('email') }}</span>
                </div>
                <div class="form-group">
                  <label class="resource-text m-0" for="phone">Phone number (Optional)</label>
                  <input type="text" class="form-control" id="phone" v-model="therapist.phone">
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <div class="form-group">
                  <label class="resource-text m-0" for="title">Title: Separate each with a comma</label>
                  <input type="text" v-validate="'min:2'" data-vv-delay="500" name="title" class="form-control"
                    id="title" v-model="therapist.title">
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <div class="form-floating form-group">
                  <label class="resource-text m-0" for="about">About</label>
                  <textarea class="form-control" v-validate="'required|min:3'" data-vv-delay="500" name="about"
                    placeholder="write down about yourself here" id="about" v-model="therapist.about"
                    rows="4"></textarea>
                  <span v-show="submittedHit && errors.has('about')"
                    class="help is-danger">{{ errors.first('about') }}</span>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <div class="form-floating form-group">
                  <label class="resource-text m-0" for="specialities">Specialities</label>
                  <textarea class="form-control" v-validate="'required|min:3'" data-vv-delay="500" name="specialities"
                    placeholder="write down about your specialities here" id="specialities"
                    v-model="therapist.specialities" rows="2"></textarea>
                  <span v-show="submittedHit && errors.has('specialities')"
                    class="help is-danger">{{ errors.first('specialities') }}</span>
                </div>
              </div>
            </div>
          </div>
          <!-- Second Column -->
          <div class="offset-1 col-5">
            <div class="row mt-4">
              <div class="col-12">
                <p class="resource-small-text">In-person Salon</p>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <div class="form-group">
                  <label class="resource-text m-0" for="address">Address</label>
                  <input type="text" v-validate="'required|min:3'" data-vv-delay="500" name="address"
                    class="form-control" id="address" v-model="therapist.address">
                  <span v-show="submittedHit && errors.has('address')"
                    class="help is-danger">{{ errors.first('address') }}</span>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-7">
                <div class="form-group">
                  <label class="resource-text m-0" for="city">City</label>
                  <input type="text" v-validate="'required|min:2'" data-vv-delay="500" name="city" class="form-control"
                    id="city" v-model="therapist.city">
                  <span v-show="submittedHit && errors.has('city')"
                    class="help is-danger">{{ errors.first('city') }}</span>
                </div>
              </div>
              <div class="col-5">
                <div class="form-group">
                  <label class="resource-text m-0" for="postcode">Postcode</label>
                  <input type="text" v-validate="'required|numeric|min:3'" data-vv-delay="500" name="postcode"
                    class="form-control" id="postcode" v-model="therapist.postcode">
                  <span v-show="submittedHit && errors.has('postcode')"
                    class="help is-danger">{{ errors.first('postcode') }}</span>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <div class="form-group">
                  <label class="resource-text m-0" for="country">Country</label>
                  <select class="custom-select" v-validate="'required'" data-vv-delay="500" name="country"
                    v-model="therapist.country">
                    <option v-for="country in countries" :value="country.name" :key="country.code">{{country.name}}
                    </option>
                  </select>
                  <span v-show="submittedHit && errors.has('country')"
                    class="help is-danger">{{ errors.first('country') }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row py-6">
          <div class="col-12 text-right">
            <button class="btn btn-primary enroute-btn resource-save-btn" @click="addTherapist">Save Therapist</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import axios from 'axios';
  import PictureInput from 'vue-picture-input';
  import {
    b64toBlob
  } from '../utils/helper';
  import countries from '../utils/countries';

  export default {
    name: 'therapist',
    components: {
      PictureInput,
    },
    data: () => {
      return {
        therapist: {},
        countries: countries,
        submittedHit: false,
        weekends: false,
        differentEachDay: false,
        start_time: '',
        end_time: '',
        weekendFields: [
          'saturday_start_time',
          'sunday_start_time',
          'saturday_end_time',
          'sunday_end_time',
        ],
        saved: false,
        successMessage: "Therapist added successfully",
        errorMessage: "This email is already in use.",
      }
    },
    methods: {
      uploadImage: function () {
        this.$refs.pictureInput.$refs.fileInput.click();
      },
      inputImage: function () {
        if (this.$refs.pictureInput.image) {
          this.therapist.image = this.$refs.pictureInput.image;
        }
      },
      adjustTimeInput: function () {
        const weekdays_start = [
          'monday_start_time',
          'tuesday_start_time',
          'wednesday_start_time',
          'thursday_start_time',
          'friday_start_time',
        ];
        const weekdays_end = [
          'monday_end_time',
          'tuesday_end_time',
          'wednesday_end_time',
          'thursday_end_time',
          'friday_end_time',
        ];
        const weekend_start = [
          'saturday_start_time',
          'sunday_start_time',
        ];
        const weekend_end = [
          'saturday_end_time',
          'sunday_end_time',
        ];
        if (!this.differentEachDay) {
          weekdays_start.forEach(dayTime => {
            this.therapist[dayTime] = this.start_time;
          });
          weekdays_end.forEach(dayTime => {
            this.therapist[dayTime] = this.end_time;
          });
          if (this.weekends) {
            weekend_start.forEach(dayTime => {
              this.therapist[dayTime] = this.start_time;
            });
            weekend_end.forEach(dayTime => {
              this.therapist[dayTime] = this.end_time;
            });
          }
        }
      },
      addWeekendsData: function (key) {
        if (this.weekendFields.includes(key) && !this.weekends) {
          return false;
        }
        return true;
      },
      addTherapist: function () {
        this.$validator.validateAll().then((result) => {
          result;
          this.submittedHit = true;
            this.adjustTimeInput();
            const formDataToUpload = new FormData();
            if (!this.imageFromDB) {
              const blob = b64toBlob(this.therapist.image);
              formDataToUpload.append("image", blob);
            }
            for (const key in this.therapist) {
              if (key !== 'image' && this.addWeekendsData(key)) {
                formDataToUpload.append(key, this.therapist[key]);
              }
            }
            axios({
              method: "POST",
              url: `admin/therapists/add?access_token=${localStorage.getItem('access_token')}`,
              data: formDataToUpload,
              headers: {
                "content-type": "multipart/form-data"
              }
            }).then(
              result => {
                if (result) {
                  if(result.data.message == this.errorMessage){
                      this.$noty.error(result.data.message);
                    }
                  else if(result.data.message == this.successMessage){
                    alert("Therapist added successfully");
                    this.saved = true;
                    this.$router.push(`/therapists`);
                    this.$noty.success("Invitation sent to therapist's email");  
                  }
                }
              },
              error => {
                console.log(error);
              }
            );
          // } else {
          //   this.$noty.error("Please fill all fields.");
          // }
        }).catch(() => {});
      },
    },
    beforeRouteLeave (to, from, next) {
      if(this.saved){
        next();
      }else{
        const answer = window.confirm('Do you really want to leave? Any unsaved changes will not be saved automatically.')
        if (answer) {
          next();
        } else {
          next(false);
        }
      }
    },
    mounted() {

    }
  };
</script>

<style lang="scss" scoped>
</style>
