<template>
<div class=" entry-content">
  <div class="container-fluid">
    <div class="container">
      <div class="top-row d-flex justify-content-between">
          <h1 class="page-title m-0">
            Reassign Therapist
          </h1>
        </div>
      <div class="table-responsive table-hover">
            <table class="table mt-2">
                <thead>
                    <tr>
                        <th class="resource-small-text cursor-pointer" scope="col">User</th>
                        <th class="resource-small-text cursor-pointer" scope="col">Action </th>
                    </tr>
                </thead>
                <tbody >
                    <tr class="bg-white cursor-pointer" v-for="client in clients" :key="client.id">
                       <td>{{client.user.name}}</td>
                       <td @click="showModal = true"><button type="button" class="btn btn-primary px-2 enroute-btn resource-medium-text" @click="selectedUser(client.id)">Select Therapist</button></td>
                    </tr >
                </tbody>
            </table>
        </div>
        <modal :show="showModal" @close="showModal = false">
            <div slot="header">
                <h1 class="mb-4">Reassign Therapist</h1>
            </div>
            <table>
            <tr v-for="therapist in therapists" :key="therapist.id" class="my-2">
            <td>
            <img :src="therapist.id === assignedTherapist? '/img/icons/quiz-radio-filled-black.svg':'/img/icons/quiz-radio-empty-black.svg'"
              alt="radio" @click="toggleUser(therapist.id)" class="modal-radio">
            </td>
            <td><span class="resource-large-text mx-5 p-0 mt-1">{{therapist.name}}</span></td>
            <td><span class="resource-text p-0 m-0">{{therapist.email}}</span></td>
            </tr>
            </table>
            <button type="button" class="btn btn-primary px-3 py-1 enroute-btn resource-large-text offset-lg-7" @click="assignTherapist">Reassign Therapist</button>
        </modal>
    </div>
  </div>
</div>
</template>


<script>
  import axios from 'axios';

  export default {
    name: "ReassignTherapist",
    components: {

    },
    data: () => {
      return {
        total: 1,
        revenue:0,
        debt:0,
        url: '',
        search: '',
        clients:[],
        selected_therapist: 0,
        therapists: [],
        showModal: false,
        assignedTherapist: 0,
      }
    },
    methods: {
      getUsers: function () {
        let url = `/admin/unassigned-users?access_token=${localStorage.getItem('access_token')}`;
        axios({
          method: "GET",
          url: url,
          headers: {
            "content-type": "application/json"
          }
        }).then(
          result => {
            if (result) {
              this.clients = result.data.users;
            }
          },
          error => {
            console.log(error);
          }
        );
      },
      searchInput: function(){
        if(!this.search){
          this.getTherapists();
        }
      },
      toggleUser: function(id){
        this.assignedTherapist = this.assignedTherapist === id ? 0: id;
      },
      getTherapists: function (page = 1) {
        let url = `/admin/therapists/all?page=${page}&access_token=${localStorage.getItem('access_token')}`;
        if(this.sort){
          if(this.sort.includes('_desc')){
            url += `&sortBy=${this.sort.split('_desc')[0]}&orderBy=DESC`;
          }else{
            url += `&sortBy=${this.sort}`;
          }
        }
        if(this.search){
          url += `&searchBy=${this.search}`;
        }
        axios({
          method: "GET",
          url: url,
          headers: {
            "content-type": "application/json"
          }
        }).then(
          result => {
            if (result) {
              this.therapists = result.data.therapists.data;
              this.total = Math.ceil(result.data.therapists.total/result.data.therapists.per_page);
              console.log(result)
            }
          },
          error => {
            console.log(error);
          }
        );
      },
      selectedUser: function(id){
          this.selectedClient = id;
          this.showModal = true;
      },
      assignTherapist: function(){
        axios({
          method: "POST",
          url: `admin/therapists/assign?user_id=${this.selectedClient}&therapist_id=${this.assignedTherapist}&access_token=${localStorage.getItem('access_token')}`,
          headers: {
            "content-type": "application/json"
          }
        }).then(
          result => {
            if (result) {
              alert(result.data.message);
              this.showModal = false;
            }
            this.getUser();
          },
          error => {
            console.log(error);
          }
        );
      },
    },
    mounted: function () {
        this.getTherapists();
        this.getUsers();
        this.url = axios.defaults.baseURL;
    },
    computed: {
    }
  }
</script>

<style lang="scss" scoped>
.table{
  border-collapse: separate;
  border-spacing: 0 12px !important;
  tbody td{
    padding: 12px;
  }
  thead th{
    padding: 5px;
    text-transform: capitalize;
  }
}
</style>
