<template>
  <div class="entry-content">
    <div class="container-fluid">
      <div class="container pb-3">
        <div class="top-row d-flex justify-content-between">
          <h1 class="page-title m-0">
            Select Replacement
          </h1>
          <a class="btn exportBtn" :href="url + 'export/therapists'">Export All</a>
        </div>
        <div class="row align-items-end m-0">
          <div class="col-9">
            <div class="row align-items-center">
              <div class="form-group m-0 float-left mr-5">
                <div class="input-group search">
                  <div class="input-group-prepend m-0">
                    <span class="input-group-text border-0"><img src="/img/icons/search.svg" alt="search"></span>
                  </div>
                  <input class="form-control resource-field border-0 pl-2" placeholder="Search name ..." type="text" v-model="search"
                    @keydown="searchKeydown" @input="searchInput">
                </div>
              </div>
              <router-link to="/invite-therapist">
                <img class="add-new-icon" src="/img/icons/icon-plus.svg" alt="">
                <span class="add-new pl-1 ">Add New</span>
              </router-link>
            </div>
          </div>
          <div class="col-3 d-flex justify-content-end p-0">
            <div style="max-width: 150px;">
              <label class="resource-small-text m-0" for="sort">Sort by</label>
              <select v-model="sort" class="resource-select custom-select py-0 px-1" id="sort" @change="getTherapists">
                <option value="name" selected>Name (A - Z)</option>
                <option value="name_desc" selected>Name (Z - A)</option>
                <option value="created_at_desc">Date (Latest)</option>
                <option value="created_at">Date (Earliest)</option>
                <option value="country">Country</option>
              </select>
            </div>
          </div>
        </div>
        <!-- Therapists -->
        <div class="row my-4">
          <div class="col-3 card resource bg-white m-3 p-0" v-for="therapist in therapists" :key="therapist.id">
            <div class="bg-black image resource-image-block">
              <img v-if="(therapist.image)" :src="appURL + therapist.image"
                alt="therapist image" style="width: auto; max-height: 109px;">
              <img v-else src="../assets/nav-user.png"
                class="my-3" alt="default image" style="width: auto; max-height: 75px;">
            </div>
            <p class="text-center resource-large-text m-0 p-0 mt-1">{{therapist.name}}</p>
            <p class="text-center resource-text p-0 m-0">{{therapist.email}}</p>
            <p class="text-center resource-small-text opacity-7 mt-1">{{therapist.country}}</p>
            <div class="row justify-content-center mb-3">
                <button class="btn btn-primary btn-sm resource-small-text enroute-btn resource-large-text px-5" @click="phaseOut(therapist.id)">Select</button>
            </div>
          </div>
        </div>
        <pagination :total="total" @fetchData="getTherapists" v-if="total > 1"/>
      </div>
    </div>
  </div>
</template>
<script>
  import axios from 'axios';
  import pagination from '../components/Pagination.vue';

  export default {
    name: 'therapists',
    components: {
      pagination
    },
    data: () => {
      return {
        appURL: process.env.VUE_APP_MAIN_URL,
        therapists: [],
        total: 1,
        search: '',
        sort: 'name',
        url: '',
      }
    },
    methods: {
      searchKeydown: function(e){
        if (e.keyCode === 13) {
          if(this.search.length>=3 || this.search==''){
            this.getTherapists();
          }
          else{
            this.$noty.error("Please enter 3 or more characters");
          }
        }
      },
      searchInput: function(){
        if(!this.search){
          this.getTherapists();
        }
      },
      getTherapists: function (page = 1) {
        let url = `/admin/therapists/all-active?page=${page}&access_token=${localStorage.getItem('access_token')}`;
        if(this.sort){
          if(this.sort.includes('_desc')){
            url += `&sortBy=${this.sort.split('_desc')[0]}&orderBy=DESC`;
          }else{
            url += `&sortBy=${this.sort}`;
          }
        }
        if(this.search){
          url += `&searchBy=${this.search}`;
        }
        axios({
          method: "GET",
          url: url,
          headers: {
            "content-type": "application/json"
          }
        }).then(
          result => {
            if (result) {
              this.therapists = result.data.therapists.data.filter(therapist => therapist.id != this.$route.params.therapist_id);
              this.total = Math.ceil(result.data.therapists.total/result.data.therapists.per_page);
              console.log(result)
            }
          },
          error => {
            console.log(error);
          }
        );
      },
      phaseOut: function (id) {
        const replacement = {
            replacement_therapist: id
        };
        axios({
          method: "POST",
          url: `admin/therapists/phase-out/${this.$route.params.therapist_id}?access_token=${localStorage.getItem('access_token')}`,
          data: replacement,
          headers: {
            "content-type": "application/json"
          }
        }).then(
          result => {
            if(result){
                this.$router.push('/therapists');
                this.getTherapists();
            }
          },
          error => {
            console.log(error);
          }
        );
      },
    },
    mounted() {
      this.getTherapists();
      this.url = axios.defaults.baseURL;
    }
  };
</script>

<style lang="scss" scoped>

.search-bar::placeholder{
  color: #1e1e1e !important;
  font-size: 16px !important;
}

.search-icon{
  color: #1e1e1e !important;
}

.custom-select{
  color:#1e1e1e !important;
}
</style>
