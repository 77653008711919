<template>
  <div class="entry-content">
    <router-link to="/therapists">
      <h1 class="back m-0 ml-4"><img class="align-baseline" src="/img/icons/arrow-left.svg" height="15" alt="left-arrow"> Back</h1>
    </router-link>
    <div class="container-fluid pt-2">
      <div class="container">
        <div class="row mt-4">
          <!-- First Column -->
          <div class="col-6">
            <div class="row mb-4">
              <div class="col-4 text-center">
                <img v-if="therapist.image && imageFromDB" ref="dbImage" class="mb-4 img-fluid"
                  :src="appURL + therapist.image" alt="Therapist image" :style="{height: imageHeight + 'px'}" @load="setHeight">
                <picture-input v-else class="mb-3" ref="pictureInput" @change="inputImage" width="150" height="150"
                  margin="0" accept="image/jpeg,image/png" size="2"
                  :hideChangeButton="true" :removable="false">
                </picture-input>
                <button class="btn btn-primary btn-sm px-2 mx-auto enroute-btn resource-text resource-upload-btn"
                  @click="uploadImage">Upload Photo</button>
              </div>
              <div class="col-8">
                <div class="form-group">
                  <label class="resource-text m-0" for="name">Display name</label>
                  <input type="text" v-validate="'required|min:3'" data-vv-delay="500" name="name" class="form-control"
                    id="name" v-model="therapist.name">
                  <span v-show="submittedHit && errors.has('name')"
                    class="help is-danger">{{ errors.first('name') }}</span>
                </div>
                <div class="form-group">
                  <label class="resource-text m-0" for="email">Email</label>
                  <input type="text" v-validate="'required|email'" data-vv-delay="500" name="email" class="form-control"
                    id="email" v-model="therapist.email">
                  <span v-show="submittedHit && errors.has('email')"
                    class="help is-danger">{{ errors.first('email') }}</span>
                </div>
                <div class="form-group">
                  <label class="resource-text m-0" for="phone">Phone number (Optional)</label>
                  <input type="text" class="form-control" id="phone" v-model="therapist.phone">
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <div class="form-group">
                  <label class="resource-text m-0" for="title">Title: Separate each with a comma</label>
                  <input type="text" v-validate="'min:2'" data-vv-delay="500" name="title" class="form-control"
                    id="title" v-model="therapist.title">
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <div class="form-floating form-group">
                  <label class="resource-text m-0" for="about">About</label>
                  <textarea class="form-control" v-validate="'required|min:3'" data-vv-delay="500" name="about"
                    placeholder="write down about yourself here" id="about" v-model="therapist.about"
                    rows="4"></textarea>
                  <span v-show="submittedHit && errors.has('about')"
                    class="help is-danger">{{ errors.first('about') }}</span>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <div class="form-floating form-group">
                  <label class="resource-text m-0" for="specialities">Specialities</label>
                  <textarea class="form-control" v-validate="'required|min:3'" data-vv-delay="500" name="specialities"
                    placeholder="write down about your specialities here" id="specialities"
                    v-model="therapist.specialities" rows="2"></textarea>
                  <span v-show="submittedHit && errors.has('specialities')"
                    class="help is-danger">{{ errors.first('specialities') }}</span>
                </div>
              </div>
            </div>
          </div>
          <!-- Second Column -->
          <div class="offset-1 col-5">
            <div class="row">
              <div class="col-12">
                <p class="resource-small-text">Availability</p>
              </div>
            </div>
            <div class="row mb-2">
              <div class="col-5">
                <div class="custom-control custom-checkbox">
                  <input class="custom-control-input" name="different-each-day" id="differentEachDay" type="checkbox"
                    v-model="differentEachDay">
                  <label class="resource-text m-0 custom-control-label d-inline" for="differentEachDay">Different each
                    day</label>
                </div>
              </div>
              <div class="col-7">
                <div class="custom-control custom-checkbox">
                  <input class="custom-control-input" id="weekends" type="checkbox" v-model="weekends">
                  <label class="resource-text m-0 custom-control-label d-inline" for="weekends">Include Weekends</label>
                </div>
              </div>
            </div>
            <div class="row align-items-center">
              <div class="offset-3 col-4">
                <p class="resource-small-text m-0 ml-1">From</p>
              </div>
              <div class="col-4">
                <p class="resource-small-text m-0 ml-4">Until</p>
              </div>
            </div>
            <!-- For all days -->
            <div class="row align-items-baseline" v-show="!differentEachDay">
              <div class="col-3">
                <label class="resource-text m-0" for="start_time">Time</label>
              </div>
              <div class="col-4">
                <div class="form-group">
                  <input type="time" v-validate="{ required: !(this.differentEachDay) }" data-vv-delay="500"
                    name="start-time" class="form-control" id="start_time"
                    v-model="start_time" @input="adjustTimeInput">
                  <span v-show="submittedHit && errors.has('start-time')" class="help is-danger">The start time
                    is required</span>
                </div>
              </div>
              <span style="color:#1e1e1e;">
                <i class="fa fa-minus" aria-hidden="true"></i>
              </span>
              <div class="col-4">
                <div class="form-group">
                  <input type="time" v-validate="{ required: !(this.differentEachDay) }" data-vv-delay="500"
                    name="end-time" class="form-control" id="end_time"
                    v-model="end_time" @input="adjustTimeInput">
                  <span v-show="submittedHit && errors.has('end-time')" class="help is-danger">The start time
                    is required</span>
                </div>
              </div>
            </div>
            <!-- monday -->
            <div class="row align-items-baseline">
              <div class="col-3">
                <label class="resource-text m-0" for="monday_start_time">Monday</label>
              </div>
              <div class="col-4">
                <div class="form-group">
                  <input type="time" v-validate="{ required: this.differentEachDay }" data-vv-delay="500"
                    name="monday-start-time" class="form-control" id="monday_start_time"
                    v-model="therapist.monday_start_time">
                  <span v-show="submittedHit && errors.has('monday-start-time')" class="help is-danger">The start time
                    is required</span>
                </div>
              </div>
              <span style="color:#1e1e1e;">
                <i class="fa fa-minus" aria-hidden="true"></i>
              </span>
              <div class="col-4">
                <div class="form-group">
                  <input type="time" v-validate="{ required: this.differentEachDay }" data-vv-delay="500"
                    name="monday-end-time" class="form-control" id="monday_end_time"
                    v-model="therapist.monday_end_time">
                  <span v-show="submittedHit && errors.has('monday-end-time')" class="help is-danger">The end time is
                    required</span>
                </div>
              </div>
            </div>
            <!-- tuesday -->
            <div class="row align-items-baseline">
              <div class="col-3">
                <label class="resource-text m-0" for="tuesday_start_time">Tuesday</label>
              </div>
              <div class="col-4">
                <div class="form-group">
                  <input type="time" v-validate="{ required: this.differentEachDay }" data-vv-delay="500"
                    name="tuesday-start-time" class="form-control" id="tuesday_start_time"
                    v-model="therapist.tuesday_start_time">
                  <span v-show="submittedHit && errors.has('tuesday-start-time')" class="help is-danger">The start time
                    is required</span>
                </div>
              </div>
              <span style="color:#1e1e1e;">
                <i class="fa fa-minus" aria-hidden="true"></i>
              </span>
              <div class="col-4">
                <div class="form-group">
                  <input type="time" v-validate="{ required: this.differentEachDay }" data-vv-delay="500"
                    name="tueday-end-time" class="form-control" id="tuesday_end_time"
                    v-model="therapist.tuesday_end_time">
                  <span v-show="submittedHit && errors.has('tueday-end-time')" class="help is-danger">The end time is
                    required</span>
                </div>
              </div>
            </div>
            <!-- wednesday -->
            <div class="row align-items-baseline">
              <div class="col-3">
                <label class="resource-text m-0" for="wednesday_start_time">Wednesday</label>
              </div>
              <div class="col-4">
                <div class="form-group">
                  <input type="time" v-validate="{ required: this.differentEachDay }" data-vv-delay="500"
                    name="wednesday-start-time" class="form-control" id="wednesday_start_time"
                    v-model="therapist.wednesday_start_time">
                  <span v-show="submittedHit && errors.has('wednesday-start-time')" class="help is-danger">The start
                    time is required</span>
                </div>
              </div>
              <span style="color:#1e1e1e;">
                <i class="fa fa-minus" aria-hidden="true"></i>
              </span>
              <div class="col-4">
                <div class="form-group">
                  <input type="time" v-validate="{ required: this.differentEachDay }" data-vv-delay="500"
                    name="wednesday-end-time" class="form-control" id="wednesday_end_time"
                    v-model="therapist.wednesday_end_time">
                  <span v-show="submittedHit && errors.has('wednesday-end-time')" class="help is-danger">The end time is
                    required</span>
                </div>
              </div>
            </div>
            <!-- thursday -->
            <div class="row align-items-baseline">
              <div class="col-3">
                <label class="resource-text m-0" for="thursday_start_time">Thursday</label>
              </div>
              <div class="col-4">
                <div class="form-group">
                  <input type="time" v-validate="{ required: this.differentEachDay }" data-vv-delay="500"
                    name="thursday-start-time" class="form-control" id="thursday_start_time"
                    v-model="therapist.thursday_start_time">
                  <span v-show="submittedHit && errors.has('thursday-start-time')" class="help is-danger">The start time
                    is required</span>
                </div>
              </div>
              <span style="color:#1e1e1e;">
                <i class="fa fa-minus" aria-hidden="true"></i>
              </span>
              <div class="col-4">
                <div class="form-group">
                  <input type="time" v-validate="{ required: this.differentEachDay }" data-vv-delay="500"
                    name="thursday-end-time" class="form-control" id="thursday_end_time"
                    v-model="therapist.thursday_end_time">
                  <span v-show="submittedHit && errors.has('thursday-end-time')" class="help is-danger">The end time is
                    required</span>
                </div>
              </div>
            </div>
            <!-- friday -->
            <div class="row align-items-baseline">
              <div class="col-3">
                <label class="resource-text m-0" for="friday_start_time">Friday</label>
              </div>
              <div class="col-4">
                <div class="form-group">
                  <input type="time" v-validate="{ required: this.differentEachDay }" data-vv-delay="500"
                    name="friday-start-time" class="form-control" id="friday_start_time"
                    v-model="therapist.friday_start_time">
                  <span v-show="submittedHit && errors.has('friday-start-time')" class="help is-danger">The start time
                    is required</span>
                </div>
              </div>
              <span style="color:#1e1e1e;">
                <i class="fa fa-minus" aria-hidden="true"></i>
              </span>
              <div class="col-4">
                <div class="form-group">
                  <input type="time" v-validate="{ required: this.differentEachDay }" data-vv-delay="500"
                    name="friday-end-time" class="form-control" id="friday_end_time"
                    v-model="therapist.friday_end_time">
                  <span v-show="submittedHit && errors.has('friday-end-time')" class="help is-danger">The end time is
                    required</span>
                </div>
              </div>
            </div>
            <!-- saturday -->
            <div class="row align-items-baseline" v-show="weekends">
              <div class="col-3">
                <label class="resource-text m-0" for="saturday_start_time">Saturday</label>
              </div>
              <div class="col-4">
                <div class="form-group">
                  <input type="time" name="saturday-start-time"
                    v-validate="{ required: this.therapist.saturday_end_time && this.weekends }" class="form-control"
                    id="saturday_start_time" v-model="therapist.saturday_start_time">
                  <span v-show="submittedHit && errors.has('saturday-start-time')" class="help is-danger">The start time
                    is required</span>
                </div>
              </div>
              <span style="color:#1e1e1e;">
                <i class="fa fa-minus" aria-hidden="true"></i>
              </span>
              <div class="col-4">
                <div class="form-group">
                  <input type="time" class="form-control" name="saturday-end-time"
                    v-validate="{ required: this.therapist.saturday_start_time && this.weekends }" id="saturday_end_time"
                    v-model="therapist.saturday_end_time">
                  <span v-show="submittedHit && errors.has('saturday-end-time')"
                    class="help is-danger">The end time is required</span>
                </div>
              </div>
            </div>
            <!-- sunday -->
            <div class="row align-items-baseline" v-show="weekends">
              <div class="col-3">
                <label class="resource-text m-0" for="sunday_start_time">Sunday</label>
              </div>
              <div class="col-4">
                <div class="form-group">
                  <input type="time" class="form-control" name="sunday-start-time"
                    v-validate="{ required: this.therapist.sunday_end_time && this.weekends }" id="sunday_start_time"
                    v-model="therapist.sunday_start_time">
                  <span v-show="submittedHit && errors.has('sunday-start-time')"
                    class="help is-danger">The start time is required</span>
                </div>
              </div>
              <span style="color:#1e1e1e;">
                <i class="fa fa-minus" aria-hidden="true"></i>
              </span>
              <div class="col-4">
                <div class="form-group">
                  <input type="time" class="form-control" name="sunday-end-time"
                    v-validate="{ required: this.therapist.sunday_start_time && this.weekends }" id="sunday_end_time"
                    v-model="therapist.sunday_end_time">
                  <span v-show="submittedHit && errors.has('sunday-end-time')"
                    class="help is-danger">The end time is required</span>
                </div>
              </div>
            </div>
            <div class="row mt-4">
              <div class="col-12">
                <p class="resource-small-text">In-person Salon</p>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <div class="form-group">
                  <label class="resource-text m-0" for="address">Address</label>
                  <input type="text" v-validate="'required|min:3'" data-vv-delay="500" name="address"
                    class="form-control" id="address" v-model="therapist.address">
                  <span v-show="submittedHit && errors.has('address')"
                    class="help is-danger">{{ errors.first('address') }}</span>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-7">
                <div class="form-group">
                  <label class="resource-text m-0" for="city">City</label>
                  <input type="text" v-validate="'required|min:2'" data-vv-delay="500" name="city" class="form-control"
                    id="city" v-model="therapist.city">
                  <span v-show="submittedHit && errors.has('city')"
                    class="help is-danger">{{ errors.first('city') }}</span>
                </div>
              </div>
              <div class="col-5">
                <div class="form-group">
                  <label class="resource-text m-0" for="postcode">Postcode</label>
                  <input type="text" v-validate="'required|numeric|min:3'" name="postcode"
                    class="form-control" id="postcode" v-model="therapist.postcode">
                  <span v-show="submittedHit && errors.has('postcode')"
                    class="help is-danger">{{ errors.first('postcode') }}</span>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <div class="form-group">
                  <label class="resource-text m-0" for="country">Country</label>
                  <select class="custom-select" v-validate="'required'" data-vv-delay="500" name="country"
                    v-model="therapist.country">
                    <option v-for="country in countries" :value="country.name" :key="country.code">{{country.name}}
                    </option>
                  </select>
                  <span v-show="submittedHit && errors.has('country')"
                    class="help is-danger">{{ errors.first('country') }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row py-6">
          <div class="col-6">
            <router-link :to="'/removeTherapist/'+this.$route.params.therapist_id">
              <span class="resource-text phase resource-remove"
              title="Remove the therapist">
                Remove Permanently
              </span>
            </router-link>
          </div>
          <div class="col-6 text-right">
            <button class="btn btn-primary enroute-btn resource-save-btn ml-6" @click="updateTherapist">Save
              Changes</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import axios from 'axios';
  import PictureInput from 'vue-picture-input';
  import {
    b64toBlob
  } from '../utils/helper';
  import countries from '../utils/countries';

  export default {
    name: 'therapist',
    components: {
      PictureInput,
    },
    data: () => {
      return {
        appURL: process.env.VUE_APP_MAIN_URL,
        therapist: {},
        countries: countries,
        imageFromDB: true,
        imageHeight: 150,
        submittedHit: false,
        weekends: false,
        differentEachDay: false,
        start_time: '',
        end_time: '',
        weekendFields: [
          'saturday_start_time',
          'sunday_start_time',
          'saturday_end_time',
          'sunday_end_time',
        ],
        remove: false,
      }
    },
    methods: {
      uploadImage: function () {
        this.$refs.pictureInput.$refs.fileInput.click();
      },
      inputImage: function () {
        if (this.$refs.pictureInput.image) {
          this.therapist.image = this.$refs.pictureInput.image;
          this.imageFromDB = false;
        }
      },
      setHeight: function() {
        this.imageHeight = this.$refs.dbImage.width;
      },
      getTherapist: function () {
        axios({
          method: "GET",
          url: `/admin/therapists/${this.$route.params.therapist_id}?access_token=${localStorage.getItem('access_token')}`,
          headers: {
            "content-type": "application/json"
          }
        }).then(
          result => {
            if (result) {
              this.therapist = result.data.therapist;
              if(this.therapist.saturday_start_time){
                this.weekends = true
              }
            }
          },
          error => {
            console.log(error);
          }
        );
      },
      adjustTimeInput: function () {
        const weekdays_start = [
          'monday_start_time',
          'tuesday_start_time',
          'wednesday_start_time',
          'thursday_start_time',
          'friday_start_time',
        ];
        const weekdays_end = [
          'monday_end_time',
          'tuesday_end_time',
          'wednesday_end_time',
          'thursday_end_time',
          'friday_end_time',
        ];
        const weekend_start = [
          'saturday_start_time',
          'sunday_start_time',
        ];
        const weekend_end = [
          'saturday_end_time',
          'sunday_end_time',
        ];
        if (!this.differentEachDay) {
          weekdays_start.forEach(dayTime => {
            this.therapist[dayTime] = this.start_time;
          });
          weekdays_end.forEach(dayTime => {
            this.therapist[dayTime] = this.end_time;
          });
          if (this.weekends) {
            weekend_start.forEach(dayTime => {
              this.therapist[dayTime] = this.start_time;
            });
            weekend_end.forEach(dayTime => {
              this.therapist[dayTime] = this.end_time;
            });
          }
        }
      },
      addWeekendsData: function (key) {
        if (this.weekendFields.includes(key) && !this.weekends) {
          return false;
        }
        return true;
      },
      updateTherapist: function () {
        this.$validator.validateAll().then((result) => {
          this.submittedHit = true;
          result;
        this.adjustTimeInput();
        const formDataToUpload = new FormData();
        if (!this.imageFromDB) {
          const blob = b64toBlob(this.therapist.image);
          formDataToUpload.append("image", blob);
        }
        for (const key in this.therapist) {
          if (key !== 'image' && this.addWeekendsData(key)) {
            formDataToUpload.append(key, this.therapist[key]);
          }
        }
        axios({
          method: "POST",
          url: `admin/therapists/update/${this.$route.params.therapist_id}?access_token=${localStorage.getItem('access_token')}`,
          data: formDataToUpload,
          headers: {
            "content-type": "multipart/form-data"
          }
        }).then(
          result => {
            if (result) {
              this.therapist = result.data.data;
              alert("Changes saved!")
            }
          },
          error => {
            console.log(error);
          }
        );
        // } else {
        //     this.$noty.error("Please fill all fields.");
        //   }
        }).catch(() => {});
      },
      deleteTherapist: function () {
        const answer = window.confirm('Are you sure you want to remove this Therapist?')
        if (answer) {
          axios({
            method: "DELETE",
            url: `admin/therapists/delete/${this.$route.params.therapist_id}?access_token=${localStorage.getItem('access_token')}`,
            headers: {
              "content-type": "application/json"
            }
          }).then(
            result => {
              if (result) {
                this.remove = true;
                this.$router.push('/therapists');
              }
            },
            error => {
              console.log(error);
            }
          );
        }
      },
      togglePhase: function () {
        axios({
          method: "POST",
          url: `admin/therapists/toggle-phase/${this.therapist.id}?access_token=${localStorage.getItem('access_token')}`,
          headers: {
            "content-type": "application/json"
          }
        }).then(
          result => {
            if (result) {
              this.remove = true;
              this.$router.push(`/therapists`);
            }
          },
          error => {
            console.log(error);
          }
        );
      },
    },
    beforeRouteLeave(to, from, next) {
      if(this.remove){
        next();
      }else{
        const answer = window.confirm('Do you really want to leave? Any unsaved changes will not be saved automatically.')
        if (answer) {
          next()
        } else {
          next(false)
        }
      }
    },
    mounted() {
      this.getTherapist();
    }
  };
</script>

<style lang="scss" scoped>
</style>
