<template>
  <div class="entry-content">
    <div class="container-fluid">
      <div class="container pb-3">
        <div class="top-row d-flex justify-content-between align-items-center">
          <h1 class="page-title m-0">
            Sales Reports
          </h1>
          <div class="d-flex flex-row align-items-center">
            <label class="resource-small-text m-0 mr-1" for="from">From: </label>
            <input type="date" v-model="from" class="form-control resource-field py-0 px-1 mr-1" id="from" @change="getSalesReports">
            <label class="resource-small-text m-0 mr-1" for="to">To: </label>
            <input type="date" v-model="to" class="form-control resource-field py-0 px-1 mr-1" id="to" @change="getSalesReports">
          </div>
          <a class="btn exportBtn" :href="appURL + 'api/' + url + '&export=1'">Export</a>
        </div>
        <div class="row align-items-end m-0 mt-2">
          <div class="offset-9 col-3 d-flex justify-content-end p-0">
            <div style="max-width: 150px;">
              <label class="resource-small-text m-0" for="sort">Sort by</label>
              <select v-model="sort" class="resource-select custom-select py-0 px-1" id="sort" @change="getSalesReports">
                <option value="order_items.id" selected>Default</option>
                <option value="order_id">SO Id</option>
              </select>
            </div>
          </div>
        </div>
        <!-- users -->
        <div class="row my-3">
          <div class="table-responsive table-hover">
            <table class="table mt-4">
              <thead>
              <tr>
                <th class="resource-small-text" scope="col">Action</th>
                <th class="resource-small-text" scope="col">Item Id</th>
                <th class="resource-small-text" scope="col">SO #</th>
                <th class="resource-small-text" scope="col">Date</th>
                <th class="resource-small-text" scope="col">Customer Id</th>
                <th class="resource-small-text" scope="col">Total Excl. VAT</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="report in reports" :key="report.id">
                <td>
                  <span v-if="report.status != 'ordered'">
                    <span v-if="report.status == 'returned'">
                      Returned
                    </span>
                    <span v-if="report.status == 'deleted'">
                      Removed
                    </span>          
                  </span>
                  <span v-else>
                    <span>
                      Ordered
                    </span>
                  </span>
                </td>
                <td>{{ report.id }}</td>
                <td>{{ report.order_id }}</td>
                <td>{{ report.created_at | moment }}</td>
                <td>{{ report.user_id }}</td>
                <td>
                  <span v-if="report.status == 'returned'">
                    -{{ report.price }}
                  </span>
                  <span v-else-if="report.status == 'deleted'">
                    -{{ report.price }}
                  </span>
                  <span v-else>
                    {{ report.price }}
                  </span>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div style="padding: 22px;">
          <pagination :total="total" @fetchData="getSalesReports" v-if="total > 1" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import axios from 'axios';
  import pagination from '../components/Pagination.vue';
  import moment from "moment";

  export default {
    name: 'SalesOrders',
    components: {
      pagination
    },
    filters: {
      moment: function (date) {
        let d = moment(date);
        return d.isValid() ? moment(date).format("MMM D, YYYY") : "N/A";
      },
    },
    data: () => {
      return {
        appURL: process.env.VUE_APP_MAIN_URL,
        reports: [],
        url: '',
        total: 1,
        search: '',
        sort: 'order_items.id',
        from: '',
        to: '',
      }
    },
    methods: {
      searchKeydown: function (e) {
        if (e.keyCode === 13) {
          if (this.search.length >= 3 || this.search == '') {
            this.getSalesReports();
          } else {
            this.$noty.error("Please enter 3 or more characters");
          }
        }
      },
      searchInput: function () {
        if (!this.search) {
          this.getSalesReports();
        }
      },
      getSalesReports: function (page = 1) {
        this.url = "admin/order-items/all?page=" + page;
        if (this.sort) {
          if (this.sort.includes('_desc')) {
            this.url += "&sortBy=" + this.sort.split('_desc')[0]} + "&orderBy=DESC";
          } else {
            this.url += "&sortBy=" + this.sort;
          }
        // if (this.search) {
        //   url += `&searchBy=${this.search}`;
        // }
        if (this.from) {
          this.url += "&from=" + this.from;
        }
        if (this.to) {
          this.url += "&to=" + this.to;
        }
        axios({
          method: "GET",
          url: this.url,
          headers: {
            "content-type": "application/json"
          }
        }).then(
          result => {
            if (result) {
              this.reports = result.data.order_items.data;
              console.log("hier: " + this.reports);
              this.total = Math.ceil(result.data.reports.total / result.data.reports.per_page);
            }
          },
          error => {
            console.log(error);
          }
        );
      },
      goToSalesOrder: function (id) {
        this.$router.push(`sales-order/${id}`);
      },
    },
    computed: {
    },
    mounted() {
      this.getSalesReports();
    }
  };
</script>

<style lang="scss" scoped>
  @media (max-width: 767px) {
    .table-responsive .dropdown-menu {
      position: static !important;
    }
  }
  @media (min-width: 768px) {
    .table-responsive {
      overflow: visible;
    }
  }
  li > li{
    padding-top:0;
    padding-bottom: 0;
  }
</style>
