<template>
    <nav class="navbar navbar-vertical fixed-left navbar-expand-md navbar-light bg-white overflow-hidden" id="sidenav-main">
        <div class="container-fluid">

            <!--Toggler-->
            <navbar-toggle-button @click.native="showSidebar">
                <span class="navbar-toggler-icon"></span>
            </navbar-toggle-button>
            <router-link class="navbar-brand text-left" to="/">
                <!-- <img src="../../assets/sidebar-logo.png" alt="" width="150px"> -->
                PLASTIC FREE 
                <br>EUROPE
            </router-link>

            <slot name="mobile-right">
                <ul class="nav align-items-center d-md-none">
                </ul>
            </slot>
            <slot></slot>
            <div v-show="$sidebar.showSidebar" class="navbar-collapse collapse show" id="sidenav-collapse-main">

                <div class="navbar-collapse-header d-md-none">
                    <div class="row">
                        <div class="col-6 collapse-brand">
                            <router-link to="/">
                                <img src="../../assets/sidebar-logo.png" alt="" width="auto" class="p-2">
                            </router-link>
                        </div>
                        <div class="col-6 collapse-close">
                            <navbar-toggle-button @click.native="closeSidebar"></navbar-toggle-button>
                        </div>
                    </div>
                </div>

                <ul class="navbar-nav">
                    <slot name="links">
                    </slot>
                </ul>
            </div>
            </div>
    </nav>
</template>
<script>
  import NavbarToggleButton from '@/components/NavbarToggleButton'

  export default {
    name: 'sidebar',
    components: {
      NavbarToggleButton
    },
    props: {
      autoClose: {
        type: Boolean,
        default: true,
        description: 'Whether sidebar should autoclose on mobile when clicking an item'
      }
    },
    provide() {
      return {
        autoClose: this.autoClose
      };
    },
    methods: {
      closeSidebar() {
        this.$sidebar.displaySidebar(false)
      },
      showSidebar() {
        this.$sidebar.displaySidebar(true)
      }
    },
    beforeDestroy() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.showSidebar = false;
      }
    }
  };
</script>

<style>
  #sidenav-main{
      overflow-y: scroll !important;
  }
  #sidenav-main::-webkit-scrollbar {
    width: 0px;
  }

  .navbar-brand{
    color: #fff !important;
    font-weight: 700;
    font-size: 24px;
  }
</style>
