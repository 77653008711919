<template>
  <div class="entry-content">
    <div v-if="!addModal" class="container-fluid">
      <div class="top-row d-flex justify-content-between">
        <h1 class="page-title m-0">
          Materials
        </h1>
        <button type="button" class="btn" @click="showAddModal">
          <img class="add-new-icon" src="/img/icons/icon-plus.svg" alt="">
          <span class="add-new pl-1" style="color: #1e1e1e !important;">Add New</span>
        </button>
      </div>
      <loading :active.sync="isLoading" :can-cancel="false" :is-full-page="fullPage"></loading>
      <vue-good-table :columns="columns" :rows="materials"
                      :search-options="{enabled: true, placeholder: 'Search by name'}" :pagination-options="pageOptions">
        <div slot="emptystate" class="text-center">
          No Data...
        </div>
        <template slot="table-row" slot-scope="props">
          <span v-if="props.column.field === 'action'">
            <button class="btn btn-danger btn-sm" @click="deleteMaterial(props.row.id)">Delete</button>
          </span>
          <span v-else>
            {{props.formattedRow[props.column.field]}}
          </span>
        </template>

      </vue-good-table>
    </div>
    <!-- Add promotional code modal -->
    <slide-y-up-transition origin="center top" mode="out-in">
      <div v-if="addModal" class="container-fluid">
        <h1 class="back m-0 cursor-pointer" @click="addModal=false"><img class="align-baseline"
                                                                         src="/img/icons/arrow-left.svg" height="15" alt="left-arrow">
          Back
        </h1>
        <div class="card mt-4 shadow cusotom-card bg-transparent">
          <div class="card-body">
            <div class="form-group">
              <label>Name:</label>
              <input class="form-control" placeholder="Material" v-model="newProductType.name" type="text" name="name"
                     v-validate="'required:min:3'" />
              <span v-show="errors.has('name')" class="help is-danger">{{ errors.first('name') }}</span>
            </div>
            <div class="form-group mt-3 text-right">
              <button class="btn brand-btn enroute-btn" @click="addMaterial">Add</button>
            </div>
          </div>
        </div>
      </div>
    </slide-y-up-transition>
  </div>
</template>
<script>
import axios from 'axios';
import 'vue-good-table/dist/vue-good-table.css'
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import {
  SlideYUpTransition
} from 'vue2-transitions';
import {
  VueGoodTable
} from 'vue-good-table';
export default {
  components: {
    VueGoodTable,
    Loading,
    SlideYUpTransition
  },
  data() {
    return {
      isLoading: false,
      addModal: false,
      fullPage: true,
      columns: [{
        label: 'Name',
        field: 'name',
      },{
        label: 'Action',
        field: 'action',
      },
      ],
      pageOptions: {
        enabled: true,
        mode: 'records',
        perPage: 10,
        position: 'bottom',
        perPageDropdown: [10, 20, 50],
        dropdownAllowAll: true,
        setCurrentPage: 1,
        nextLabel: 'next',
        prevLabel: 'prev',
        rowsPerPageLabel: 'Per Page',
        ofLabel: 'of',
        pageLabel: 'page', // for 'pages' mode
        allLabel: 'All',
      },
      materials: [],
      newProductType: {
        category: 1
      },
      config: {
        headers: {
          Accept: 'application/json'
        }
      },
    };
  },
  methods: {
    getAllMaterials: function () {
      this.isLoading = true;
      axios.get("admin/materials/all", this.config) //access_token=" + localStorage.getItem("access_token"),
          .then(result => {
            this.isLoading = false;
            this.materials = result.data.materials;
          }, error => {
            this.isLoading = false;
            console.log(error.response);
          });
    },
    deleteMaterial: function (id) {
      this.$swal({
        title: 'Are you sure?',
        text: "This will delete this material.",
        icon: '',
        buttons: {
          cancel: true,
          confirm: true,
        },
      }).then((result) => {
        if (result) {
          this.isLoading = true;
          axios.post(`admin/materials/delete/${id}`, this.config) //access_token=" + localStorage.getItem("access_token"),
              .then(result => {
                this.isLoading = false;
                this.$noty.success("Material deleted");
                this.getAllMaterials()
                return result;
              }, error => {
                this.isLoading = false;
                console.log(error.response);
              });
        }
      })
    },
    showAddModal: function () {
      this.addModal = true;
    },
    addMaterial: function () {
      this.$validator.validate().then((result) => {
        if (result) {
          if(this.materials.map(type => type.name.toLowerCase()).includes(this.newProductType.name.toLowerCase())){
            this.$noty.error("Material already exist.");
            return;
          }
          this.isLoading = true;
          axios.post('admin/materials/add' /*+ "?access_token=" + localStorage.getItem("access_token")*/,
              this.newProductType, this.config)
              .then(result => {
                this.isLoading = false;
                this.addModal = false;

                this.getAllMaterials();
                return result;
              }, error => {
                this.isLoading = false;
                this.$noty.error("Something went wrong");
                return error;
              });
        }
      }).catch(() => {});
    }
  },
  mounted() {
    this.getAllMaterials();
  }
};
</script>
<style scoped>
.cusotom-card:hover {
  transform: none !important;
}

.noty_theme__sunset.noty_type__success{
  background-color: #f2d3bc !important;
  color: #1e1e1e !important;
}

.form-control:not(textarea), .custom-select {
  height: 42px !important;
}
</style>
