/*!

=========================================================
* Vue Argon Dashboard - v1.1.1
=========================================================

* Product Page: https://www.creative-tim.com/product/vue-argon-dashboard
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/vue-argon-dashboard/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import './registerServiceWorker'
import ArgonDashboard from './plugins/argon-dashboard'
import axios from "axios";
import VeeValidate from "vee-validate";
import VueSwal from "vue-swal";
import VueNoty from "vuejs-noty";
import VueGoodTablePlugin from 'vue-good-table';
// import "./db-init";
// import store from "./store";

// import the styles
import 'vue-good-table/dist/vue-good-table.css'

Vue.config.productionTip = false

let apiURL = process.env.VUE_APP_API_URL ? process.env.VUE_APP_API_URL : "https://be.plasticfreeeurope.org/api/";

axios.defaults.baseURL = apiURL;

// Set The Auth Token For Users Site Wide
let token = localStorage.getItem('access_token');
axios.defaults.headers.common = {'Authorization': `Bearer ${token}`};


Vue.use(ArgonDashboard)
Vue.use(VeeValidate, {
  events: 'change'
});
Vue.use(VueGoodTablePlugin);
Vue.use(VueSwal);
import 'vuejs-noty/dist/vuejs-noty.css';
Vue.use(VueNoty, {
  theme: "sunset",
  layout: "topRight",
  progressBar: true,
});
new Vue({
  router,
  // store: store,
  render: h => h(App)
}).$mount('#app')
