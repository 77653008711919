<template>
  <div class="entry-content">
    <router-link to="/therapists">
      <h1 class="back m-0 ml-4"><img class="align-baseline" src="/img/icons/arrow-left.svg" height="15"
                    alt="left-arrow"> Back</h1>
    </router-link>
    <div class="container-fluid pt-2">
      <div class="container">
        <div class="row">
            <div class="col-12">
                <router-link class="float-right" :to="'/therapist/edit/' + this.$route.params.therapist_id">
                    <p class="m-0 ml-4 resource-text edit">Edit</p>
                </router-link>
            </div>
        </div>
        <div class="row mt-4">
          <!-- First Column -->
          <div class="col-6">
            <div class="row mb-4">
              <div class="col-4 text-center">
                <img v-if="therapist.image" class="mb-4 img-fluid" :src="appURL + therapist.image"
                    alt="Therapist image" :style="{height: imageHeight + 'px'}" @load="setHeight">
                <img v-else class="mb-4 img-fluid" src="../assets/nav-user.png"
                    alt="Therapist image" :style="{height: imageHeight + 'px'}" @load="setHeight">
              </div>
              <div class="col-8">
                <div class="form-group">
                  <label class="resource-text font-weight-bold pb-1 m-0" for="name">Display name</label>
                  <p class="resource-small-text">{{therapist.name}}</p>
                </div>
                <div class="form-group">
                  <label class="resource-text font-weight-bold pb-1 m-0" for="email">Email</label>
                  <p class="resource-small-text">{{therapist.email}}</p>
                </div>
                <div class="form-group">
                  <label class="resource-text font-weight-bold pb-1 m-0" for="phone">Phone number (Optional)</label>
                  <p class="resource-small-text">{{therapist.phone}}</p>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <div class="form-group">
                  <label class="resource-text font-weight-bold pb-1 m-0" for="title">Title: Separate each with a comma</label>
                  <p class="resource-small-text">{{therapist.title}}</p>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <div class="form-floating form-group">
                  <label class="resource-text font-weight-bold pb-1 m-0" for="about">About</label>
                  <p class="resource-small-text">{{therapist.about}}</p>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <div class="form-floating form-group">
                  <label class="resource-text font-weight-bold pb-1 m-0" for="specialities">Specialities</label>
                  <p class="resource-small-text">{{therapist.specialities}}</p>
                </div>
              </div>
            </div>
          </div>
          <!-- Second Column -->
          <div class="offset-1 col-5">
            <div class="row">
              <div class="col-12">
                <p class="resource-small-text">Availability</p>
              </div>
            </div>
            <div class="row align-items-center mb-2">
              <div class="offset-4 col-3">
                <p class="resource-small-text m-0 ml-1">From</p>
              </div>
              <div class="col-4">
                <p class="resource-small-text m-0 ml-4">Until</p>
              </div>
            </div>
            <!-- monday -->
            <div class="row align-items-baseline" v-if="therapist.monday_start_time && therapist.monday_end_time">
              <div class="col-3">
                <label class="resource-text font-weight-bold pb-1 m-0" for="monday_start_time">Monday</label>
              </div>
              <div class="col-3 offset-1">
                <p class="resource-small-text">{{therapist.monday_start_time | therapistTime}}</p>
              </div>
              <span style="color:#1e1e1e;">
                <i class="fa fa-minus" aria-hidden="true"></i>
              </span>
              <div class="col-4">
                <p class="resource-small-text">{{therapist.monday_end_time | therapistTime}}</p>
              </div>
            </div>
            <!-- tuesday -->
            <div class="row align-items-baseline" v-if="therapist.tuesday_start_time && therapist.tuesday_end_time">
              <div class="col-3">
                <label class="resource-text font-weight-bold pb-1 m-0" for="tuesday_start_time">Tuesday</label>
              </div>
              <div class="col-3 offset-1">
                <p class="resource-small-text">{{therapist.tuesday_start_time | therapistTime}}</p>
              </div>
              <span style="color:#1e1e1e;">
                <i class="fa fa-minus" aria-hidden="true"></i>
              </span>
              <div class="col-4">
                <p class="resource-small-text">{{therapist.tuesday_end_time | therapistTime}}</p>
              </div>
            </div>
            <!-- wednesday -->
            <div class="row align-items-baseline" v-if="therapist.wednesday_start_time && therapist.wednesday_end_time">
              <div class="col-3">
                <label class="resource-text font-weight-bold pb-1 m-0" for="wednesday_start_time">Wednesday</label>
              </div>
              <div class="col-3 offset-1">
                <p class="resource-small-text">{{therapist.wednesday_start_time | therapistTime}}</p>
              </div>
              <span style="color:#1e1e1e;">
                <i class="fa fa-minus" aria-hidden="true"></i>
              </span>
              <div class="col-4">
                <p class="resource-small-text">{{therapist.wednesday_end_time | therapistTime}}</p>
              </div>
            </div>
            <!-- thursday -->
            <div class="row align-items-baseline" v-if="therapist.thursday_start_time && therapist.thursday_end_time">
              <div class="col-3">
                <label class="resource-text font-weight-bold pb-1 m-0" for="thursday_start_time">Thursday</label>
              </div>
              <div class="col-3 offset-1">
                <p class="resource-small-text">{{therapist.thursday_start_time | therapistTime}}</p>
              </div>
              <span style="color:#1e1e1e;">
                <i class="fa fa-minus" aria-hidden="true"></i>
              </span>
              <div class="col-4">
                <p class="resource-small-text">{{therapist.thursday_end_time | therapistTime}}</p>
              </div>
            </div>
            <!-- friday -->
            <div class="row align-items-baseline" v-if="therapist.friday_start_time && therapist.friday_end_time">
              <div class="col-3">
                <label class="resource-text font-weight-bold pb-1 m-0" for="friday_start_time">Friday</label>
              </div>
              <div class="col-3 offset-1">
                <p class="resource-small-text">{{therapist.friday_start_time | therapistTime}}</p>
              </div>
              <span style="color:#1e1e1e;">
                <i class="fa fa-minus" aria-hidden="true"></i>
              </span>
              <div class="col-4">
                <p class="resource-small-text">{{therapist.friday_end_time | therapistTime}}</p>
              </div>
            </div>
            <!-- saturday -->
            <div class="row align-items-baseline" v-if="therapist.saturday_start_time && therapist.saturday_end_time">
              <div class="col-3">
                <label class="resource-text font-weight-bold pb-1 m-0" for="saturday_start_time">Saturday</label>
              </div>
              <div class="col-3 offset-1">
                <p class="resource-small-text">{{therapist.saturday_start_time | therapistTime}}</p>
              </div>
              <span style="color:#1e1e1e;">
                <i class="fa fa-minus" aria-hidden="true"></i>
              </span>
              <div class="col-4">
                <p class="resource-small-text">{{therapist.saturday_end_time | therapistTime}}</p>
              </div>
            </div>
            <!-- sunday -->
            <div class="row align-items-baseline" v-if="therapist.sunday_start_time && therapist.sunday_end_time">
              <div class="col-3">
                <label class="resource-text font-weight-bold pb-1 m-0" for="sunday_start_time">Sunday</label>
              </div>
              <div class="col-3 offset-1">
                <p class="resource-small-text">{{therapist.sunday_start_time | therapistTime}}</p>
              </div>
              <span style="color:#1e1e1e;">
                <i class="fa fa-minus" aria-hidden="true"></i>
              </span>
              <div class="col-4">
                <p class="resource-small-text">{{therapist.sunday_end_time | therapistTime}}</p>
              </div>
            </div>
            <div class="row mt-4">
              <div class="col-12">
                <p class="resource-small-text">In-person Salon</p>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <div class="form-group">
                  <label class="resource-text font-weight-bold pb-1 m-0" for="address">Address</label>
                  <p class="resource-small-text">{{therapist.address}}</p>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-7">
                <div class="form-group">
                  <label class="resource-text font-weight-bold pb-1 m-0" for="city">City</label>
                  <p class="resource-small-text">{{therapist.city}}</p>
                </div>
              </div>
              <div class="col-5">
                <div class="form-group">
                  <label class="resource-text font-weight-bold pb-1 m-0" for="postcode">Postcode</label>
                  <p class="resource-small-text">{{therapist.postcode}}</p>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <div class="form-group">
                  <label class="resource-text font-weight-bold pb-1 m-0" for="country">Country</label>
                  <p class="resource-small-text">{{therapist.country}}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row py-3 pb-5">
          <div class="col-6">
            <router-link :to="'/removeTherapist/'+this.$route.params.therapist_id">
              <span class="resource-text phase resource-remove"
              title="Remove the therapist">
                Remove Permanently
              </span>
              </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import axios from 'axios';
  import moment from "moment";

  export default {
    name: 'view-therapist',
    filters: {
        therapistTime: function(time) {
            let d = moment(time, 'HH:mm:ss');
            return d.isValid()
                ? moment(d).format("hh:mm A")
                : '-';
        }
    },
    data: () => {
      return {
        appURL: process.env.VUE_APP_MAIN_URL,
        therapist: {},
        imageHeight: 150,
        start_time: '',
        end_time: '',
      }
    },
    methods: {
      setHeight: function() {
        this.imageHeight = this.$refs.dbImage.width;
      },
      getTherapist: function () {
        axios({
          method: "GET",
          url: `/admin/therapists/${this.$route.params.therapist_id}?access_token=${localStorage.getItem('access_token')}`,
          headers: {
            "content-type": "application/json"
          }
        }).then(
          result => {
            if (result) {
              this.therapist = result.data.therapist;
              if(this.therapist.saturday_start_time){
                this.weekends = true
              }
            }
          },
          error => {
            console.log(error);
          }
        );
      },
      deleteTherapist: function () {
        const answer = window.confirm('Are you sure you want to remove this Therapist?')
        if (answer) {
          axios({
            method: "DELETE",
            url: `admin/therapists/delete/${this.$route.params.therapist_id}?access_token=${localStorage.getItem('access_token')}`,
            headers: {
              "content-type": "application/json"
            }
          }).then(
            result => {
              if (result) {
                this.$router.push('/therapists');
              }
            },
            error => {
              console.log(error);
            }
          );
        }
      },
      togglePhase: function () {
        axios({
          method: "POST",
          url: `admin/therapists/toggle-phase/${this.therapist.id}?access_token=${localStorage.getItem('access_token')}`,
          headers: {
            "content-type": "application/json"
          }
        }).then(
          result => {
            if (result) {
              this.$router.push(`/therapists`);
            }
          },
          error => {
            console.log(error);
          }
        );
      },
    },
    mounted() {
      this.getTherapist();
    }
  };
</script>

<style lang="scss" scoped>
</style>
