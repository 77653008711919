<template>
  <div class="entry-content">
    <div class="container-fluid mt-7">
    <div class="row">
    <div class="col-lg-12">
      <p class="main-message m-0 mx-auto login-max-width">New Password</p>
      <div class="row">
        <div class="col-lg-12 mt-2">
        <div class="form-group mx-auto login-max-width">
        <label class="resource-text m-0" for="password">Password</label>
        <div class="input-group">
          <input class="form-control border-0" :type="inputType" id="password"
            placeholder="password"
            v-model="passwords.password"> 
          <div class="input-group-prepend m-2">
            <span v-if="!passwordShow" class="eye-icon cursor-pointer" @click="hidePassword"><i class="fa fa-eye" aria-hidden="true"></i></span>
            <span v-else class="eye-icon cursor-pointer" @click="showPassword"><i class="fas fa-eye-slash"></i></span>
          </div>
          </div>
        </div>
      </div>
      </div>

      <div class="row">
        <div class="col-lg-12">
        <div class="form-group mx-auto login-max-width">
        <label class="resource-text m-0" for="password_confirmation">Confirm Password</label>
        <div class="input-group">
          <input class="form-control border-0" :type="inputTypeConfirm" id="password_confirmation"
            placeholder="password"
            v-model="passwords.confirm_password"> 
          <div class="input-group-prepend m-2">
            <span v-if="!confirmShow" class="eye-icon cursor-pointer" @click="hideConfirm"><i class="fa fa-eye" aria-hidden="true"></i></span>
            <span v-else class="eye-icon cursor-pointer" @click="showConfirm"><i class="fas fa-eye-slash"></i></span>
          </div>
          </div>
        </div>
      </div>
      </div>
      <div class="row">
        <div class="col-lg-12 text-center mt-4">
          <button type="button" class="btn btn-primary px-5 enroute-btn resource-large-text" @click="changePassword">Change Password</button>
          <div>{{response}}</div>
        </div>
      </div>
    </div>
    </div>
  </div>
</div>
</template>

<script>
  import axios from 'axios';
  export default {
    name: 'reset-password',
    data: () => {
      return {
        passwords: {
          password: '',
          confirm_password: ''
        },
        passwordShow: false,
        confirmShow: false,
        inputType: 'password',
        inputTypeConfirm: 'password',
        response: ''
      }
    },
    methods: {
      showPassword: function(){
          this.passwordShow = false;
          this.inputType = 'password';
        },
      hidePassword: function(){
          this.passwordShow = true;
          this.inputType = 'text';
        },
      showConfirm: function(){
          this.confirmShow = false;
          this.inputTypeConfirm = 'password';
        },
      hideConfirm: function(){
          this.confirmShow = true;
          this.inputTypeConfirm = 'text';
        },
      changePassword: function () {
        var data = new FormData();
        data.append('password', this.passwords.password);
        data.append('confirm_password', this.passwords.confirm_password);
        this.loader = true;
        axios(
          {
            method: "POST",
            "url": "admin/change-password",
            "data": data,
            "headers": {
              "content-type": "multipart/form-data",
            }
          }
        ).then(result => {
          console.log("hello");
          this.response = result.data['message'];
          }, error => {
            console.log(this.passwords.password);
            console.log(this.passwords.confirm_password);
            this.$noty.error("Please check your credentials");
          console.log(error.response);
          this.loader = false;
        });
      },

    },
  }
</script>
<style>
.input-group-text{
  border-top-right-radius: 6px !important;
  border-bottom-right-radius: 6px !important;
}
</style>
