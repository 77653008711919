<template>
  <div class="entry-content">
    <!-- <router-link to="/products">
      <h1 class="page-title m-0 ml-4"><img class="align-baseline" src="/img/icons/arrow-left.svg" alt="left-arrow"> Back
      </h1>
    </router-link> -->
    <div class="container-fluid">
      <div class="container pb-3">
        <div class="top-row d-flex justify-content-between">
          <h1 class="page-title m-0">
            Select Replacement
          </h1>
          <a class="btn exportBtn" :href="url + 'export/products'">Export All</a>
        </div>
        <div class="row align-items-end m-0">
          <div class="col-9">
            <div class="row align-items-center">
              <div class="form-group m-0 float-left mr-5">
                <div class="input-group search">
                  <div class="input-group-prepend m-0">
                    <span class="input-group-text border-0"><img src="/img/icons/search.svg" alt="search"></span>
                  </div>
                  <input class="form-control resource-field border-0 pl-2" placeholder="Search name ..." type="text" v-model="search"
                    @keydown="searchKeydown" @input="searchInput">
                </div>
              </div>
              <router-link :to="'/add-replacement/'+ this.$route.params.product_id">
                <img class="add-new-icon" src="/img/icons/icon-plus.svg" alt="">
                <span class="add-new pl-1 ">Add New</span>
              </router-link>
            </div>
          </div>
          <div class="col-3 d-flex justify-content-end p-0">
            <div style="max-width: 190px;">
              <label class="resource-small-text m-0" for="sort">Sort by</label>
              <select v-model="sort" class="resource-select custom-select py-0" id="sort" @change="getProducts">
                <option value="product_name" selected>Name</option>
                <option value="product_type">Type</option>
                <option value="created_at">Date</option>
                <option value="product_price">Price (Asc)</option>
                <option value="product_price_desc">Price (Desc)</option>
                <option value="expiry_date">Expiry Date (Asc)</option>
                <option value="expiry_date_desc">Expiry Date (Desc)</option>
              </select>
            </div>
          </div>
        </div>
        <!-- products -->
        <div class="row my-4">
          <div class="col-3 card resource bg-white m-3 p-0" v-for="product in products" :key="product.id">
            <div class="bg-black image resource-image-block">
              <img v-if="(product.image)" :src="appURL + product.image"
                alt="product image" style="width: auto; max-height: 109px;">
              <img v-else src="../assets/product-bottle.png"
                class="my-3" alt="default image" style="width: auto; max-height: 75px;">
            </div>
            <p class="text-center resource-large-text m-0 p-0 mt-1">{{product.product_name}}</p>
            <p class="text-center resource-text p-0 m-0">{{product.brand_name}}</p>
            <p class="text-center resource-small-text opacity-7 mt-1">{{product.product_type}}</p>
            <div class="row justify-content-center mb-3">
                <button class="btn btn-primary btn-sm resource-text enroute-btn px-5 py-2" @click="removeProduct(product.id)">Select</button>
              <!-- <router-link :to="'/product/'+product.id">
                <button class="btn btn-primary btn-sm resource-small-text enroute-btn resource-edit-btn px-4">View</button>
              </router-link> -->
            </div>
          </div>
        </div>

        <pagination :total="total" @fetchData="getProducts" v-if="total > 1"/>
      </div>
    </div>
  </div>
</template>
<script>
  import axios from 'axios';
  import pagination from '../components/Pagination.vue';

  export default {
    name: 'Product',
    components: {
      pagination
    },
    data: () => {
      return {
        appURL: process.env.VUE_APP_MAIN_URL,
        products: [],
        total: 1,
        search: '',
        sort: 'product_name',
        url: '',
      }
    },
    methods: {
      searchKeydown: function(e){
        if (e.keyCode === 13) {
          if(this.search.length>=3 || this.search==''){
            this.getProducts();
          }
          else{
            this.$noty.error("Please enter 3 or more characters");
          }
        }
      },
      searchInput: function(){
        if(!this.search){
          this.getProducts();
        }
      },
      getProducts: function (page = 1) {
        let url = `/admin/products/all?page=${page}&access_token=${localStorage.getItem('access_token')} `;
        if(this.sort){
          if(this.sort.includes('_desc')){
            url += `&sortBy=${this.sort.split('_desc')[0]}&orderBy=DESC`;
          }else{
            url += `&sortBy=${this.sort}`;
          }
        }
        if(this.search){
          url += `&searchBy=${this.search}`;
        }
        axios({
          method: "GET",
          url: url,
          headers: {
            "content-type": "application/json"
          }
        }).then(
          result => {
            if (result) {
              this.products = result.data.products.data.filter(product => product.id != this.$route.params.product_id);
              this.total = Math.ceil(result.data.products.total/result.data.products.per_page);
            }
          },
          error => {
            console.log(error);
          }
        );
      },
      removeProduct: function (id) {
        const replacement = {
            replacement_product: id
        };

        axios({
          method: "DELETE",
          url: `admin/products/delete/${this.$route.params.product_id}?access_token=${localStorage.getItem('access_token')}`,
          data: replacement,
          headers: {
            "content-type": "application/json"
          }
        }).then(
          result => {
            if(result){
              this.$router.push('/products');
            }
          },
          error => {
            console.log(error);
          }
        );
      },
    },
    mounted() {
      this.getProducts();
      this.url = axios.defaults.baseURL;
    }
  };
</script>

<style lang="scss" scoped>
</style>
