<template>
<div class=" entry-content">
  <div class="container-fluid">
    <div class="container">
      <div class="top-row d-flex justify-content-between">
          <h1 class="page-title m-0">
            Questionnaire
          </h1>
          <a class="btn exportBtn" :href="url + 'export/surveys'">Export All</a>
        </div>
      <div class="table-responsive table-hover">
            <table class="table mt-4">
                <thead>
                    <tr>
                        <th class="resource-small-text cursor-pointer" scope="col" 
                          @click="sortTable('name')" title="sort by name">Name <i class="fas fa-caret-down"></i></th>
                        <th class="resource-small-text cursor-pointer" scope="col" 
                          @click="sortTable('email')" title="sort by email">Email <i class="fas fa-caret-down"></i></th>
                        <th class="resource-small-text cursor-pointer" scope="col" 
                          @click="sortTable('updated_at')" title="sort by last updated">Last Updated On <i class="fas fa-caret-down"></i></th>
                        <th class="resource-small-text cursor-pointer" scope="col" 
                          @click="sortTable('account')">Account <i class="fas fa-caret-down"></i></th>
                    </tr>
                </thead>
                <tbody >
                    <tr class="bg-white cursor-pointer" v-for="user in users" :key="user.id" @click="answers(user.id)">
                        <td>{{user.name || '-'}}</td>
                        <td>{{user.email || '-'}}</td>
                        <td>{{formatDate(user.updated_at)}}</td>
                        <td>{{user.account ? 'Yes': 'No'}}</td>
                    </tr >
                </tbody>
            </table>
        </div>
      <pagination :total="total" ref="pagination" @fetchData="usersList" v-if="total > 1"/>
    </div>
  </div>
</div>
</template>


<script>
  import axios from 'axios';
  import moment from "moment";
  import pagination from '../components/Pagination.vue';
  export default {
    name: "Questionnaire",
    components: {
      pagination
    },
    // filters: {
    // moment: function(date, altDate) {
    //   let d = moment(date);
    //   let altD = moment(altDate);
    //   return d.isValid()
    //     ? moment(date).format("MMM D, YYYY")
    //     : moment(altD).format("MMM D, YYYY");
    //   }
    // },
    data: () => {
      return {
        users: [],
        total: 1,
        sort: 'updated_at',
        url: '',
        orderBy: {updated_at:'DESC'},
      }
    },
    methods: {
      usersList: function (page = 1) {
        let url = `admin/users/questionnaire-done?page=${page}&access_token=${localStorage.getItem('access_token')} `;
        if(this.sort){
          url += `&sortBy=${this.sort}`;
        }
        if(this.orderBy[this.sort]){
          url += `&orderBy=${this.orderBy[this.sort]}`;
        }else{
          this.orderBy[this.sort] = 'ASC';
        }
        axios({
          method: "GET",
          url: url ,
          headers: {
            "content-type": "application/json"
          }
        }).then(
          result => {
            if (result) {
              this.users = result.data.users.data;
              this.total = Math.ceil(result.data.users.total/result.data.users.per_page);
              console.log(this.users);
            }
          },
          error => {
            console.log(error);
          }
        );
      },
      answers: function (id) {
        localStorage.setItem('answer_id', id);
        this.$router.push('/answers/'+id);
      },
      sortTable: function (id) {
        this.sort = id;
        if(this.orderBy[id]=='ASC'){
          this.orderBy[id] = 'DESC';
        }else{
          this.orderBy[id] = 'ASC';
        }
        this.usersList();
      },
      formatDate: function (value) {
        if(value){
          return moment(String(value)).format('MMM D, YYYY');
        }else{
          return '-';
        }
      }
    },
    mounted: function () {
        this.usersList();
        this.url = axios.defaults.baseURL;
    },
    computed: {
    }
  }
</script>

<style lang="scss" scoped>
.table{
  border-collapse: separate;
  border-spacing: 0 12px !important;
  tbody td{
    padding: 12px;
  }
  thead th{
    padding: 5px;
    text-transform: capitalize;
  }
}
</style>
