<template>
    <div class="entry-content">
        <router-link to="/questionnaire">
            <h1 class="back m-0 ml-4"><img class="align-baseline" src="/img/icons/arrow-left.svg" height="15"
                    alt="left-arrow"> Back</h1>
        </router-link>
        <div class="container-fluid pt-2">
            <div class="container">
                <label class="divider"><span>Skin Care EN ROUTE</span></label>
                <div class="questions" v-for="question in firstQuestions" :key="question.id">
                    <div class="details">
                        <p class="mb-1 resource-text">{{question.question_text}}</p>
                        <p class="mb-4 resource-text font-weight-bold">{{jsonString(question.answer)}}</p>
                    </div>
                </div>
                <label class="divider"><span>Block 1: About me and my priorities</span></label>
                <div class="questions" v-for="question in block1Questions" :key="question.id">
                    <div class="details">
                        <p class="mb-1 resource-text" v-html="jsonString(question.question_text)"></p>
                        <p class="mb-4 resource-text font-weight-bold" v-html="jsonString(question.answer)"></p>
                    </div>
                </div>
                <label class="divider"><span>Block 2: Skin type and skin texture</span></label>
                <div class="questions" v-for="question in block2Questions" :key="question.id">
                    <div class="details">
                        <p class="mb-1 resource-text" v-html="jsonString(question.question_text)">{{question.question_text}}</p>
                        <p class="mb-4 resource-text font-weight-bold" v-html="jsonString(question.answer)">{{jsonString(question.answer)}}</p>
                    </div>
                </div>
                <label class="divider"><span>Block 3: Your priority skin conditions</span></label>
                <div class="questions" v-for="question in block3Questions" :key="question.id">
                    <div class="details">
                        <p class="mb-1 resource-text" v-html="jsonString(question.question_text)">{{question.question_text}}</p>
                        <p class="mb-4 resource-text font-weight-bold" v-html="jsonString(question.answer)">{{jsonString(question.answer)}}</p>
                    </div>
                </div>
                <label class="divider"><span>Block 4: Environment and Lifestyle</span></label>
                <div class="questions" v-for="question in block4Questions" :key="question.id">
                    <div class="details">
                        <p class="mb-1 resource-text" v-html="jsonString(question.question_text)">{{question.question_text}}</p>
                        <p class="mb-4 resource-text font-weight-bold" v-html="jsonString(question.answer)">{{jsonString(question.answer)}}</p>
                    </div>
                </div>
                <label class="divider" v-if="images.image1 || images.image2 || images.image3"><span>Images</span></label>
                <div class="details mb-0" v-if="images.image1 || images.image2 || images.image3">
                    <div class="d-flex cursor-pointer m-2">
                        <div v-if="(images.image1)" class="mx-2 image-effects image resource-image-block image">
                            <img :src="appURL + images.image1" alt="product image" style="width: auto; max-height: 200px;">
                        </div>
                        <div v-if="(images.image2)" class="mx-2 image-effects image resource-image-block image">
                            <img :src="appURL + images.image2" alt="product image" style="width: auto; max-height: 200px;">
                        </div>
                        <div v-if="(images.image3)" class="mx-2 image-effects image resource-image-block image">
                            <img :src="appURL + images.image3" alt="product image" style="width: auto; max-height: 200px;">
                        </div>
                    </div>
                    <div v-if="!(images.image1) && !(images.image2) && !(images.image3)" 
                        class="resource-text"> No image attached by user </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import axios from 'axios';
    export default {
        name: "answers",
        components: {},
        data: () => {
            return {
                appURL: process.env.VUE_APP_MAIN_URL,
                questions: [],
                images: [],
                current_block: 1,
            }
        },
        methods: {
            allAnswers: function () {
                axios({
                    method: "GET",
                    url: `admin/users/question-answers/${localStorage.getItem('answer_id')}?access_token=${localStorage.getItem('access_token')}`,
                    headers: {
                        "content-type": "application/json"
                    }
                }).then(
                    result => {
                        if (result) {
                            this.questions = result.data.questions;
                            this.images = result.data.images;
                            console.log(result);
                        }
                    },
                    error => {
                        console.log(error);
                    }
                );
            },
            back: function () {
                this.$router.push('/questionnaire');
            },
            jsonString(value) {
                if (!value) {
                    return '-'
                } else {
                    if (value === parseInt(value, 10)) {
                        return value.toString();
                    } else {
                        try {
                            var data = JSON.parse(value);
                            if (data.length) {
                                return data.join(', ');
                            } else {
                                return '-'
                            }
                        } catch {
                            if (value) {
                                return value
                            } else {
                                return '-'
                            }
                        }
                    }
                }
            }
        },
        mounted: function () {
            this.allAnswers();
        },
        computed: {
            firstQuestions() {
                return this.questions.filter((item) => {
                        if(item.block == 1 && item.question_column_name != 'priorities'){
                            return item;
                        }
                    })
            },
            block1Questions() {
                return this.questions.filter((item) => {
                        if(item.block == 1 && item.question_column_name == 'priorities'){
                            return item;
                        }
                    })
            },
            block2Questions() {
                return this.questions.filter((item) => {
                        if(item.block == 2){
                            return item;
                        }
                    })
            },
            block3Questions() {
                return this.questions.filter((item) => {
                        if(item.block == 3){
                            return item;
                        }
                    })
            },
            block4Questions() {
                return this.questions.filter((item) => {
                        if(item.block == 4){
                            return item;
                        }
                    })
            },
            block5Questions() {
                return this.questions.filter((item) => {
                        if(item.block == 5){
                            return item;
                        }
                    })
            }
        }
    }
</script>


<style>
    .details {
        margin: 1rem 2rem 1rem 2rem;
    }

    .container {
        font-size: 22px;
        color: #1e1e1e;
    }

    .divider {
        width: 100%;
        text-align: left;
        border-bottom: 1px solid #1e1e1e;
        line-height: 0.1em;
        margin: 10px 0 20px;
    }

    .divider span {
        background: #f2f0eb;
        padding: 0 10px;
    }
</style>