<template>
  <div class="entry-content">
    <div class="container-fluid">
      <div class="container pb-3">
        <div class="top-row d-flex justify-content-between">
          <h1 class="page-title m-0">
            Users
          </h1>
        </div>
        <div class="row align-items-end m-0">
          <div class="col-9">
            <div class="row align-items-center">
              <div class="form-group m-0 float-left mr-5">
                <div class="input-group search">
                  <div class="input-group-prepend m-0">
                    <span class="input-group-text border-0"><img src="/img/icons/search.svg" alt="search"></span>
                  </div>
                  <input class="form-control resource-field border-0 pl-2" placeholder="Search name ..." type="text"
                    v-model="search" @keydown="searchKeydown" @input="searchInput">
                </div>
              </div>
            </div>
          </div>
          <div class="col-3 d-flex justify-content-end p-0">
            <div style="max-width: 150px;">
              <label class="resource-small-text m-0" for="sort">Sort by</label>
              <select v-model="sort" class="resource-select custom-select py-0 px-1" id="sort" @change="getUsers">
                <option value="name" selected>Name (A - Z)</option>
                <option value="name_desc" selected>Name (Z - A)</option>
                <option value="created_at_desc">Date (Latest)</option>
                <option value="created_at">Date (Earliest)</option>
              </select>
            </div>
          </div>
        </div>
        <!-- users -->
        <div class="row my-3">
          <div class="col-3 card resource bg-white m-3 p-0 cursor-pointer" v-for="user in filteredUsers" :key="user.id"
            @click="goToUser(user.id)" :class="userBlockClass(user)">
            <div class="bg-black image" :class="{'resource-image-block': !userBlockClass(user)}">
              <img v-if="(user.profile_image)" :src="appURL + user.profile_image" alt="user image"
                style="width: auto; max-height: 112px;">
              <img v-else src="../assets/nav-user.png" class="my-3" alt="default image"
                style="width: auto; max-height: 80px;">
            </div>
            <p class="text-center resource-large-text m-0 p-0 mt-2 mb-1">{{user.name || "User Name"}}</p>
            <p class="text-center resource-text p-0 m-0 mb-4">{{user.email || "- No Email Provided -"}}</p>
          </div>
        </div>
        <div style="padding: 22px;">
          <pagination :total="total" @fetchData="getUsers" v-if="total > 1" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import axios from 'axios';
  import pagination from '../components/Pagination.vue';

  export default {
    name: 'Users',
    components: {
      pagination
    },
    data: () => {
      return {
        appURL: process.env.VUE_APP_MAIN_URL,
        users: [],
        url: '',
        total: 1,
        search: '',
        sort: 'name',
      }
    },
    methods: {
      searchKeydown: function (e) {
        if (e.keyCode === 13) {
          if (this.search.length >= 3 || this.search == '') {
            this.getUsers();
          } else {
            this.$noty.error("Please enter 3 or more characters");
          }
        }
      },
      searchInput: function () {
        if (!this.search) {
          this.getUsers();
        }
      },
      userBlockClass: function (user) {
        if (user.user_therapist) {
          return '';
        }
        if (user.client_suggestion) {
          if (user.client_suggestion.acknowledged_by_therapist && !user.client_suggestion.accepted_by_therapist) {
            return 'client-rejection-border';
          }
          return 'client-suggestion-border';
        }
        if (user.consultation_request) {
          return 'user-request-border';
        }
        return '';
      },
      getUsers: function (page = 1) {
        let url = `admin/users?page=${page}&access_token=${localStorage.getItem('access_token')}`;
        if (this.sort) {
          if (this.sort.includes('_desc')) {
            url += `&sortBy=${this.sort.split('_desc')[0]}&orderBy=DESC`;
          } else {
            url += `&sortBy=${this.sort}`;
          }
        }
        if (this.search) {
          url += `&searchBy=${this.search}`;
        }
        axios({
          method: "GET",
          url: url,
          headers: {
            "content-type": "application/json"
          }
        }).then(
          result => {
            if (result) {
              this.users = result.data.users.data;
              this.total = Math.ceil(result.data.users.total / result.data.users.per_page);
              console.log({users: this.users});
            }
          },
          error => {
            console.log(error);
          }
        );
      },
      goToUser: function (id) {
        this.$router.push(`user/${id}`);
      },
    },
    computed: {
      filteredUsers() {
        return this.users.filter((item) => {
          return item;
        })
      },
    },
    mounted() {
      this.getUsers();
      this.url = axios.defaults.baseURL;
    }
  };
</script>

<style lang="scss" scoped>
  .color-box {
    width: 25px;
    height: 12px;
  }

  .request-box {
    background-color: #f2d3bc;
  }

  .suggestion-box {
    background-color: black;
  }

  .rejection-box {
    background-color: #a5a5a5;
  }

  .user-request-border {
    border: 8px solid #f2d3bc;
  }

  .client-suggestion-border {
    border: 8px solid black;
  }

  .client-rejection-border {
    border: 8px solid #a5a5a5;
  }
</style>
