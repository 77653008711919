<template>
  <div class="entry-content">
    <div class="container-fluid">
      <div class="container pb-3">
        <div class="top-row d-flex justify-content-between align-items-center">
          <h1 class="page-title m-0">
            Purchase Orders
          </h1>
          <div class="d-flex flex-row align-items-center">
            <label class="resource-small-text m-0 mr-1" for="from">From: </label>
            <input type="date" v-model="from" class="form-control resource-field py-0 px-1 mr-1" id="from" @change="getPurchaseOrders">
            <label class="resource-small-text m-0 mr-1" for="to">To: </label>
            <input type="date" v-model="to" class="form-control resource-field py-0 px-1 mr-1" id="to" @change="getPurchaseOrders">
          </div>
<!--          <a class="btn exportBtn" :href="url + 'export/users'">Export All</a>-->
        </div>
        <div class="row align-items-end m-0 mt-2">
          <div class="offset-9 col-3 d-flex justify-content-end p-0">
            <div style="max-width: 150px;">
              <label class="resource-small-text m-0" for="sort">Sort by</label>
              <select v-model="sort" class="resource-select custom-select py-0 px-1" id="sort" @change="getPurchaseOrders">
                <option value="id" selected>Default</option>
                <option value="so_id">Sales Order</option>
                <option value="date">Date</option>
                <option value="supplier">Supplier</option>
              </select>
            </div>
          </div>
        </div>
        <!-- users -->
        <div class="row my-3">
          <div class="table-responsive table-hover">
            <table class="table mt-4">
              <thead>
              <tr>
                <th class="resource-small-text" scope="col">
                  Purchase Order ID
                </th>
                <th class="resource-small-text" scope="col">
                  SO #
                </th>
                <th class="resource-small-text" scope="col">Date</th>
                <th class="resource-small-text" scope="col">Supplier</th>
                <th class="resource-small-text" scope="col">&nbsp;</th>
              </tr>
              </thead>
              <tbody>
              <tr class="bg-white" v-for="order in orders" :key="order.id">
                <td>{{ order.id }}</td>
                <td>{{ order.so_id }}</td>
                <td>{{ order.date | moment }}</td>
                <td class="text-capitalize">{{ order.supplier }}</td>
                <td>
                  <ul class="pl-0 mb-0">
                  <li class="nav-item dropdown left-width ml-2 py-0">
                    <base-dropdown data-boundary="viewport" class="nav-link px-0 float-right">
                      <div class="media align-items-center" slot="title">
                        <div class="media-body d-none d-sm-block">
                          <img
                                  src="img/icons/second-menu-normal-brown.svg"
                                  alt="second menu"
                          />
                        </div>
                      </div>
                      <template class="new">
                        <div class="menu-block">
                          <router-link
                                  :to="'/purchase-order/' + order.id"
                                  class="py-3 pr-3 pl-1 d-flex justify-content-center">
                            <span class="medium-text" style="background-color: #ffffff !important;"> Open/Edit Purchase Order </span>
                          </router-link>
                          <router-link
                                  :to="'/sales-order/' + order.id"
                                  class="py-3 pr-3 pl-1 d-flex justify-content-center">
                            <span class="medium-text" style="background-color: #ffffff !important;">Generate</span>
                          </router-link>
                        </div>
                      </template>
                    </base-dropdown>
                  </li>
                  </ul>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div style="padding: 22px;">
          <pagination :total="total" @fetchData="getPurchaseOrders" v-if="total > 1" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import axios from 'axios';
  import pagination from '../components/Pagination.vue';
  import moment from "moment";

  export default {
    name: 'SalesOrders',
    components: {
      pagination
    },
    filters: {
      moment: function (date) {
        let d = moment(date);
        return d.isValid() ? moment(date).format("DD/MM/YYYY") : "N/A";
      },
    },
    data: () => {
      return {
        appURL: process.env.VUE_APP_MAIN_URL,
        orders: [],
        url: '',
        total: 1,
        search: '',
        sort: 'id',
        from: '',
        to: '',
        page : 1
      }
    },
    methods: {
      searchKeydown: function (e) {
        if (e.keyCode === 13) {
          if (this.search.length >= 3 || this.search == '') {
            this.getPurchaseOrders();
          } else {
            this.$noty.error("Please enter 3 or more characters");
          }
        }
      },
      searchInput: function () {
        if (!this.search) {
          this.getPurchaseOrders();
        }
      },
      getPurchaseOrders: function (page=1) {
        this.page = page;
        let url = "admin/purchase/all";
        if (this.sort) {
          url += `?${this.sort}=true`;
        }
        if (this.from) {
          url += `&fromDate=${this.from}`;
        }
        if (this.to) {
          url += `&toDate=${this.to}`;
        }
        if(this.page > 1){
          url += `&page=${this.page}`
        }
        axios({
          method: "GET",
          url: url,
          headers: {
            "content-type": "application/json"
          }
        }).then(
          result => {
            if (result) {
              this.orders = result.data.purchaseOrders.data;
              this.total = Math.ceil(result.data.purchaseOrders.total / result.data.purchaseOrders.per_page);
              window.scroll({
                top:0, 
                left: 0, 
                behavior: 'smooth' 
              });
            }
          },
          error => {
            console.log(error);
          }
        );
      },
      goToSalesOrder: function (id) {
        this.$router.push(`sales-order/${id}`);
      },
    },
    computed: {
    },
    mounted() {
      localStorage.setItem('page', '1');
      this.getPurchaseOrders();
      this.url = axios.defaults.baseURL;
    }
  };
</script>

<style lang="scss" scoped>
  @media (max-width: 767px) {
    .table-responsive .dropdown-menu {
      position: static !important;
    }
  }
  @media (min-width: 768px) {
    .table-responsive {
      overflow: visible;
    }
  }
  li > li{
    padding-top:0;
    padding-bottom: 0;
  }
</style>
