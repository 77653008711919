var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wrapper",class:{ 'nav-open': _vm.$sidebar.showSidebar }},[_c('side-bar',{attrs:{"background-color":_vm.sidebarBackground}},[_c('template',{slot:"links"},[_c('sidebar-item',{attrs:{"link":{
          name: 'Users',
          custom_icon: '/img/icons/nav-user.svg',
          path: '/users'
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: 'Subscribers',
          custom_icon: '/img/icons/nav-user.svg',
          path: '/subscribers'
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: 'Products',
          custom_icon: '/img/icons/nav-product-bottle.svg',
          path: '/products'
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: 'Materials',
          custom_icon: '/img/icons/nav-product-bottle.svg',
          path: '/materials'
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: 'Supplier',
          custom_icon: '/img/icons/nav-product-bottle.svg',
          path: '/supplier'
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: 'Categories',
          custom_icon: '/img/icons/nav-product-bottle.svg',
          path: '/categories'
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: 'Sales Orders',
          custom_icon: '/img/icons/file.svg',
          path: '/sales-orders'
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: 'Sales Reports',
          custom_icon: '/img/icons/file.svg',
          path: '/sales-reports'
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: 'Purchase Orders',
          custom_icon: '/img/icons/file.svg',
          path: '/purchase-orders'
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: 'Notifications',
          custom_icon: '/img/icons/file.svg',
          path: '/notifications'
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: 'Change password',
          custom_icon: '/img/icons/file.svg',
          path: '/password-change'
        }}})],1)],2),_c('div',{staticClass:"main-content",attrs:{"data":_vm.sidebarBackground}},[_c('dashboard-navbar'),_c('div',{on:{"click":_vm.toggleSidebar}},[_c('fade-transition',{attrs:{"duration":200,"origin":"center top","mode":"out-in"}},[_c('router-view')],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }