<template>
<div class="container-fluid pt-8">
  <div class="row">
  <div class="col-lg-12">
    <p class="main-message m-0 mx-auto login-max-width">New Password</p>
    <div class="row">
      <div class="col-lg-12 mt-2">
      <div class="form-group mx-auto login-max-width">
      <label class="resource-text m-0" for="password">Password</label>
      <div class="input-group">
        <input class="form-control border-0" :type="inputType" id="password"
          placeholder="password"
          v-model="login.password"> 
        <div class="input-group-prepend m-2">
          <span v-if="!passwordShow" class="eye-icon cursor-pointer" @click="hidePassword"><i class="fa fa-eye" aria-hidden="true"></i></span>
          <span v-else class="eye-icon cursor-pointer" @click="showPassword"><i class="fas fa-eye-slash"></i></span>
        </div>
        </div>
      </div>
    </div>
    </div>

    <div class="row">
      <div class="col-lg-12">
      <div class="form-group mx-auto login-max-width">
      <label class="resource-text m-0" for="password_confirmation">Confirm Password</label>
      <div class="input-group">
        <input class="form-control border-0" :type="inputTypeConfirm" id="password_confirmation"
          placeholder="password"
          v-model="login.password_confirmation"> 
        <div class="input-group-prepend m-2">
          <span v-if="!confirmShow" class="eye-icon cursor-pointer" @click="hideConfirm"><i class="fa fa-eye" aria-hidden="true"></i></span>
          <span v-else class="eye-icon cursor-pointer" @click="showConfirm"><i class="fas fa-eye-slash"></i></span>
        </div>
        </div>
      </div>
    </div>
    </div>

    <div class="row">
      <div class="col-lg-12 text-center mt-4">
        <button type="button" class="btn btn-primary px-5 enroute-btn resource-large-text" @click="reset">Change Password</button>
        <div>{{response}}</div>
      </div>
    </div>
  </div>
  </div>
</div>
</template>

<script>
  import axios from "axios";
  import router from "../router";

  export default {
    name: 'reset-password',
    data: () => {
      return {
        login: {
          email: '',
          password: '',
          password_confirmation: '',
          reset_token: ''
        },
        passwordShow: false,
        confirmShow: false,
        inputType: 'password',
        inputTypeConfirm: 'password',
        response: '',
      }
    },
    methods: {
      showPassword: function(){
          this.passwordShow = false;
          this.inputType = 'password';
        },
      hidePassword: function(){
          this.passwordShow = true;
          this.inputType = 'text';
        },
      showConfirm: function(){
          this.confirmShow = false;
          this.inputTypeConfirm = 'password';
        },
      hideConfirm: function(){
          this.confirmShow = true;
          this.inputTypeConfirm = 'text';
        },
      reset: function () {
        var data = new FormData();
        data.append('email', this.login.email);
        data.append('password', this.login.password);
        data.append('password_confirmation', this.login.password_confirmation);
        data.append('reset_token', this.login.reset_token);
        axios(
            {
              method: "POST",
              "url": "admin/reset-password",
              "data": data,
              "headers": {
                "content-type": "multipart/form-data",
              }
            }
        ).then(result => {
          result;
            this.$noty.success('Password Reset Successfully');
            router.push('/login');
        }, error => {
          this.$noty.error(error.response.data.message);
          console.log(error.response);
          this.loader = false;

        });
      },
    },
    mounted(){
      this.login.email = this.$route.params.email;
      this.login.reset_token = this.$route.params.token;
    }
  }
</script>
<style>
.input-group-text{
  border-top-right-radius: 6px !important;
  border-bottom-right-radius: 6px !important;
}
</style>
