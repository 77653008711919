<template>
  <div class="entry-content">
    <div v-if="!addModal" class="container-fluid">
      <div class="top-row d-flex justify-content-between">
        <h1 class="page-title m-0">
          Notifications
        </h1>
        <button type="button" class="btn" @click="showAddModal()">
          <img class="add-new-icon" src="/img/icons/icon-plus.svg" alt="">
          <span class="add-new pl-1" style="color: #1e1e1e !important;">Add New</span>
        </button>
      </div>
      <loading :active.sync="isLoading" :can-cancel="false" :is-full-page="fullPage"></loading>
      <vue-good-table :columns="columns" :rows="notifications" :search-options="{enabled: true, placeholder: 'Search by name'}"
                      :pagination-options="pageOptions">
        <template slot="table-row" slot-scope="props">
          <div class="row" v-if="props.column.field === 'table_actions'">
            <div class="col-md-4">

              <button @click="showAddModal(props.row)" class="btn btn-sm btn-outline-dark">Edit</button>
            </div>
            <div class="col-md-4">
              <button @click="deleteNotification(props.row.id)" class="btn btn-sm btn-outline-danger">Delete</button>
            </div>
          </div>
        </template>
        <div slot="emptystate" class="text-center">
          No Data...
        </div>
      </vue-good-table>
    </div>
    <!-- Add promotional code modal -->
    <slide-y-up-transition origin="center top" mode="out-in">
      <div v-if="addModal" class="container-fluid">
        <h1 class="back m-0 cursor-pointer" @click="goBack"><img class="align-baseline"
                                                                 src="/img/icons/arrow-left.svg" height="15" alt="left-arrow">
          Back
        </h1>
        <div class="card mt-4 shadow cusotom-card bg-transparent">
          <div class="card-body">
            <div class="row">
              <div class="col-md-12">
                <div class="form-group">
                  <label>Description:</label>
                  <textarea class="form-control" placeholder="Enter notification here" v-model="newNotification.description" type="text" name="description"
                            v-validate="'required:min:3'" />
                  <span v-show="errors.has('description')" class="help is-danger">{{ errors.first('description') }}</span>
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group">
                  <label class="resource-text m-0" for="category">Type</label>
                  <select class="form-control" v-validate="'required'" data-vv-delay="500" name="type"
                          id="type" v-model="newNotification.type">
                    <option value="maintenance">Maintenance</option>
                    <option value="popup">Popup</option>
                  </select>
                  <span v-show="errors.has('type')" class="help is-danger">{{ errors.first('type') }}</span>
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group">
                  <label class="resource-text m-0" for="display">Online</label>
                  <select class="form-control" name="display" v-model="newNotification.display" v-validate="'required'">
                    <option value="1">Yes</option>
                    <option value="0">No</option>
                  </select>
                  <span v-show="errors.has('display')" class="help is-danger">{{ errors.first('display') }}</span>
                </div>
              </div>
            </div>


            <div class="form-group mt-3 text-right">
              <button v-if="editing === false" class="btn category-btn enroute-btn" @click="addNotification">Add</button>
              <button v-if="editing === true" class="btn category-btn enroute-btn" @click="updateNotification">Update</button>
            </div>
          </div>
        </div>
      </div>
    </slide-y-up-transition>
  </div>
</template>
<script>
import axios from 'axios';
import 'vue-good-table/dist/vue-good-table.css'
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import {
  SlideYUpTransition
} from 'vue2-transitions';
import {
  VueGoodTable
} from 'vue-good-table';
export default {
  components: {
    VueGoodTable,
    Loading,
    SlideYUpTransition
  },
  data() {
    return {
      isLoading: false,
      addModal: false,
      editing: false,
      fullPage: true,
      columns: [
        {
          label: 'Notification',
          field: 'description',
        },
        {
          label: 'Type',
          field: 'type',
          width: '150px',
        },{
          label: 'Online',
          field: 'display',
          width: '90px',
        },
        {
          label: 'Action',
          field: 'table_actions',
          width: '200px',
          sortable: false,
        }
      ],
      pageOptions: {
        enabled: true,
        mode: 'records',
        perPage: 10,
        position: 'bottom',
        perPageDropdown: [10, 20, 50],
        dropdownAllowAll: true,
        setCurrentPage: 1,
        nextLabel: 'next',
        prevLabel: 'prev',
        rowsPerPageLabel: 'Per Page',
        ofLabel: 'of',
        pageLabel: 'page', // for 'pages' mode
        allLabel: 'All',
      },
      notifications: [],
      newNotification: {},
      config: {
        headers: {
          Accept: 'application/json'
        }
      },
    };
  },
  methods: {
    goBack(){
      this.addModal=false;
      this.editing = false
    },
    getAllNotifications: function () {
      this.isLoading = true;
      axios.get("admin/notifications", this.config)
          .then(result => {
            this.isLoading = false;
            this.notifications = result.data;
          }, error => {
            this.isLoading = false;
            console.log(error.response);
          });
    },
    deleteNotification: function (id) {
      this.$swal({
        title: 'Are you sure?',
        text: "This will delete the Notification.",
        icon: '',
        buttons: {
          cancel: true,
          confirm: true,
        },
      }).then((result) => {
        if (result) {
          this.isLoading = true;
          axios.delete("admin/notifications/" + id)
              .then(result => {
                this.isLoading = false;
                this.$noty.success("Notification deleted");
                this.getAllNotifications();
                return result;
              }, error => {
                this.isLoading = false;
                this.$noty.error("Something went wrong");
                return error;
              });
        }
      })
    },
    showAddModal: function (data = null) {
      this.addModal = true;
      this.editing = false;
      this.newNotification = {}

      if (data){
        this.newNotification = {...data, display: data.display === "Yes" ? 1 : 0}
        this.editing = true
      }
    },
    addNotification: function () {
      this.$validator.validate().then((result) => {
        if (result) {
          this.isLoading = true;
          axios.post('admin/notifications' + "?access_token=" + localStorage.getItem("access_token"),
              this.newNotification, this.config)
              .then(result => {
                this.isLoading = false;
                this.addModal = false;
                this.newNotification = {};
                this.$noty.success("Notification added");
                this.getAllNotifications();
                return result;
              }, error => {
                this.isLoading = false;
                this.$noty.error("Something went wrong");
                return error;
              });
        }
      }).catch((err) => {
        console.log(err)
      });
    },
    updateNotification: function () {
      this.$validator.validate().then((result) => {
        if (result) {
          this.isLoading = true;
          axios.put('admin/notifications/' + this.newNotification.id + "?access_token=" + localStorage.getItem("access_token"),
              this.newNotification, this.config)
              .then(result => {
                this.isLoading = false;
                this.addModal = false;
                this.newNotification = {};
                this.$noty.success("Notification updated");
                this.getAllNotifications();
                return result;
              }, error => {
                this.isLoading = false;
                this.$noty.error("Something went wrong");
                return error;
              });
        }
      }).catch((err) => {
        console.log(err)
      });
    },
  },
  mounted() {
    this.getAllNotifications();
  }
};
</script>
<style scoped>
.cusotom-card:hover {
  transform: none !important;
}
</style>
